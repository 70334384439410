import './start.scss';

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { debounce } from 'lodash/fp';
import { useStableCallback, useWrapCallback } from '../../hooks/use-stable-callback';
import { SpaceInfo } from '../../utils/definition';
import { mangLocal } from '../../utils/common';
import { ControlTree, SPLIT_VALUE, TreeNode } from '../common/contorl-tree';
import { CheckInfo, getCheckInfo, saveCheckInfo } from '../../service/check';

import {
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Radio,
  RadioChangeEvent,
} from 'antd';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
const { TextArea } = Input;
const { Option } = Select;

const SPACE_NAME_PREFIX = 'SpaceName-';

const StartCheck = () => {
  const history = useHistory();
  const params: any = useParams();
  const location: any = useLocation();
  const { version, roadName } = location.state ?? {};

  const [form] = Form.useForm();
  const scrollDomRef = useRef<HTMLDivElement>(null);

  const [nowIndex, setNowIndex] = useState<number>(0);
  const [closedSpace, setCloseSpace] = useState<{ [key: string]: boolean }>({});
  const [spaceInfo, setSpaceInfo] = useState<SpaceInfo>({});
  const [departmentTree, setDepartmentTree] = useState<TreeNode>({ name: 'root', children: [] });
  const [checkPointInfo, setCheckPointInfo] = useState<CheckInfo>();

  const initCheckPoint = useStableCallback((index?: number) => {
    if (!checkPointInfo) return;

    const pointDetail = checkPointInfo.projectOptionList[index ?? 0];
    if (!pointDetail) return;

    const field: any = {
      company: pointDetail.company,
      remark: pointDetail.remark,
    };

    const newSpaceInfo: SpaceInfo = {};
    pointDetail.projectSpaceInfoList?.forEach((each, index) => {
      field['space-info'] =
        each.projectSpaceDetailList.find((each) => each.checked)?.detailName ?? field['space-info'];

      newSpaceInfo['extra-' + index] = {
        name: each.spaceName,
        list: each.projectSpaceDetailList.map((each) => each.detailName),
      };
    });
    setSpaceInfo(newSpaceInfo);

    const departmentTreeKeys: string[] = [];
    const newDepartmentInfo: TreeNode = { name: 'root', children: [] };
    const rootItem = newDepartmentInfo;
    pointDetail.projectDepartmentInfoList?.forEach((level1) => {
      let level1Item = rootItem.children?.find((each) => each.name === level1.departmentName);
      if (!level1Item) {
        level1Item = {
          name: level1.departmentName as string,
          children: [],
        };
        rootItem.children!.push(level1Item);
      }
      if (!level1Item.children) {
        level1Item.children = [];
      }

      if (level1.checked) {
        departmentTreeKeys.push(level1Item.name as string);
      }

      if (!level1.projectDepartmentDetailList?.length) {
        return;
      }

      level1.projectDepartmentDetailList?.forEach((level2) => {
        let level2Item = level1Item!.children?.find(
          (each) => each.name === level2.departmentDetailName,
        );
        if (!level2Item) {
          level2Item = {
            name: level2.departmentDetailName as string,
            children: [],
          };
          level1Item!.children!.push(level2Item);
        }
        if (!level2Item.children) {
          level2Item.children = [];
        }

        if (level2.checked) {
          departmentTreeKeys.push(level1Item?.name + SPLIT_VALUE + level2Item?.name);
        }

        if (!level2.projectDepartmentDetailThreeList?.length) {
          return;
        }

        level2.projectDepartmentDetailThreeList?.forEach((level3) => {
          let level3Item = level2Item!.children?.find((each) => each.name === level3.detailName);
          if (!level3Item) {
            level3Item = {
              name: level3.detailName as string,
            };
            level2Item!.children!.push(level3Item);
          }

          if (level3.checked) {
            departmentTreeKeys.push(
              level1Item?.name + SPLIT_VALUE + level2Item?.name + SPLIT_VALUE + level3Item.name,
            );
          }
        });
      });
    });
    setDepartmentTree(rootItem);
    field.departmentTree = departmentTreeKeys.slice(0, 1);

    setCloseSpace({});
    form.setFieldsValue(field);
  });

  const toggleClose = useStableCallback((key: string) => {
    setCloseSpace({
      ...closedSpace,
      [key]: !closedSpace[key],
    });
  });

  const saveNowIndex = useStableCallback(async () => {
    if (!checkPointInfo) return;
    const saveCheck = { ...checkPointInfo };
    const nowInfo = { ...saveCheck.projectOptionList?.[nowIndex] };
    saveCheck.projectOptionList[nowIndex] = nowInfo;

    const values = await form.validateFields();
    nowInfo.clientTime = Date.now();
    nowInfo.company = values.company;
    nowInfo.remark = values.remark;
    nowInfo.projectSpaceInfoList = Object.keys(spaceInfo).map((key) => ({
      spaceName: spaceInfo[key].name,
      projectSpaceDetailList: spaceInfo[key].list.map((each: string) => ({
        detailName: each,
        checked: values['space-info'] === each,
      })),
    }));
    nowInfo.projectDepartmentInfoList =
      departmentTree.children?.map((level1) => {
        const level1IsChecked = values.departmentTree.includes(level1.name);
        const level1Item: any = {
          departmentName: level1.name,
          checked: level1IsChecked,
        };

        if (level1.children) {
          level1Item.projectDepartmentDetailList = level1.children?.map((level2) => {
            const level2IsChecked = values.departmentTree.includes(
              level1.name + SPLIT_VALUE + level2.name,
            );
            const level2Item: any = {
              departmentDetailName: level2.name,
              checked: level2IsChecked,
            };

            if (level2.children) {
              level2Item.projectDepartmentDetailThreeList = level2.children?.map((level3) => {
                const level3IsChecked = values.departmentTree.includes(
                  level1.name + SPLIT_VALUE + level2.name + SPLIT_VALUE + level3.name,
                );

                return {
                  detailName: level3.name,
                  checked: level3IsChecked,
                };
              });
            }

            return level2Item;
          });
        }

        return level1Item;
      }) ?? [];

    return saveCheck;
  });

  const prev = useStableCallback(async () => {
    try {
      const saveCheck = await saveNowIndex();
      mangLocal(`checkInfo_${params.projectId}_${params.roadId}`, {
        ...saveCheck,
        suspendIndex: nowIndex - 1,
        projectId: params.projectId,
        roadId: params.roadId,
      });
      mangLocal('checkName', { roadName, projectId: params.projectId, roadId: params.roadId });
    } catch (err) {}

    setNowIndex(nowIndex - 1);
    initCheckPoint(nowIndex - 1);
  });

  const next = useStableCallback(async () => {
    const saveCheck = await saveNowIndex();
    mangLocal(`checkInfo_${params.projectId}_${params.roadId}`, {
      ...saveCheck,
      suspendIndex: nowIndex + 1,
      projectId: params.projectId,
      roadId: params.roadId,
    });
    mangLocal('checkName', { roadName, projectId: params.projectId, roadId: params.roadId });

    // 每隔5个点位主动保存一次
    if (nowIndex % 5 === 0) {
      await onSave();
    }

    setNowIndex(nowIndex + 1);
    initCheckPoint(nowIndex + 1);
  });

  const onSave = useStableCallback(async () => {
    const saveCheck = await saveNowIndex();
    if (!saveCheck) return;

    const saveParams = {
      ...saveCheck,
      suspendIndex: nowIndex,
      projectId: params.projectId,
      roadId: params.roadId,
    };
    await saveCheckInfo(saveParams);
    message.success({
      content: '保存成功',
      style: {
        marginTop: '40vh',
      }
    });
    mangLocal(`checkInfo_${params.projectId}_${params.roadId}`, 'del');
    mangLocal('checkName', 'del');
  });

  const onChangeSpace = useStableCallback((e: RadioChangeEvent) => {
    const field: any = {};
    Object.keys(spaceInfo).forEach((key, index) => {
      field[SPACE_NAME_PREFIX + 'extra-' + index] = '';
    });
    form.setFieldsValue(field);
  });

  const onSaveExit = useWrapCallback(async () => {
    await onSave();
    history.goBack();
  }, debounce(1500));

  useEffect(() => {
    const localCheckInfo = mangLocal(`checkInfo_${params.projectId}_${params.roadId}`);
    getCheckInfo(params.projectId, params.roadId, version)
      .then((res) => {
        if (localCheckInfo && res.suspendIndex < localCheckInfo.suspendIndex) {
          setCheckPointInfo(localCheckInfo);
        } else {
          setCheckPointInfo(res);
        }
      })
      .catch(() => {
        message.error('获取点位列表失败');
      });
  }, [params.projectId, params.roadId, version]);

  useEffect(() => {
    setNowIndex(checkPointInfo?.suspendIndex ?? 0);
    initCheckPoint(checkPointInfo?.suspendIndex);
  }, [checkPointInfo, initCheckPoint]);

  useLayoutEffect(() => {
    scrollDomRef.current?.scrollTo(0, 0);
  }, [nowIndex]);

  if (!checkPointInfo) return null;

  return (
    <div className='start-notes' ref={scrollDomRef}>
      <div className='start-notes-header'>
        <Row>
          <Col xs={0} sm={12}>
            <div className='start-notes-header-title'>路线：{roadName}</div>
          </Col>
          <Col xs={24} sm={12}>
            <div className='flex-x m-s-end'>
              <Button onClick={onSaveExit}>中止检查</Button>
              <Button type='primary' onClick={onSave}>
                保存
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <div className='page-custom'>
        <Form name='road-check' form={form} labelAlign='left'>
          <Card title={`点位号：${nowIndex + 1}`}>
            <Card type='inner' title='空间信息' className='start-notes-card'>
              <Row>
                <Col xs={0} md={3} className='check-title'>
                  空间类型：
                </Col>
                <Col xs={24} md={21}>
                  <Form.Item name='space-info'>
                    <Radio.Group style={{ width: '100%' }} onChange={onChangeSpace}>
                      {Object.entries(spaceInfo)?.map(([key, value]) => (
                        <Row key={key}>
                          <Col className='check-space-name' xs={24} md={5}>
                            {closedSpace[key] ? (
                              <PlusSquareOutlined
                                className='project-setting-icon-wrap'
                                onClick={() => toggleClose(key)}
                              />
                            ) : (
                              <MinusSquareOutlined
                                className='project-setting-icon-wrap'
                                onClick={() => toggleClose(key)}
                              />
                            )}
                            {value.name}
                          </Col>
                          <Col
                            xs={24}
                            md={19}
                            className={`project-setting-form-checkbox ${
                              closedSpace[key] ? 'close' : ''
                            }`}
                          >
                            <Row>
                              {value.list.map((each) => (
                                <Col className='check-space-item' xs={24} md={8} key={each}>
                                  <Radio value={each}>{each}</Radio>
                                </Col>
                              ))}
                            </Row>
                          </Col>
                        </Row>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card type='inner' title='部门信息' className='start-notes-card'>
              <Row>
                <Col xs={0} md={3} className='check-title'>
                  部门：
                </Col>
                <Col xs={24} md={21} className='project-setting-department'>
                  <Form.Item name='departmentTree'>
                    <ControlTree
                      checkable
                      checkStrictly
                      mutiple={false}
                      showLine={false}
                      treeDate={departmentTree}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card type='inner' title='其他' className='start-notes-card'>
              <Form.Item label='公司名称' name='company' wrapperCol={{ span: 6 }}>
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  placeholder='请选择公司名称'
                  optionFilterProp='value'
                  filterOption={(input, option) =>
                    option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {checkPointInfo?.companyList.map((each, index) => (
                    <Option value={each} key={index}>
                      {each}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='备注' name='remark' wrapperCol={{ span: 10 }}>
                <TextArea autoSize style={{ width: '100%' }} placeholder='选填' />
              </Form.Item>
            </Card>
          </Card>
        </Form>
      </div>

      <div className='start-notes-bottom'>
        <Row>
            <Col xs={24} sm={24}>
              <div className='flex-x m-s-end'>
                {nowIndex === 0 ? (
                  <Button onClick={history.goBack}>取消</Button>
                ) : (
                  <Button onClick={prev}>上一个</Button>
                )}

                {nowIndex + 1 < checkPointInfo?.projectOptionList?.length ? (
                  <Button onClick={next} type='primary'>
                    下一个
                  </Button>
                ) : (
                  <Button onClick={onSaveExit} type='primary'>
                    保存
                  </Button>
                )}
              </div>
            </Col>
          </Row>
      </div>
    </div>
  );
};

export default StartCheck;
