import './home.scss';
import { useHistory } from 'react-router-dom';
import home1 from '../../assets/images/home-1.png';
import home2 from '../../assets/images/home-2.png';
import loginBeian from '../../assets/images/beian.png';

import { FC } from 'react';

const Home: FC = () => {
  const history = useHistory();

  return (
    <div className='home flex-x m-t-b'>
      <img className='home-img' src={home1} alt='' onClick={() => history.push('/login')} />
      <img className='home-img' src={home2} alt='' />

      <div className='home-beian flex-x'>
        <a href='https://beian.miit.gov.cn' target='_blank' rel='noreferrer'>
          沪ICP备2022000158号-1
        </a>
        <img src={loginBeian} alt='' />
        <a href='http://www.beian.gov.cn' target='_blank' rel='noreferrer'>
          沪公网安备31010602006625号
        </a>
        <a href='https://mail.163.com/' target='_blank' rel='noreferrer'>
          ·邮箱:siyouspace@163.com
        </a>
      </div>
    </div>
  );
};

export default Home;
