import React, { FC, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useRoadListTable from '../../road/use-road-table';

import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const PorjectRoadEdit: FC = () => {
  const params: any = useParams();
  const history = useHistory();

  const search = useMemo(() => ({ projectId: params.id }), [params.id]);

  const { table } = useRoadListTable({
    search,
    excluedIds: ['projectSequence', 'projectName'],
  });

  return (
    <div className='project-setting-detail project-road-setting common-list'>
      <div className='commom-setting-title'>路线列表</div>
      
      <div className='commom-setting-content'>{table}</div>

      <div className='commom-setting-content' style={{ marginBottom: 8 }}>
        <Button
          ghost
          type='primary'
          block
          className='common-button-dashed'
          onClick={() => history.push('/road/add', { projectId: params.id })}
        >
          <PlusOutlined />
          新增路线
        </Button>
      </div>
    </div>
  );
};

export default PorjectRoadEdit;
