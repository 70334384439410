import React, { FC, useState } from 'react';
import { debounce } from 'lodash/fp';
import { useWrapCallback } from '../../../hooks/use-stable-callback';
import { mangLocal } from '../../../utils/common';

import { download } from '../../../service/download';

import { message, Button, Upload } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

interface Props {
  messageName: string;
  projectId: string;
  downloadUrl: string;
  exportUrl: string;
  onImport: (data: any) => void;
}

const MessageExport: FC<Props> = ({ messageName, projectId, downloadUrl, exportUrl, onImport }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const downloadTpl = useWrapCallback(() => {
    download(downloadUrl, { projectId }, `${messageName}导入模板.xlsx`);
  }, debounce(500));

  const excelProps = {
    name: 'file',
    maxCount: 1,
    showUploadList: false,
    action: `${process.env.REACT_APP_BASE_URL}${exportUrl}`,
    headers: {
      Authorization: mangLocal('userInfo')?.token,
    },
    onChange(info: any) {
      const { status, response } = info.file;
      if (!status) return;
      if (status === 'uploading') {
        setLoading(true);
      } else if (status === 'done') {
        setLoading(false);
        if (response && response.status !== '1') {
          return message.error(response.msg);
        }
        message.success('导入成功');
        onImport(response.data);
      } else {
        setLoading(false);
        message.error('导入失败');
      }
    },
  };

  return (
    <div>
      <Button type='text' style={{ padding: '0 8px' }} onClick={downloadTpl}>
        <DownloadOutlined /> 下载模板
      </Button>
      <Upload {...excelProps}>
        <Button loading={loading}>导入{messageName}</Button>
      </Upload>
    </div>
  );
};

export default MessageExport;
