import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { set, sum } from 'lodash/fp';
import { EditableRow } from '../../common/editable/editable-row';
import {
  DataType,
  EditableCell,
  InputCell,
} from '../../common/editable/editable-cell';
import { useStableCallback } from '../../../hooks/use-stable-callback';

import { Card, Table, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
  value?: any;
  template2Value?: any;
  template3Value?: any;
  template4Value?: any;
  template5Value?: any;
  template6Value?: any;
  onChange?: (newValue: any) => void;
}

const PredictTemplate7: FC<Props> = ({
  value,
  template2Value,
  template3Value,
  template4Value,
  template5Value,
  template6Value,
  onChange,
}) => {
  const inInit = useRef<boolean>(false);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [workNum, setWorkNum] = useState<number>(0);
  const [seatNum, setSeatNum] = useState<number>(0);

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    []
  );

  const handleValueChange = useStableCallback((newValue: DataType[]) => {
    setDataSource(newValue);
    onChange?.({
      usedArea: newValue[0].data[1],
      aisleArea: newValue[1].data[1],
      area: newValue[2].data[1],
      leaseArea: newValue[3].data[1],
      aislePercent: newValue[1].data[2],
      workNum: workNum,
      seatNum: seatNum,
    });
  });

  const handleChange = useStableCallback(
    (row: DataType, changedKey: keyof DataType) => {
      let arr = dataSource.map((each) => {
        if (row.id === each.id) {
          return {
            ...each,
            ...row,
          };
        }
        return each;
      });
      const area = sum([arr[0].data[1], arr[1].data[1]]);
      arr = set([2, 'data', 1], area, arr);
      arr = set([1, 'data', 2], !area ? 0 : arr[1].data[1] / area, arr);
      arr = set(
        [3, 'data', 2],
        !arr[3].data[1] ? 0 : area / arr[3].data[1],
        arr
      );
      handleValueChange(arr);
    }
  );

  const columns = React.useMemo(
    () => [
      {
        title: '租用面积分配',
        dataIndex: 'data|0',
        key: 'data|0',
        render(text: any, record: any) {
          return record.data[0];
        },
      },
      {
        title: (
          <div>
            面积(m²)
            <Tooltip
              placement='top'
              overlayInnerStyle={{ width: '310px' }}
              title={
                <>
                  <p>空间使用净面积：所有空间类型的面积总和</p>
                  <p>走道面积：请测量走道包括次走道的总面积填写</p>
                  <p>净面积：空间使用净面积与走道的面积总和</p>
                  <p>租赁面积：请根据租赁合同填写</p>
                </>
              }
            >
              <InfoCircleOutlined className='title-tips' />
            </Tooltip>
          </div>
        ),
        dataIndex: 'data|1',
        key: 'data|1',
        render(text: any, record: any) {
          return Number(record.data[1]).toFixed(2);
        },
        onCell: (record: DataType): any => ({
          record,
          editable: record.editable,
          editType: 'input',
          title: '面积(m²)',
          dataIndex: 'data|1',
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell
                name={name}
                onChange={onChange}
                isInputNumber={true}
                numberPrecision={2}
                rules={[{ required: true, message: '不可为空' }]}
              >
                {originCell}
              </InputCell>
            );
          },
        }),
      },
      {
        title: '分配说明',
        dataIndex: 'data|2',
        key: 'data|2',
        render: (text: string, record: any, index: number) => {
          if (index === 1) {
            return (
              <div>
                {Number(record.data[2]).toFixed(2)}%
                <Tooltip
                  placement='top'
                  title={'走道面积/(空间使用净面积+走道面积)'}
                >
                  <InfoCircleOutlined className='title-tips' />
                </Tooltip>
              </div>
            );
          }
        },
      },
    ],
    [handleChange]
  );

  useEffect(() => {
    if (value && !inInit.current) {
      inInit.current = true;
      let newValue = [
        ['空间使用净面积', value.usedArea, ''],
        ['走道面积', value.aisleArea, value.aislePercent],
        ['净面积', value.area, ''],
        ['租赁面积', value.leaseArea, ''],
      ];
      const data = newValue.map((each: any[], index) => {
        return {
          id: uuid(),
          editable: ![0, 2].includes(index),
          data: each,
        };
      });
      setDataSource(data);
      setWorkNum(value.workNum);
      setSeatNum(value.seatNum);
    }
  }, [value]);

  useEffect(() => {
    // 工位数量
    const workNumAll = sum(
      template2Value?.tableData?.map((row: any) => row.data[2])
    );
    setWorkNum(workNumAll);

    // 座位数量
    const seatNum3 = sum(
      template3Value?.tableData?.map((row: any) => row.data[2])
    );
    const seatNum4 = sum(
      template4Value?.tableData?.map((row: any) => row.data[3])
    );
    const seatNum5 = sum(
      template5Value?.tableData?.map((row: any) => row.data[3])
    );
    const seatNum6 = sum(
      template6Value?.tableData?.map((row: any) => row.data[3])
    );
    const seatNumAll = sum([seatNum3, seatNum4, seatNum5, seatNum6]);
    setSeatNum(seatNumAll);

    // 空间使用净面积
    const spaceNum2 = sum(
      template2Value?.tableData?.map((row: any) => row.data[2] * row.data[3])
    );
    const spaceNum3 = sum(
      template3Value?.tableData?.map((row: any) => row.data[1] * row.data[3])
    );
    const spaceNum4 = sum(
      template4Value?.tableData?.map((row: any) => row.data[2] * row.data[4])
    );
    const spaceNum5 = sum(
      template5Value?.tableData?.map((row: any) => row.data[2] * row.data[4])
    );
    const spaceNum6 = sum(
      template6Value?.tableData?.map((row: any) => row.data[2] * row.data[4])
    );
    const spaceNumAll = sum([
      spaceNum2,
      spaceNum3,
      spaceNum4,
      spaceNum5,
      spaceNum6,
    ]);

    // 走道面积
    const aisleAll = value?.aisleArea || 0;

    // 净面积
    const areaAll = sum([spaceNumAll, aisleAll]);

    // 租赁面积
    const leaseAll = value?.leaseArea || 0;

    // 走道面积分配说明
    const aislePercent = !areaAll ? 0 : Number(aisleAll / areaAll).toFixed(2);

    let newValue = [
      ['空间使用净面积', spaceNumAll, ''],
      ['走道面积', aisleAll, aislePercent],
      ['净面积', areaAll, ''],
      ['租赁面积', leaseAll, ''],
    ];
    const data = newValue.map((each: any[], index) => {
      return {
        id: uuid(),
        editable: ![0, 2].includes(index),
        data: each,
      };
    });
    setDataSource(data);
    onChange?.({
      usedArea: spaceNumAll,
      aisleArea: aisleAll,
      area: areaAll,
      leaseArea: leaseAll,
      aislePercent: aislePercent,
      workNum: workNumAll,
      seatNum: seatNumAll,
    });
    // 不可监听value、onChange，否则死循环
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    template2Value,
    template3Value,
    template4Value,
    template5Value,
    template6Value,
  ]);

  return (
    <Card
      id='step7'
      className='space-predict-edit-table'
      type='inner'
      title='租用面积分配'
    >
      <div className='rental-area flex-x m-s-start a-s-center'>
        <div>
          工位数量：{workNum}
          <Tooltip placement='top' title={'个人空间的空间数量总和'}>
            <InfoCircleOutlined className='title-tips' />
          </Tooltip>
        </div>
        <div>
          座位数量：{seatNum}
          <Tooltip placement='top' title={'所有空间类型的座位数量总和'}>
            <InfoCircleOutlined className='title-tips' />
          </Tooltip>
        </div>
      </div>
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        components={components}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </Card>
  );
};

export default PredictTemplate7;
