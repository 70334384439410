import React from 'react';
import { Link } from 'react-router-dom';
import { projectStatusConvert } from '../../utils/definition';

import { Project } from '../../service/project';

import { ColumnsType } from 'antd/lib/table';

export const getProjectColumns = (
  renderOpeartor?: (record: Project) => React.ReactElement,
  excluedIds: string[] = [],
  link: boolean = true,
): ColumnsType<Project> => {
  const list: ColumnsType<Project> = [
    {
      title: '项目ID',
      dataIndex: 'projectSequence',
      key: 'projectSequence',
      render: (text: string, record: any) => {
        if (link) {
          return <Link to={`/project/detail/${record.projectId}`}>{text}</Link>;
        }
        return text;
      },
      fixed: 'left' as any,
      width: 120,
    },
    {
      title: '项目名称',
      dataIndex: 'projectName',
      key: 'projectName',
      width: 160,
    },
    {
      title: '路线数量',
      dataIndex: 'roadNum',
      key: 'roadNum',
      align: 'center' as any,
      width: 80,
    },
    {
      title: '管理员',
      dataIndex: 'accountNameList',
      key: 'accountNameList',
      ellipsis: true,
      width: 180,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <div className='flex-x m-s-start table-status'>
          <div className={`circle ${projectStatusConvert[status]?.[1]}`}></div>
          <div>{projectStatusConvert[status]?.[0]}</div>
        </div>
      ),
      width: 100,
    },
    {
      title: '更新时间',
      key: 'updateTime',
      dataIndex: 'updateTime',
      width: 180,
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  if (renderOpeartor) {
    list.push({
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: 140,
      fixed: 'right' as any,
      render: (text: string, record: Project) => renderOpeartor(record),
    });
  }

  return list;
};
