import React, { useState } from 'react';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { filterParams } from '../../utils/common';

import PersonnelListQuery from './query';
import PersonnelListTable from './table';

const PersonnelManagement = () => {
  const [searchForm, setSearchForm] = useState<{ [key: string]: any }>({});

  const onSearchFinish = useStableCallback((values) => {
    setSearchForm(filterParams(values));
  });
  const onSearchReset = useStableCallback((values) => {
    setSearchForm(filterParams(values));
  });

  return (
    <>
      <div className='common-title'>人员管理</div>
      <PersonnelListQuery onFinish={onSearchFinish} onReset={onSearchReset} />
      <PersonnelListTable searchForm={searchForm} />
    </>
  );
};

export default PersonnelManagement;
