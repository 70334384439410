import { preFetch } from './fetch';

export const download = async (url: string, body: Object, fileName: string = '') => {
  const data = await preFetch(url, { method: 'POST', format: 'blob', body });
  const bolbUrl = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  a.download = fileName;
  a.href = bolbUrl;
  a.click();
};
