import './index.scss';
import mouse from '../../assets/images/mouse.png';

import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { mangLocal } from '../../utils/common';
import {
  closeSetFloorsNumberModal,
  openSetFloorsNumberModal,
} from './modal/set-floors-number';

import BreadcrumbCustom from '../breadcrumb-custom';

import { getProjectDetail, Project } from '../../service/project';
import { download } from '../../service/download';
import { getProjectPredictStatus } from '../../service/space';

import { Button, Card, Col, message, Row } from 'antd';

const SpacePredictHome = () => {
  const history = useHistory();
  const params: any = useParams();

  const userInfo = mangLocal('userInfo');

  const [projectDetail, setProjectDetail] = useState<Project | null>(null);

  const exportPredictInfo = useStableCallback(() => {
    download(
      '/predict/downloadPredictInfo',
      { predictList: [params.id] },
      '预测信息.xlsx'
    );
  });

  const startRow = useStableCallback(async (step: number) => {
    try {
      const res = await getProjectPredictStatus(params.id);
      if (res.hasPredictData === 1) {
        history.push(`/project/space/predict/${params.id}`, { step });
        return;
      }
      openSetFloorsNumberModal({
        visible: true,
        onCancel: closeSetFloorsNumberModal,
        onConfirm: (value) =>
          history.push(`/project/space/predict/${params.id}`, {
            step,
            floorNum: value.floorNum,
            predictDate: moment(value.predictDate).format('YYYY-MM'),
          }),
      });
    } catch (err) {}
  });

  const seeTotal = useStableCallback(async () => {
    try {
      const res = await getProjectPredictStatus(params.id);
      if (res.hasPredictData === 1) {
        history.push(`/project/space/total/${params.id}`);
        return;
      }
      message.warning('暂无预测数据，请先填写预测信息');
    } catch (err) {}
  });

  useEffect(() => {
    getProjectDetail(params.id)
      .then(setProjectDetail)
      .catch(() => {});
  }, [params.id]);

  const extraButton = useMemo(() => {
    return (
      <>
        {userInfo?.role >= 3 && (
          <Button onClick={exportPredictInfo}>导出预测信息</Button>
        )}
      </>
    );
  }, [exportPredictInfo, userInfo?.role]);

  return (
    <>
      <BreadcrumbCustom
        paths={['项目管理', '空间预测']}
        title={`项目：${projectDetail?.projectSequence ?? ''}（${
          projectDetail?.projectName ?? ''
        }）`}
        extra={extraButton}
      />

      <div className='space-predict-home'>
        <Row gutter={[32, 32]}>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              hoverable
              actions={[<Button type='link'>开始</Button>]}
              onClick={() => startRow(1)}
            >
              <div className='title'>人员数量以及个人空间分配原则</div>
              <div className='desc'>
                人员组成会有不同也有多种方式来进行人员数量的统计，包括但不限于：
                <br />
                - FTE（全职人员）
                <br />
                - 实习生
                <br />
                - 非在编员工等
                <br />
                <br />
                不同人员因为工种、职级等也会有不同的工作方式。根据不同的工作方式，是如何分配个人办公空间的？
              </div>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              hoverable
              actions={[<Button type='link'>开始</Button>]}
              onClick={() => startRow(2)}
            >
              <div className='title'>个人办公空间</div>

              <div className='desc'>
                可供一个人根据不同的工作行为进行选择的空间，包括但不限于：
                <br />
                - 封闭办公室
                <br />
                - 固定工位/移动工位
                <br />
                - 专注间/电话间
                <br />
                - 可供个人或团队工作的项目桌等
                <br />
                <br />
                可用不同名称和分类方式，个人办公空间也不局限于某几个空间类型，可根据实际情况自行考量
              </div>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              hoverable
              actions={[<Button type='link'>开始</Button>]}
              onClick={() => startRow(3)}
            >
              <div className='title'>楼层协同空间</div>
              <div className='desc'>
                可多人进行团队协作的空间，包括但不限于：
                <br />
                - 根据不同与会人员，不同会议规模进行匹配的不同规格的封闭会议室
                <br />
                - 带有协作设备的开放协作空间
                <br />
                <br />
                辅助协作的工具越来越多样，协作方式也随之多元，可根据实际情况自行考量
              </div>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              hoverable
              actions={[<Button type='link'>开始</Button>]}
              onClick={() => startRow(4)}
            >
              <div className='title'>楼层支持空间</div>
              <div className='desc'>
                为楼层提供支持功能的空间，包括但不限于：
                <br />
                - 机房/打印
                <br />
                - 饮水点/茶水间
                <br />
                - 母婴室/更衣室
                <br />
                - 储藏室/衣柜文件柜等
                <br />
                <br />
                随着办公空间功能的多样化，或许我们会有更生活化的支持功能空间出现
              </div>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              hoverable
              actions={[<Button type='link'>开始</Button>]}
              onClick={() => startRow(5)}
            >
              <div className='title'>其他支持空间</div>
              <div className='desc'>
                集中为一个楼层或者多个楼层提供支持功能的相关空间，包括但不限于：
                <br />
                - 总机房
                <br />
                - 收发室/安保间
                <br />
                - 健身空间
                <br />
                - 前台等
                <br />
                <br />
                办公空间场景的多元化，支持空间除了功能外，或许也更灵活了。
              </div>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              hoverable
              actions={[<Button type='link'>开始</Button>]}
              onClick={() => startRow(6)}
            >
              <div className='title'>其他需求功能空间</div>
              <div className='desc'>
                不同团队业务的需求，再经过一系列考量后，或许也会在办公空间入驻，包括但不限于：
                <br />
                - 实验室
                <br />
                - 直播间
                <br />
                - 供餐餐厅
                <br />
                - 展示空间等
                <br />
                <br />
                相关空间除了面积，更多会有其他考虑因素，请根据实际情况自行考量
              </div>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              hoverable
              actions={[<Button type='link'>开始</Button>]}
              onClick={() => startRow(7)}
            >
              <div className='title'>楼层通道等面积</div>
              <div className='desc'>
                租赁面积的组成有很多，除了以上的不同的空间使用面积外，还包括其他但不限于：
                <br />
                - 主走道
                <br />
                - 次走道
                <br />
                - 楼层工程类机房
                <br />
                - 分摊面积等
                <br />
                <br />
                建议在使用面积小计后，可根据租赁面积来了解相关空间的走道面积比例，以及其他面积组成
              </div>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <div className='total' onClick={() => seeTotal()}>
              <div className='col1'>总计</div>
              <div className='col2'>
                这里你能看到完整的以上所有面积的总览，以便有整体了解
              </div>
              <div className='col3 flex-x a-s-center m-s-start'>
                <p className='text'>点击查看</p>
                <img className='img' src={mouse} alt='mouse' />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SpacePredictHome;
