import SiderCustom from './components/sider-custom';
import HeaderCustom from './components/header-custom';
import PageCustom from './components/page-custom';

import { Layout } from 'antd';
const { Content } = Layout;

const App = () => {
  return (
    <div className='App'>
      <Layout style={{ minHeight: '100vh' }}>
        <HeaderCustom />
        <Layout>
          <SiderCustom />
          <Content style={{ height: 'calc(100vh - 48px)', overflow: 'auto' }}>
            <PageCustom />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default App;
