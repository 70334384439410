import './predict.scss';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';

import BreadcrumbCustom from '../breadcrumb-custom';
import HelpFeedback from '../user/help-feedback';
import TotalTabpane from './total-tabpane';

import { getProjectPredictStatus, getTotalData } from '../../service/space';

import { Tabs } from 'antd';

const SpacePredictTotal = () => {
  const params: any = useParams();

  const [activeKey, setActiveKey] = useState<string>('1');
  const [isHelpVisible, setIsHelpVisible] = useState<boolean>(false);
  const [totalData, setTotalData] = useState({});

  const onTabsChange = useStableCallback(async (key: string) => {
    if (key === '2') {
      const res = await getProjectPredictStatus(params.id);
      if (res.predictAuth === 2) {
        setIsHelpVisible(true);
        return;
      }
    }
    setActiveKey(key);
    getData();
  });

  const onHelpChange = useStableCallback(() => {
    setIsHelpVisible(false);
  });

  const getData = useStableCallback(async () => {
    try {
      const res = await getTotalData(params.id, activeKey);
      setTotalData(res);
    } catch (err) {}
  });

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <BreadcrumbCustom paths={['项目管理', '空间预测']} title={'空间预测'} />

      <div className='space-predict-edit'>
        <Tabs
          className='tabs'
          tabBarGutter={64}
          activeKey={activeKey}
          onChange={onTabsChange}
        >
          <Tabs.TabPane tab='目前' key='1'>
            <TotalTabpane projectId={params.id} totalData={totalData} />
          </Tabs.TabPane>
          <Tabs.TabPane tab='未来' key='2'>
            <TotalTabpane projectId={params.id} totalData={totalData} />
          </Tabs.TabPane>
        </Tabs>
      </div>

      {isHelpVisible && (
        <HelpFeedback
          isHelpVisible={isHelpVisible}
          title={'开通空间预测'}
          subtitle={'请联系客服'}
          onChange={onHelpChange}
        />
      )}
    </>
  );
};

export default SpacePredictTotal;
