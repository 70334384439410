import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { mangLocal } from '../../utils/common';
import { publishRoad } from '../../service/road';
import { download } from '../../service/download';

import useRoadListTable from './use-road-table';

import { Button, Modal, message } from 'antd';
import { ReloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const exportInfoMap: { [key: string]: [string, string] } = {
  observe: ['/observe/downloadRoadObserveInfo', '路线观察数据.xlsx'],
  check: ['/observe/downloadRoadCheckInfo', '路线检查数据.xlsx'],
};

interface Props {
  searchForm?: { [key: string]: any };
}

const RoadListTable: FC<Props> = ({ searchForm }) => {
  const history = useHistory();

  const userInfo = mangLocal('userInfo');

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const { getList, pageData, table } = useRoadListTable({
    search: searchForm,
    rowSelection: {
      selectedRowKeys,
      onChange: onSelectChange,
    },
  });

  const batchPublish = useStableCallback(() => {
    if (!selectedRowKeys.length) return message.warning('请先选择需要开始观察的路线');
    Modal.confirm({
      title: `确定开始观察选择的路线吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          await publishRoad(selectedRowKeys);
          message.success('开始观察成功');
          getList(pageData.page);
        } catch (err) {}
      },
    });
  });

  const batchExport = useStableCallback((type: string) => {
    if (!selectedRowKeys.length) return message.warning('请先选择需要导出的路线');
    download(exportInfoMap[type][0], { roadIdList: selectedRowKeys }, exportInfoMap[type][1]);
  });

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>路线列表</div>
        <div className='header-operate'>
          {userInfo?.role >= 3 && <Button onClick={batchPublish}>开始观察</Button>}
          <Button onClick={() => batchExport('observe')}>导出观察数据</Button>
          <Button onClick={() => batchExport('check')}>导出检查数据</Button>
          <Button type='primary' onClick={() => history.push('/road/add')}>
            创建路线
          </Button>
          <ReloadOutlined className='refresh' onClick={() => getList(1)} />
        </div>
      </div>

      {table}
    </div>
  );
};

export default RoadListTable;
