import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { getDefaultObserveList, ObserveList } from '../../utils/definition';
import RoadConfig from './edit/config';

import { createRoad, RoadDetail } from '../../service/road';

import { Form, Button } from 'antd';

const RoadAdd: FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [observeList, setObserveList] = useState<ObserveList>(getDefaultObserveList);

  const onSubmit = useStableCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      const roadObserveInfoList: RoadDetail['roadObserveInfoList'] = [];

      Object.entries(observeList).forEach(([key, value]: any) => {
        if (values[key]?.length) {
          roadObserveInfoList.push({
            roadObserveName: value.name,
            observeItemList: values[key].map((each: any) => ({ itemName: each })),
          });
        }
        return null;
      });

      const road: RoadDetail = {
        projectId: values.projectId,
        roadName: values.roadName,
        startDate: values.dateRange[0].format('YYYYMMDD'),
        endDate: values.dateRange[1].format('YYYYMMDD'),
        startTime: values.timeRange[0].format('HHmmss'),
        endTime: values.timeRange[1].format('HHmmss'),
        repetition: values.repetition,
        intervalNum: values.intervalNum,
        interval: values.interval,
        userNum: values.userNum,
        roadObserveInfoList,
      };
      const { data } = await createRoad(road);
      history.replace(`/road/add/success/${data.roadId}`);
    } catch (err) {}
  });

  return (
    <div className='page-custom'>
      <RoadConfig form={form} observeList={observeList} onObserveListChange={setObserveList} />

      <div className='common-buttons flex-x m-s-end minauto'>
        <Button onClick={history.goBack}>取消</Button>
        <Button type='primary' onClick={onSubmit}>
          保存
        </Button>
      </div>
    </div>
  );
};

export default RoadAdd;
