import React, { FC, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { getDefaultObserveList, ObserveList } from '../../../utils/definition';
import RoadConfig from './config';

import { editRoad, RoadDetail } from '../../../service/road';

import { Form, Button, message } from 'antd';

interface Props {
  road?: RoadDetail;
  onChange?: () => void;
  onSave: () => void;
}

const RoadEdit: FC<Props> = ({ road, onChange, onSave }) => {
  const history = useHistory();
  const params: any = useParams();
  const [form] = Form.useForm();
  const [observeList, setObserveList] = useState<ObserveList>(getDefaultObserveList);

  const onSubmit = useStableCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      const road: Partial<RoadDetail> = {
        projectId: values.projectId,
        roadId: params.id,
        roadName: values.roadName,
        startDate: values.dateRange[0].format('YYYYMMDD'),
        endDate: values.dateRange[1].format('YYYYMMDD'),
        startTime: values.timeRange[0].format('HHmmss'),
        endTime: values.timeRange[1].format('HHmmss'),
        repetition: values.repetition,
        intervalNum: values.intervalNum,
        interval: values.interval,
        userNum: values.userNum,
        roadObserveInfoList: Object.entries(observeList)
          .map(([key, value]: any) => {
            if (values[key]?.length) {
              return {
                roadObserveName: value.name,
                observeItemList: values[key].map((each: any) => ({ itemName: each })),
              };
            }
            return null;
          })
          .filter((each) => each) as RoadDetail['roadObserveInfoList'],
      };

      await editRoad(road);
      onSave();
      message.success('保存成功');
    } catch (err) {}
  });

  useEffect(() => {
    if (!road) return;

    const field: any = {
      roadName: road.roadName,
      projectId: road.projectId,
      dateRange: [moment(road.startDate, 'YYYY-MM-DD'), moment(road.endDate, 'YYYY-MM-DD')],
      timeRange: [moment(road.startTime, 'HH:mm'), moment(road.endTime, 'HH:mm')],
      repetition: road.repetition,
      intervalNum: road.intervalNum,
      interval: road.interval,
      userNum: road.userNum,
    };

    const observeList = getDefaultObserveList();
    road.roadObserveInfoList.forEach((each, index) => {
      if (each.roadObserveName === observeList.spaceStatus.name) {
        field.spaceStatus = each.observeItemList.map((each) => each.itemName);
        each.observeItemList.forEach((each) => {
          if (!observeList.spaceStatus.list.includes(each.itemName)) {
            observeList.spaceStatus.list.push(each.itemName);
          }
        });
        return;
      }
      if (each.roadObserveName === observeList.tool.name) {
        field.tool = each.observeItemList.map((each) => each.itemName);
        each.observeItemList.forEach((each) => {
          if (!observeList.tool.list.includes(each.itemName)) {
            observeList.tool.list.push(each.itemName);
          }
        });
        return;
      }

      field['extra' + index] = each.observeItemList.map((each) => each.itemName);
      observeList['extra' + index] = {
        name: each.roadObserveName,
        list: each.observeItemList.map((each) => each.itemName),
      };
    });

    form.setFieldsValue(field);
    setObserveList(observeList);
  }, [form, params.id, road]);

  return (
    <>
      <RoadConfig
        form={form}
        onChange={onChange}
        observeList={observeList}
        onObserveListChange={setObserveList}
      />

      <div className='flex-x m-s-end minauto' style={{margin: '0 32px'}}>
        <Button onClick={history.goBack}>取消</Button>
        <Button type='primary' onClick={onSubmit}>
          保存
        </Button>
      </div>
    </>
  );
};

export default RoadEdit;
