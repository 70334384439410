import React, { FC, useEffect, useMemo, useState } from 'react';
import { get, sum } from 'lodash/fp';
import { otherFeaturesColumn } from '../../../utils/predict';

import { Card, Table } from 'antd';

interface Props {
  value?: any[];
}

const OtherFeaturesTable: FC<Props> = ({ value }) => {
  const [dataSource, setDataSource] = useState<any[]>([]);

  const columns = useMemo(() => {
    return [
      ...[...otherFeaturesColumn, '总面积(m²)'].map((item, index) => {
        return {
          title: item,
          dataIndex: 'data.' + index,
          key: 'data.' + index,
          ellipsis: item === '分配说明',
          render(text: any, record: any) {
            return get('data.' + index, record);
          },
        };
      }),
    ];
  }, []);

  useEffect(() => {
    if (value && value.length) {
      setDataSource(value[0].tableData);
    }
  }, [value]);

  return (
    <Card
      className='space-predict-edit-table'
      type='inner'
      title='其他功能空间'
    >
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        summary={() => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                小计
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                {sum(dataSource.map((row) => row.data[2]))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} colSpan={2}>
                {sum(dataSource.map((row) => row.data[3]))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                {sum(dataSource.map((row) => row.data[5])).toFixed(2)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Card>
  );
};

export default OtherFeaturesTable;
