import { useRef } from 'react';

export const useStableCallback = <T extends (...args: any[]) => any>(callback: T): T => {
  const ref = useRef({
    currentCall: callback,
    useCall: (...args: any[]) => {
      return (ref.current as any).currentCall(...args);
    },
  });

  ref.current.currentCall = callback;

  return ref.current.useCall as any;
};

export const useWrapCallback = <T extends (...args: any[]) => any>(
  callback: T,
  wrapCall: (call: any) => Function,
): T => {
  const ref = useRef({
    currentCall: callback,
    useCall: wrapCall((...args: any[]) => {
      return (ref.current as any).currentCall(...args);
    }),
  });

  ref.current.currentCall = callback;

  return ref.current.useCall as any;
};
