import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import {
  getDefaultDepartmentTree,
  getDefaultSpaceInfo,
  industryList,
  SpaceInfo,
} from '../../../utils/definition';
import { cityList } from '../../../utils/city';
import { AddNew } from '../../common/add-new';
import { ControlTree, SPLIT_VALUE, TreeNode } from '../../common/contorl-tree';
import MessageExport from './message-export';

import { DepartmentInfo, editProject, Project } from '../../../service/project';

import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  DatePicker
} from 'antd';
import {
  MinusCircleOutlined,
  MinusSquareOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
const { Option } = Select;

interface Props {
  project?: Project;
  onChange: () => void;
  onSave: () => void;
}

const SPACE_NAME_PREFIX = 'SpaceName-';

const PorjectBaseSetting: FC<Props> = ({ project, onChange, onSave: outSave }) => {
  const params: any = useParams();
  const history = useHistory();
  const [form] = Form.useForm();

  const [closedSpace, setCloseSpace] = useState<{ [key: string]: boolean }>({});
  const [spaceInfo, setSpaceInfo] = useState<SpaceInfo>(getDefaultSpaceInfo);
  const [departmentTree, setDepartmentTree] = useState<TreeNode>(getDefaultDepartmentTree);

  const toggleClose = useStableCallback((key: string) => {
    setCloseSpace({
      ...closedSpace,
      [key]: !closedSpace[key],
    });
  });

  const addSpaceList = useStableCallback((type: string, value: string) => {
    const newSpaceList = { ...spaceInfo };
    const newList = [...newSpaceList[type].list, value];
    newSpaceList[type] = {
      ...newSpaceList[type],
      list: newList,
    };
    setSpaceInfo(newSpaceList);
  });

  const addSpace = useStableCallback((value: string) => {
    const len = Object.keys(spaceInfo).length;
    setSpaceInfo({ ...spaceInfo, ['extar-' + len]: { name: value, list: [] } });
  });

  const addDepartment = useStableCallback(
    (name: string, level: number = 1, parentKeys: string[] = []) => {
      let parent = departmentTree as TreeNode | undefined;
      if (level >= 2) {
        parent = parent?.children?.find((each) => each.name === parentKeys[0]);
      }
      if (level === 3) {
        parent = parent?.children?.find((each) => each.name === parentKeys[1]);
      }

      if (!parent) return;

      if (parent?.children?.some((each) => each.name === name)) {
        message.error('命名重复');
        return;
      }

      const oldChildren = parent.children ?? [];
      parent.children = [...oldChildren, { name }];
      let departmentTreeSelect: string[] = form.getFieldValue('departmentTree');
      departmentTreeSelect.push([...parentKeys, name].join(SPLIT_VALUE));

      form.setFieldsValue({ departmentTree: departmentTreeSelect });
      setDepartmentTree({ ...departmentTree });
    },
  );

  const onSave = useStableCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      const selectDepartmentList: string[][] = values.departmentTree.map((each: string) =>
        each.split(SPLIT_VALUE),
      );
      const departmentList: DepartmentInfo[] = [];
      selectDepartmentList.forEach((each) => {
        const level1Name = each[0];
        let level1Item = departmentList.find((each) => each.departmentName === level1Name);
        if (!level1Item) {
          level1Item = {
            departmentName: level1Name,
            projectDepartmentDetailList: [],
          };
          departmentList.push(level1Item);
        }

        if (!each[1]) return;
        const level2Name = each[1];
        let level2Item = level1Item.projectDepartmentDetailList?.find(
          (each) => each.departmentDetailName === level2Name,
        );
        if (!level2Item) {
          level2Item = {
            departmentDetailName: level2Name,
            projectDepartmentDetailThreeList: [],
          };
          level1Item.projectDepartmentDetailList!.push(level2Item);
        }

        if (!each[2]) return;
        const level3Name = each[2];
        let level3Item = level2Item.projectDepartmentDetailThreeList?.find(
          (each) => each.detailName === level3Name,
        );
        if (!level3Item) {
          level3Item = {
            detailName: level3Name,
          };
          level2Item.projectDepartmentDetailThreeList!.push(level3Item);
        }
      });

      const project: Project = {
        projectId: params.id,
        projectName: values.projectName,
        industry: values.industry,
        city: values.city,
        address: values.address,
        floor: values.floor,
        area: values.area,
        expireDate: values.expireDate,
        notifyDate: values.notifyDate,

        projectSpaceInfoList: Object.keys(spaceInfo)
          .filter((key) => values[SPACE_NAME_PREFIX + key]?.length)
          .map((key) => ({
            spaceName: spaceInfo[key].name,
            projectSpaceDetailList: values[SPACE_NAME_PREFIX + key].map((each: string) => ({
              detailName: each,
            })),
          })),

        company: values.company,
        projectDepartmentInfoList: departmentList,
      };

      await editProject(project);
      outSave();
      message.success('保存成功');
    } catch (err) {}
  });

  const onImportSpaceInfo = useStableCallback((data: any) => {
    const newSpaceInfo: any = {};
    const formField: any = {};

    (data.projectSpaceInfoList as Project['projectSpaceInfoList'])?.forEach((each, index) => {
      formField[SPACE_NAME_PREFIX + 'extra-' + index] = each.projectSpaceDetailList
        .filter((each) => each.checked)
        .map((each) => each.detailName);
      newSpaceInfo['extra-' + index] = {
        name: each.spaceName,
        list: each.projectSpaceDetailList.map((each) => each.detailName),
      };
    });

    form.setFieldsValue(formField);
    setSpaceInfo(newSpaceInfo);
  });

  const onImportDepartmentInfo = useStableCallback((data: any) => {
    const rootItem: TreeNode = { name: 'root', children: [] };
    const formField: any = {};
    const departmentTreeKeys: string[] = [];

    (data.projectDepartmentInfoList as Project['projectDepartmentInfoList'])?.forEach((level1) => {
      let level1Item = rootItem.children?.find((each) => each.name === level1.departmentName);

      if (!level1Item) {
        level1Item = {
          name: level1.departmentName as string,
          children: [],
        };
        rootItem.children!.push(level1Item);
      }
      if (!level1Item.children) {
        level1Item.children = [];
      }

      if (level1.checked) {
        departmentTreeKeys.push(level1Item.name as string);
      }

      if (!level1.projectDepartmentDetailList?.length) {
        return;
      }

      level1.projectDepartmentDetailList?.forEach((level2) => {
        let level2Item = level1Item!.children?.find(
          (each) => each.name === level2.departmentDetailName,
        );
        if (!level2Item) {
          level2Item = {
            name: level2.departmentDetailName as string,
            children: [],
          };
          level1Item!.children!.push(level2Item);
        }
        if (!level2Item.children) {
          level2Item.children = [];
        }

        if (level2.checked) {
          departmentTreeKeys.push(level1Item?.name + SPLIT_VALUE + level2Item?.name);
        }

        if (!level2.projectDepartmentDetailThreeList?.length) {
          return;
        }

        level2.projectDepartmentDetailThreeList?.forEach((level3) => {
          let level3Item = level2Item!.children?.find((each) => each.name === level3.detailName);
          if (!level3Item) {
            level3Item = {
              name: level3.detailName as string,
            };
            level2Item!.children!.push(level3Item);
          }

          if (level3.checked) {
            departmentTreeKeys.push(
              level1Item?.name + SPLIT_VALUE + level2Item?.name + SPLIT_VALUE + level3Item.name,
            );
          }
        });
      });
    });

    formField.departmentTree = departmentTreeKeys;
    form.setFieldsValue(formField);
    setDepartmentTree(rootItem);
  });

  useEffect(() => {
    if (!project) return;

    const field: any = {
      projectName: project.projectName,
      industry: project.industry,
      city: project.city,
      address: project.address,
      floor: project.floor,
      area: project.area,
      company: project.company,
      expireDate: project.expireDate == null ? null : moment(project.expireDate, 'YYYY-MM-DD'),
      notifyDate: project.notifyDate == null ? null : moment(project.notifyDate, 'YYYY-MM-DD HH:mm'),
    };

    const departmentTreeKeys: string[] = [];
    const newDepartmentInfo: TreeNode = getDefaultDepartmentTree();
    const rootItem = newDepartmentInfo;
    project.projectDepartmentInfoList?.forEach((level1) => {
      let level1Item = rootItem.children?.find((each) => each.name === level1.departmentName);
      if (!level1Item) {
        level1Item = {
          name: level1.departmentName as string,
          children: [],
        };
        rootItem.children!.push(level1Item);
      }
      if (!level1Item.children) {
        level1Item.children = [];
      }

      if (!level1.projectDepartmentDetailList?.length) {
        departmentTreeKeys.push(level1.departmentName as string);
        return;
      }

      level1.projectDepartmentDetailList?.forEach((level2) => {
        let level2Item = level1Item!.children?.find(
          (each) => each.name === level2.departmentDetailName,
        );
        if (!level2Item) {
          level2Item = {
            name: level2.departmentDetailName as string,
            children: [],
          };
          level1Item!.children!.push(level2Item);
        }
        if (!level2Item.children) {
          level2Item.children = [];
        }

        if (!level2.projectDepartmentDetailThreeList?.length) {
          departmentTreeKeys.push(level1Item?.name + SPLIT_VALUE + level2Item?.name);
          return;
        }

        level2.projectDepartmentDetailThreeList?.forEach((level3) => {
          let level3Item = level2Item!.children?.find((each) => each.name === level3.detailName);
          if (!level3Item) {
            level3Item = {
              name: level3.detailName as string,
            };
            level2Item!.children!.push(level3Item);
          }

          departmentTreeKeys.push(
            level1Item?.name + SPLIT_VALUE + level2Item?.name + SPLIT_VALUE + level3Item.name,
          );
        });
      });
    });

    setDepartmentTree(newDepartmentInfo);
    field.departmentTree = departmentTreeKeys;

    const newSpaceInfo = getDefaultSpaceInfo();
    project.projectSpaceInfoList?.forEach((each, index) => {
      if (each.spaceName === newSpaceInfo.station.name) {
        field[SPACE_NAME_PREFIX + 'station'] = each.projectSpaceDetailList.map(
          (each) => each.detailName,
        );
        each.projectSpaceDetailList.forEach((each) => {
          if (!newSpaceInfo.station.list.includes(each.detailName)) {
            newSpaceInfo.station.list.push(each.detailName);
          }
        });
        return;
      }
      if (each.spaceName === newSpaceInfo.meetingRoom.name) {
        field[SPACE_NAME_PREFIX + 'meetingRoom'] = each.projectSpaceDetailList.map(
          (each) => each.detailName,
        );
        each.projectSpaceDetailList.forEach((each) => {
          if (!newSpaceInfo.meetingRoom.list.includes(each.detailName)) {
            newSpaceInfo.meetingRoom.list.push(each.detailName);
          }
        });
        return;
      }

      field[SPACE_NAME_PREFIX + 'extra-' + index] = each.projectSpaceDetailList.map(
        (each) => each.detailName,
      );
      newSpaceInfo['extra-' + index] = {
        name: each.spaceName,
        list: each.projectSpaceDetailList.map((each) => each.detailName),
      };
    });
    setSpaceInfo(newSpaceInfo);

    form.setFieldsValue(field);
  }, [form, project]);

  return (
    <div className='project-setting-detail project-road-setting common-list'>
      <Form form={form} layout='vertical' onChange={onChange}>
        <div className='commom-setting-title'>基本信息</div>
        <div className='commom-setting-content'>
          <Row gutter={32}>
            <Col span={8}>
              <Form.Item
                label='项目名称'
                name='projectName'
                rules={[{ required: true, message: '请输入项目名称' }]}
              >
                <Input placeholder='请输入项目名称' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='所属行业'
                name='industry'
                rules={[{ required: true, message: '请选择项目所属行业' }]}
              >
                <Select placeholder='请选择项目所属行业'>
                  {industryList.map((each, index) => (
                    <Option value={each} key={index}>
                      {each}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='项目城市'
                name='city'
                rules={[{ required: true, message: '请选择项目所在城市' }]}
              >
                <Cascader
                  fieldNames={{ label: 'name', value: 'name', children: 'mallCityList' }}
                  options={cityList}
                  placeholder='请选择项目所在城市'
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='项目地点'
                name='address'
                rules={[{ required: true, message: '请输入项目详细地址' }]}
              >
                <Input placeholder='请输入项目详细地址' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='楼层数量'
                name='floor'
                rules={[{ required: true, message: '请输入楼层数' }]}
              >
                <InputNumber style={{ width: '100%' }} min='0' placeholder='请输入楼层数' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='面积(m²)'
                name='area'
                rules={[{ required: true, message: '请输入建筑面积' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min='0'
                  precision={2}
                  placeholder='请输入建筑面积'
                />
              </Form.Item>
            </Col>
            <Col span={8}>  
              <Form.Item
                label='租约到期日'
                name='expireDate'
                // rules={[{ required: true, message: '请选择租约到期日' }]}
              >
                <DatePicker format='YYYY-MM-DD' style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>    
              <Form.Item
                label='租约提醒时间'
                name='notifyDate'
                // rules={[{ required: true, message: '请选择租约提醒时间' }]}
              >
                <DatePicker showTime format='YYYY-MM-DD HH:mm' style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className='commom-setting-title flex-x m-s-between'>
          <p>空间信息</p>
          <MessageExport
            messageName={'空间信息'}
            projectId={params.id}
            downloadUrl={'/project/downloadProjectSpaceInfo'}
            exportUrl={'/project/uploadProjectSpaceInfo'}
            onImport={onImportSpaceInfo}
          />
        </div>
        <div className='commom-setting-content'>
          <Row>
            <Col span={3}>空间类型：</Col>
            <Col span={21}>
              {Object.entries(spaceInfo)?.map(([key, value]) => (
                <Row key={key}>
                  <Col span={5}>
                    {closedSpace[key] ? (
                      <PlusSquareOutlined
                        className='project-setting-icon-wrap'
                        onClick={() => toggleClose(key)}
                      />
                    ) : (
                      <MinusSquareOutlined
                        className='project-setting-icon-wrap'
                        onClick={() => toggleClose(key)}
                      />
                    )}
                    {value.name}
                  </Col>
                  <Col span={19}>
                    <Form.Item
                      name={SPACE_NAME_PREFIX + key}
                      className={`project-setting-form-checkbox ${closedSpace[key] ? 'close' : ''}`}
                    >
                      <Checkbox.Group style={{ width: '100%' }}>
                        <Row>
                          {value.list.map((each) => (
                            <Col span={8} key={each}>
                              <Checkbox value={each}>{each}</Checkbox>
                            </Col>
                          ))}

                          <Col span={8}>
                            <AddNew
                              name='添加其他'
                              onChange={(value) => addSpaceList(key, value)}
                            />
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col span={5}>
                  <AddNew name='其他空间类型' onChange={addSpace} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className='commom-setting-title flex-x m-s-between' style={{ marginTop: 40 }}>
          <p>部门信息</p>
          <MessageExport
            messageName={'部门信息'}
            projectId={params.id}
            downloadUrl={'/project/downloadProjectDepartmentInfo'}
            exportUrl={'/project/uploadProjectDepartmentInfo'}
            onImport={onImportDepartmentInfo}
          />
        </div>
        <div className='commom-setting-content'>
          <Row>
            <Col span={3} style={{ paddingTop: 5 }}>
              公司名称：
            </Col>
            <Col span={10}>
              <Form.List name='company'>
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item key={field.key}>
                        <Form.Item
                          noStyle
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: '请输入公司名称或删除该项',
                            },
                          ]}
                        >
                          <Input placeholder='请输入公司名称' style={{ width: '80%' }} />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            style={{ fontSize: 18, marginLeft: 8 }}
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        className='common-button-dashed'
                        style={{ width: '80%' }}
                        ghost
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={() => add()}
                      >
                        添加公司
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row>
            <Col span={3}>部门：</Col>
            <Col span={21} className='project-setting-department'>
              <Form.Item name='departmentTree'>
                <ControlTree
                  checkable
                  showLine={{ showLeafIcon: false }}
                  treeDate={departmentTree}
                  onAddNew={addDepartment}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>

      <div className='flex-x m-s-end' style={{ margin: '0 32px' }}>
        <Button onClick={history.goBack}>取消</Button>
        <Button type='primary' onClick={onSave}>
          保存
        </Button>
      </div>
    </div>
  );
};

export default PorjectBaseSetting;
