import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { mangLocal } from '../../utils/common';

import { signIn, signUp, UserInfo } from '../../service/user';

import { Form, Input, Button, message } from 'antd';

const RegisterTab: FC = () => {
  const history = useHistory();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = useStableCallback(async (values: UserInfo) => {
    setLoading(true);
    try {
      await signUp(values);
      const res = await signIn(values);
      mangLocal('userInfo', res.data);
      message.success('注册并登录成功');
      history.push('/');
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  return (
    <Form
      name='normal_register'
      className='login-form'
      form={form}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Form.Item
        name='accountName'
        rules={[{ required: true, message: '请输入邮箱/账户名' }]}
      >
        <Input placeholder='邮箱/账户名' allowClear />
      </Form.Item>
      <Form.Item
        name='phone'
        rules={[
          { required: true, message: '请输入手机号' },
          { pattern: /^1[0-9]{10}$/, message: '请输入正确的手机号' },
        ]}
      >
        <Input placeholder='手机号' allowClear />
      </Form.Item>
      <Form.Item
        name='password'
        rules={[{ required: true, message: '请输入密码' }]}
      >
        <Input.Password placeholder='密码' />
      </Form.Item>
      <Form.Item
        name='confirmPassword'
        dependencies={['password']}
        rules={[
          { required: true, message: '请输入确认密码' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('您输入的两个密码不匹配'));
            },
          }),
        ]}
      >
        <Input.Password placeholder='确认密码' />
      </Form.Item>
      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button'
          loading={loading}
        >
          注册
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegisterTab;
