import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { mangLocal } from '../../utils/common';
import { roleStatusConvert } from '../../utils/definition';

import { createUser } from '../../service/personnel';

import { Form, Input, Button, Select, message } from 'antd';
const { Option } = Select;

const PersonnelAdd: FC = () => {
  const history = useHistory();

  const userInfo = mangLocal('userInfo');

  const [form] = Form.useForm();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const onFinish = useStableCallback(async (values: any) => {
    setSubmitLoading(true);
    try {
      await createUser(values);
      message.success('创建成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  return (
    <div className='page-custom common-details'>
      <Form
        name='personnel-add'
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        form={form}
        onFinish={onFinish}
        autoComplete='off'
      >
        <Form.Item
          label='角色'
          name='role'
          rules={[{ required: true, message: '请选择角色' }]}
        >
          <Select placeholder='请选择'>
            {Object.entries(roleStatusConvert).map(
              ([key, value]) =>
                key < userInfo?.role && (
                  <Option value={key} key={key}>
                    {value}
                  </Option>
                )
            )}
          </Select>
        </Form.Item>
        <Form.Item
          label='账户名'
          name='accountName'
          rules={[{ required: true, message: '请输入账户名' }]}
        >
          <Input placeholder='请输入' maxLength={20} allowClear />
        </Form.Item>
        <Form.Item
          label='密码'
          name='password'
          rules={[{ required: true, message: '请输入密码' }]}
        >
          <Input type='password' placeholder='请输入' allowClear />
        </Form.Item>
        <Form.Item
          label='确认密码'
          name='confirmPassword'
          dependencies={['password']}
          rules={[
            { required: true, message: '请输入确认密码' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('您输入的两个密码不匹配'));
              },
            }),
          ]}
        >
          <Input type='password' placeholder='请输入' allowClear />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type='primary' htmlType='submit' loading={submitLoading}>
            确定
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PersonnelAdd;
