import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { debounce } from 'lodash/fp';
import { useWrapCallback } from '../../hooks/use-stable-callback';
import { intervalMap, weekInfoMap } from '../../utils/definition';
import usePersonnelTable from '../personnel/use-personnel-table';
import BreadcrumbCustom from '../breadcrumb-custom';

import { getRoadDetail } from '../../service/road';
import { getRoadObserver } from '../../service/personnel';
import { download } from '../../service/download';

import { Button, Descriptions } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const RoadDetail: FC = () => {
  const params: any = useParams();
  const history = useHistory();

  const [roadDetail, setRoadDetail] = useState<any>({});

  const search = useMemo(() => ({ roadId: params.id }), [params.id]);

  const { table: personnelTable } = usePersonnelTable({
    search,
    getUserList: getRoadObserver,
  });

  const downloadPointImg = useWrapCallback(() => {
    download('/road/downloadRoadImage', { roadId: params.id }, `${roadDetail.roadName}.JPEG`);
  }, debounce(500));

  useEffect(() => {
    getRoadDetail(params.id)
      .then(setRoadDetail)
      .catch(() => {});
  }, [params.id]);

  const extraButton = useMemo(() => {
    return (
      <div>
        {(roadDetail.status === 1 || roadDetail.status === 4) && (
          <Link to={`/road/edit/${roadDetail.roadId}`}>
            <Button>编辑</Button>
          </Link>
        )}
      </div>
    );
  }, [roadDetail.roadId, roadDetail.status]);

  return (
    <>
      <BreadcrumbCustom
        paths={['路线管理', '路线详情']}
        title={`路线：${roadDetail.roadSequence}（${roadDetail.roadName}）`}
        extra={extraButton}
      />

      <div className='page-custom common-list'>
        <Descriptions title='基本信息' className='common-detail-card'>
          <Descriptions.Item label='项目ID'>
            {`${roadDetail.projectSequence}（${roadDetail.projectName}）`}
          </Descriptions.Item>
          <Descriptions.Item label='路线名称'>{roadDetail.roadName}</Descriptions.Item>
          <Descriptions.Item label='有效时期'>
            {roadDetail.startDate}-{roadDetail.endDate}
          </Descriptions.Item>
          <Descriptions.Item label='有效时间段'>
            {roadDetail.startTime}-{roadDetail.endTime}
          </Descriptions.Item>
          <Descriptions.Item label='星期'>
            {roadDetail.repetition?.map((each: string) => weekInfoMap[each]).join('，')}
          </Descriptions.Item>
          <Descriptions.Item label='间隔时间'>
            {roadDetail.intervalNum}
            {intervalMap[roadDetail.interval]}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions column={1} title='观察信息' className='common-detail-card'>
          <Descriptions.Item label='使用人数'>
            {roadDetail.userNum?.map((each: string) => each).join('，')}
          </Descriptions.Item>
          {roadDetail.roadObserveInfoList?.map((each: any) => (
            <Descriptions.Item label={each.roadObserveName} key={each.roadObserveName}>
              {each.observeItemList.map((item: any) => item.itemName).join('，')}
            </Descriptions.Item>
          ))}
        </Descriptions>

        <Descriptions column={1} title='点位图' className='common-detail-card'>
          <Descriptions.Item contentStyle={{ alignItems: 'flex-end' }}>
            <img
              src={roadDetail.pointImageUrl}
              alt='点位图'
              style={{ width: '280px', height: 'auto' }}
            />
            {roadDetail.pointImageUrl && (
              <Button type='link' onClick={downloadPointImg}>
                <DownloadOutlined /> 下载点位图
              </Button>
            )}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions column={1} title='观察员列表' className='common-detail-card'>
          <Descriptions.Item contentStyle={{ overflow: 'hidden', display: 'inline-block' }}>
            {personnelTable}
          </Descriptions.Item>
        </Descriptions>
      </div>

      <div className='common-buttons flex-x m-s-end minauto'>
        <Button onClick={history.goBack}>返回</Button>
      </div>
    </>
  );
};

export default RoadDetail;
