import { message } from 'antd';
import { mangLocal } from '../utils/common';
import { history } from '../utils/history';

/**
 * 请求成功情况下异常status处理
 */
const resStatusHandle = (res: any) => {
  try {
    switch (res.status) {
      case '027': // 未登录
        sessionStorage.clear();
        mangLocal('userInfo', 'del');
        history.replace('/login');
        message.error('请先登录');
        break;
      default:
        message.error(`${res.msg || '网络异常，请稍后重试'}`);
    }
  } catch (e) {
    message.error('网络异常，请稍后重试');
  }
};

interface Params {
  method?: string;
  format?: 'json' | 'blob';
  headers?: { [key: string]: any };
  query?: { [key: string]: any };
  body?: { [key: string]: any };
}

export const preFetch = async (url: string, option?: Params) => {
  let finalUrl = `${process.env.REACT_APP_BASE_URL}${url}`;
  let finalBody = '';

  if (option?.query) {
    const [main, query] = finalUrl.split('?');
    const urlQuery = new URLSearchParams(query);
    Object.entries(option.query).forEach(([key, value]) => {
      urlQuery.set(key, value);
    });
    finalUrl = `${main}?${urlQuery.toString()}`;
  }

  if (option?.body) {
    if (typeof option.body === 'string') {
      finalBody = option.body;
    } else {
      finalBody = JSON.stringify(option.body);
    }
  }

  const fetchParams: RequestInit = {
    method: option?.method ?? 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json, text/javascript, */*; q=0.01',
      ...option?.headers,
    },
  };

  if (url !== '/signIn' && url !== '/signUp') {
    (fetchParams.headers as any).Authorization = mangLocal('userInfo')?.token;
  }

  if (finalBody) {
    fetchParams.body = finalBody;
  }

  const httpResponse = await fetch(finalUrl, fetchParams);

  if (option?.format === 'blob') {
    return await httpResponse.blob();
  }

  const result = await httpResponse.json();

  if (!httpResponse.ok) {
    if (httpResponse.status === 404) {
      history.replace('/404');
    } else {
      message.error(`${result.message || '网络异常，请稍后重试'}`);
    }
    throw new Error(result.message);
  }

  // 通用成功：'1'
  if (result.status && result.status !== '1') {
    resStatusHandle(result);
    throw new Error(result.msg);
  }

  return result;
};
