import { ComponentClass, FC } from 'react';
import { render } from 'react-dom';

export const createOpenModal = <P,>(Component: FC<P> | ComponentClass<P>, id: string) => {
  return (props: P) => {
    let div = document.getElementById(id);
    if (!div) {
      div = document.createElement('div');
      div.id = id;
      document.body.appendChild(div);
    }

    render(<Component {...(props as any)} />, div);
  };
};

export const createCloseModal = (Component: any, id: string) => {
  return () => {
    let div = document.getElementById(id);
    if (!div) {
      div = document.createElement('div');
      div.id = id;
      document.body.appendChild(div);
    }

    render(<Component visible={false} />, div);
  };
};
