import './predict.scss';

import { v4 as uuid } from 'uuid';
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { closeConfirmModal, openConfirmModal } from './modal/confirm-modal';

import BreadcrumbCustom from '../breadcrumb-custom';
import HelpFeedback from '../user/help-feedback';
import PredictTabpane from './predict-tabpane';

import { getProjectPredictStatus } from '../../service/space';

import { Tabs } from 'antd';

const SpacePredictEdit = () => {
  const params: any = useParams();
  const location: any = useLocation();
  const { step, floorNum, predictDate } = location.state;

  const [activeKey, setActiveKey] = useState<string>('1');
  const [isHelpVisible, setIsHelpVisible] = useState<boolean>(false);
  const [autoSaveId, setAutoSaveId] = useState<string>(uuid());

  const onTabsChange = useStableCallback(async (key: string) => {
    if (key === '2') {
      try {
        const res = await getProjectPredictStatus(params.id);
        if (res.predictAuth === 2) {
          setIsHelpVisible(true);
          return;
        }
      } catch (err) {}
    }
    openConfirmModal({
      visible: true,
      onLeave: () => {
        closeConfirmModal();
        setActiveKey(key);
      },
      onSave: () => {
        setAutoSaveId(uuid());
        closeConfirmModal();
        setActiveKey(key);
      },
      onCancel: closeConfirmModal,
    });
  });

  const onHelpChange = useStableCallback(() => {
    setIsHelpVisible(false);
  });

  return (
    <>
      <BreadcrumbCustom paths={['项目管理', '空间预测']} title={'空间预测'} />

      <div className='space-predict-edit'>
        <Tabs
          className='tabs'
          tabBarGutter={64}
          activeKey={activeKey}
          onChange={onTabsChange}
        >
          <Tabs.TabPane tab='目前' key='1'>
            <PredictTabpane
              projectId={params.id}
              step={step}
              floorNum={floorNum}
              predictDate={predictDate}
              dataType={activeKey}
              autoSaveId={autoSaveId}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='未来' key='2'>
            <PredictTabpane
              projectId={params.id}
              step={step}
              floorNum={floorNum}
              predictDate={predictDate}
              dataType={activeKey}
              autoSaveId={autoSaveId}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>

      {isHelpVisible && (
        <HelpFeedback
          isHelpVisible={isHelpVisible}
          title={'开通空间预测'}
          subtitle={'请联系客服'}
          onChange={onHelpChange}
        />
      )}
    </>
  );
};

export default SpacePredictEdit;
