import { preFetch } from './fetch';

export interface SpaceInfo {
  spaceName: string;
  projectSpaceDetailList: { detailName: string; checked?: boolean }[];
}

export interface DepartmentInfo {
  checked?: boolean;
  detailName?: string;
  departmentName?: string;
  departmentDetailName?: string;
  projectDepartmentDetailList?: DepartmentInfo[];
  projectDepartmentDetailThreeList?: DepartmentInfo[];
}

export interface Project {
  finished?: boolean;

  projectSequence?: string;
  projectId: string;
  projectName: string;
  industry: string;
  city: string[];
  address: string;
  floor: number;
  area: number;
  expireDate?: string;
  notifyDate?: string;

  status?: number;
  projectSpaceInfoList?: SpaceInfo[];

  company?: string[];
  projectDepartmentInfoList?: DepartmentInfo[];

  predictStatus?: number;
}

// 根据用户id获取项目管理列表
export const getUserProjectList = async (
  body: object
): Promise<{ list: Project[]; total: number }> => {
  const res = await preFetch('/project/getUserProjectList', {
    method: 'POST',
    body,
  });

  return res.data;
};

// 项目详情
export const getProjectDetail = async (projectId: string): Promise<Project> => {
  const res = await preFetch('/project/getProjectDetail', {
    method: 'POST',
    body: { projectId },
  });

  return res.data;
};

// 创建项目
export const createProject = async (project: Project) => {
  const res = await preFetch('/project/createProject', {
    method: 'POST',
    body: project,
  });
  return res;
};

// 编辑项目
export const editProject = async (project: Project) => {
  const res = await preFetch('/project/editProject', {
    method: 'POST',
    body: project,
  });

  return res.data;
};

// 删除项目
export const deleteProject = async (projectId: string) => {
  const res = await preFetch('/project/deleteProject', {
    method: 'POST',
    body: { projectId },
  });
  return res;
};

// 项目添加管理员
export const projectAddUser = async (projectId: string, userId: number[]) => {
  const res = await preFetch('/project/projectAddUser', {
    method: 'POST',
    body: { projectId, userId },
  });
  return res;
};

// 项目移除管理员
export const projectRemoveUser = async (
  projectId: string,
  userId: number[]
) => {
  const res = await preFetch('/project/projectRemoveUser', {
    method: 'POST',
    body: { projectId, userId },
  });
  return res;
};
