import React, { FC } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { createCloseModal, createOpenModal } from '../../../utils/modal';

import { Button, ConfigProvider, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onLeave: () => void;
  onSave: () => void;
}

const ConfirmModal: FC<Props> = ({ visible, onLeave, onSave, onCancel }) => {
  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        className='ant-modal-confirm ant-modal-confirm-confirm'
        width={460}
        centered
        closable={false}
        maskClosable={false}
        footer={null}
        visible={visible}
      >
        <div className='ant-modal-confirm-body-wrapper'>
          <div className='ant-modal-confirm-body'>
            <ExclamationCircleOutlined />
            <span className='ant-modal-confirm-title'>注意</span>
            <div className='ant-modal-confirm-content'>
              <p>当前页面未保存，建议保存后再离开页面。</p>
              <p>
                注意核对人数。将【人员数量】【个人工作方式分配人数】【个人空间分配人数】保持一致。
              </p>
            </div>
          </div>
          <div className='ant-modal-confirm-btns'>
            <Button onClick={onCancel}>留在页面</Button>
            <Button danger onClick={onLeave}>
              直接离开
            </Button>
            <Button type='primary' onClick={onSave}>
              保存并离开
            </Button>
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export const openConfirmModal = createOpenModal(ConfirmModal, 'ConfirmModal');

export const closeConfirmModal = createCloseModal(ConfirmModal, 'ConfirmModal');
