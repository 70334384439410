import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { deviceIsMobile, mangLocal } from '../utils/common';

import BreadcrumbCustom from './breadcrumb-custom';

// 错误页
import NoPermission from './error/403';
import NotFound from './error/404';
import NetworkError from './error/500';

// 修改密码
import ModifyPassword from './user/modify-password';

// 项目管理
import ProjectManagement from './project/index';
import ProjectAdd from './project/add';
import ProjectEdit from './project/edit';
import ProjectAddResult from './project/result';
import ProjectDetail from './project/detail';

// 空间预测
import SpacePredictHome from './space/index';
import SpacePredictEdit from './space/predict';
import SpacePredictTotal from './space/total';

// 路线管理
import RoadManagement from './road/index';
import RoadAdd from './road/add';
import RoadEdit from './road/edit';
import RoadAddResult from './road/result';
import RoadDetail from './road/detail';

// 人员管理
import PersonnelManagement from './personnel/index';
import PersonnelAdd from './personnel/add';

// 路线检查
import RoadCheck from './check/index';
import StartCheck from './check/start';

// 观察任务
import RoadObserve from './observe/index';
import StartObserve from './observe/start';

const PageCustom: FC = () => {
  const userInfo = mangLocal('userInfo');
  const redirectPath =
    userInfo?.role === 1
      ? '/observe'
      : deviceIsMobile() && (userInfo?.role === 2 || userInfo?.role === 3)
      ? '/check'
      : '/project';

  return (
    <Switch>
      <Route path='/project/space/total/:id'>
        <SpacePredictTotal />
      </Route>
      <Route path='/project/space/predict/:id'>
        <SpacePredictEdit />
      </Route>
      <Route path='/project/space/:id'>
        <SpacePredictHome />
      </Route>

      <Route path='/project/edit/:id'>
        <ProjectEdit />
      </Route>
      <Route path='/project/add/success/:id'>
        <BreadcrumbCustom
          paths={['项目管理', '创建项目', '结果页']}
          title={false}
        />
        <ProjectAddResult />
      </Route>
      <Route path='/project/add'>
        <BreadcrumbCustom paths={['项目管理', '创建项目']} />
        <ProjectAdd />
      </Route>
      <Route path='/project/detail/:id'>
        <ProjectDetail />
      </Route>
      <Route path='/project'>
        <ProjectManagement />
      </Route>

      <Route path='/road/edit/:id'>
        <RoadEdit />
      </Route>
      <Route path='/road/add/success/:id'>
        <BreadcrumbCustom
          paths={['路线管理', '创建路线', '结果页']}
          title={false}
        />
        <RoadAddResult />
      </Route>
      <Route path='/road/add'>
        <BreadcrumbCustom paths={['路线管理', '创建路线']} />
        <RoadAdd />
      </Route>
      <Route path='/road/detail/:id'>
        <RoadDetail />
      </Route>
      <Route path='/road'>
        <RoadManagement />
      </Route>

      <Route path='/personnel/add'>
        <BreadcrumbCustom paths={['人员管理', '创建账户']} />
        <PersonnelAdd />
      </Route>
      <Route path='/personnel'>
        <PersonnelManagement />
      </Route>

      <Route path='/check/start/:projectId/:roadId'>
        <StartCheck />
      </Route>
      <Route path='/check'>
        <RoadCheck />
      </Route>

      <Route path='/observe/start/:projectId/:roadId'>
        <StartObserve />
      </Route>
      <Route path='/observe'>
        <RoadObserve />
      </Route>

      <Route path='/modifyPassword'>
        <ModifyPassword />
      </Route>

      <Route path='/403'>
        <NoPermission />
      </Route>
      <Route path='/404'>
        <NotFound />
      </Route>
      <Route path='/500'>
        <NetworkError />
      </Route>

      <Redirect exact from='/' to={redirectPath} />
      <Redirect from='*' to='/404' />
    </Switch>
  );
};

export default PageCustom;
