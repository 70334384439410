import React, { useEffect, useMemo, useState } from 'react';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { getPersonnelColumns } from './table-columns';

import { getUserList as getDefaultUserList, UserList } from '../../service/personnel';

import { Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';

interface Params {
  needInit?: boolean;
  tableScroll?: { [key: string]: any };
  search?: { [key: string]: any };
  rowSelection?: TableProps<any>['rowSelection'];
  excluedIds?: string[];
  renderOpeartor?: (record: any) => React.ReactElement;
  getUserList?: (params: object) => Promise<{ list: UserList[]; total: number }>;
}

const usePersonnelTable = ({
  needInit = true,
  tableScroll = { x: '100%' },
  search,
  rowSelection,
  excluedIds,
  renderOpeartor,
  getUserList = getDefaultUserList,
}: Params) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const [listTotal, setListTotal] = useState<number>(0);
  const [pageData, setPageData] = useState<{ [key: string]: number }>({
    page: 1,
    size: 10,
  });

  const getList = useStableCallback(async (page: number = 1, size?: number) => {
    const listPageData = {
      page: page,
      size: size ?? pageData.size,
    };
    setLoading(true);
    setPageData(listPageData);
    try {
      const { list, total } = await getUserList({ ...search, ...listPageData });
      setList(list);
      setListTotal(total);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (needInit) {
      getList(1);
    }
  }, [getList, needInit]);

  const columns: ColumnsType = useMemo(
    () => getPersonnelColumns(renderOpeartor, excluedIds),
    [renderOpeartor, excluedIds],
  );

  return {
    getList,
    pageData,
    table: (
      <Table
        className='list-table'
        size={'middle'}
        scroll={tableScroll}
        rowKey='userId'
        loading={loading}
        columns={columns}
        dataSource={list}
        rowSelection={rowSelection}
        pagination={{
          size: 'small',
          current: pageData.page,
          pageSize: pageData.size,
          total: listTotal,
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: getList,
        }}
      />
    ),
  };
};

export default usePersonnelTable;
