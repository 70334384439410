import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { debounce } from 'lodash/fp';
import { useStableCallback, useWrapCallback } from '../../../hooks/use-stable-callback';
import usePersonnelTable from '../../personnel/use-personnel-table';

import { projectAddUser } from '../../../service/project';
import { getUserListForProject } from '../../../service/personnel';

import { Modal, Input, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
const { Search } = Input;

interface Props {
  projectId: string;
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ProjectManageAdd: FC<Props> = ({ projectId, visible, onCancel, onConfirm }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchName, setSearchName] = useState<string>('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const { table, getList } = usePersonnelTable({
    needInit: false,
    tableScroll: { x: '100%', y: 300 },
    search: { projectId, accountName: searchName },
    rowSelection: {
      selectedRowKeys,
      onChange: onSelectChange,
    },
    getUserList: getUserListForProject,
  });

  const onChange = useStableCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  });

  const onSearch = useWrapCallback((value: string) => {
    setSearchName(value);
    getList(1);
  }, debounce(200));

  const handleOk = useStableCallback(async () => {
    if (!selectedRowKeys.length) return message.warning('请先选择管理人员');
    setConfirmLoading(true);
    try {
      await projectAddUser(projectId, selectedRowKeys);
      message.success('添加成功');
      onConfirm();
    } catch (err) {
    } finally {
      setConfirmLoading(false);
    }
  });

  useEffect(() => {
    if (visible) {
      setSearchName('');
      setSelectedRowKeys([]);
      setTimeout(() => {
        getList(1);
      }, 200);
    }
  }, [getList, visible]);

  return (
    <Modal
      title='添加管理员'
      width={'60%'}
      centered
      maskClosable={false}
      visible={visible}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={onCancel}
    >
      <div className='common-model'>
        <div className='model-header flex-x m-s-between'>
          <div className='header-title'>人员列表</div>
          <div className='header-operate flex-x m-s-end'>
            <Search
              placeholder='搜索账户名'
              value={searchName}
              onChange={onChange}
              onSearch={onSearch}
              style={{ width: 230 }}
            />
            <ReloadOutlined className='refresh' onClick={() => getList(1)} />
          </div>
        </div>

        {table}
      </div>
    </Modal>
  );
};

export default ProjectManageAdd;
