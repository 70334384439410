import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';

import BreadcrumbCustom from '../breadcrumb-custom';
import useRoadListTable from '../road/use-road-table';
import usePersonnelTable from '../personnel/use-personnel-table';

import { getProjectDetail, Project } from '../../service/project';
import { getProjectManager } from '../../service/personnel';
import { download } from '../../service/download';

import { Button, Descriptions } from 'antd';

const ProjectDetail: FC = () => {
  const params: any = useParams();
  const history = useHistory();

  const [projectDetail, setProjectDetail] = useState<Project | null>(null);

  const search = useMemo(() => ({ projectId: params.id }), [params.id]);

  const { table: roadTable } = useRoadListTable({
    search,
    link: false,
    excluedIds: ['projectSequence', 'projectName', 'operation'],
  });

  const { table: personnelTable } = usePersonnelTable({ search, getUserList: getProjectManager });

  const exportProjectInfo = useStableCallback(() => {
    download('/project/downloadProjectInfo', { projectIdList: [params.id] }, '项目信息.xlsx');
  });

  useEffect(() => {
    getProjectDetail(params.id)
      .then(setProjectDetail)
      .catch(() => {});
  }, [params.id]);

  const extraButton = useMemo(() => {
    return (
      <div>
        <Link to={`/project/edit/${projectDetail?.projectId}`} style={{ marginRight: 8 }}>
          <Button>编辑</Button>
        </Link>
        <Button onClick={exportProjectInfo}>导出项目信息</Button>
      </div>
    );
  }, [exportProjectInfo, projectDetail?.projectId]);

  return (
    <>
      <BreadcrumbCustom
        paths={['项目管理', '项目详情']}
        title={`项目：${projectDetail?.projectSequence ?? ''}（${
          projectDetail?.projectName ?? ''
        }）`}
        extra={extraButton}
      />
      <div className='page-custom common-list'>
        <Descriptions title='基本信息' className='common-detail-card'>
          <Descriptions.Item label='项目名称'>{projectDetail?.projectName}</Descriptions.Item>
          <Descriptions.Item label='所属行业'>{projectDetail?.industry}</Descriptions.Item>
          <Descriptions.Item label='项目城市'>{projectDetail?.city}</Descriptions.Item>
          <Descriptions.Item label='项目地点'>{projectDetail?.address}</Descriptions.Item>
          <Descriptions.Item label='楼层数量'>{projectDetail?.floor}</Descriptions.Item>
          <Descriptions.Item label='面积(m²)'>{projectDetail?.area}</Descriptions.Item>
          <Descriptions.Item label='租约到期日'>{projectDetail?.expireDate}</Descriptions.Item>
          <Descriptions.Item label='租约提醒日'>{projectDetail?.notifyDate}</Descriptions.Item>
        </Descriptions>

        {projectDetail?.projectSpaceInfoList && (
          <Descriptions column={1} title='空间信息' className='common-detail-card'>
            {projectDetail?.projectSpaceInfoList?.map((each) => (
              <Descriptions.Item label={each.spaceName} key={each.spaceName}>
                {each.projectSpaceDetailList.map((each) => each.detailName).join('，')}
              </Descriptions.Item>
            ))}
          </Descriptions>
        )}

        <Descriptions column={1} title='部门信息' className='common-detail-card'>
          <Descriptions.Item label='公司名称'>
            {projectDetail?.company?.map((each: string) => each).join('，')}
          </Descriptions.Item>
          {projectDetail?.projectDepartmentInfoList?.map((level1) => (
            <Descriptions.Item label={level1.departmentName} key={level1.departmentName}>
              {level1.projectDepartmentDetailList?.length
                ? level1.projectDepartmentDetailList
                    ?.map((level2) => {
                      let name = level2.departmentDetailName;
                      if (level2.projectDepartmentDetailThreeList?.length) {
                        name += '/';
                        name += level2.projectDepartmentDetailThreeList
                          .map((level3) => level3.detailName)
                          .join('、');
                      }
                      return name;
                    })
                    .join('；')
                : '--'}
            </Descriptions.Item>
          ))}
        </Descriptions>

        <Descriptions column={1} title='路线列表' className='common-detail-card'>
          <Descriptions.Item contentStyle={{ overflow: 'hidden', display: 'inline-block' }}>
            {roadTable}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions column={1} title='管理员列表' className='common-detail-card'>
          <Descriptions.Item contentStyle={{ overflow: 'hidden', display: 'inline-block' }}>
            {personnelTable}
          </Descriptions.Item>
        </Descriptions>
      </div>

      <div className='common-buttons flex-x m-s-end minauto'>
        <Button onClick={history.goBack}>返回</Button>
      </div>
    </>
  );
};

export default ProjectDetail;
