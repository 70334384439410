import './help-feedback.scss';

import { FC } from 'react';

import { Modal } from 'antd';
import { MailOutlined } from '@ant-design/icons';

interface Props {
  isHelpVisible: boolean;
  title?: string;
  subtitle?: string;
  onChange?: () => void;
}

const HelpFeedback: FC<Props> = ({
  isHelpVisible,
  title = '帮助与反馈',
  subtitle = '如您需要帮助或遇到问题请联系我们：',
  onChange,
}) => {
  return (
    <Modal
      className='help'
      title={title}
      centered
      width='380px'
      footer={null}
      maskClosable={false}
      visible={isHelpVisible}
      onCancel={onChange}
    >
      <p>{subtitle}</p>
      <p className='help-email flex-x m-s-start'>
        <MailOutlined className='help-email-icon' />
        <a
          className='help-email-link'
          href='https://mail.163.com/'
          target='_blank'
          rel='noreferrer'
        >
          siyouspace@163.com
        </a>
      </p>
    </Modal>
  );
};

export default HelpFeedback;
