/* eslint-disable eqeqeq */
import checkEmpty from '../../assets/images/check-empty.png';

import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { mangLocal } from '../../utils/common';
import { getObserveList, ObserveList } from '../../service/observe';

import { Col, Row, Card, Button, Spin, Modal } from 'antd';
const { Meta } = Card;

interface Props {
  searchForm?: { [key: string]: any };
}

const RoadCheckTable: FC<Props> = ({ searchForm }) => {
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<ObserveList[]>([]);

  const getList = useStableCallback(async () => {
    setLoading(true);
    try {
      let params = { ...searchForm };
      const { data } = await getObserveList(params);
      setList(data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const goCheck = (item: ObserveList) => {
    const localRoad = mangLocal('checkName');
    if (
      localRoad &&
      list.some((each) => each.roadId == localRoad.roadId) &&
      (localRoad.projectId != item.projectId || localRoad.roadId != item.roadId)
    ) {
      Modal.warning({
        title: `${localRoad.roadName}的检查数据未保存，请前往保存以免数据丢失`,
        centered: true,
      });
      return;
    }

    history.push(`/check/start/${item.projectId}/${item.roadId}`, {
      version: item.suspendVersion,
      roadName: item.roadName,
    });
  };

  useEffect(() => {
    getList();
  }, [getList, searchForm]);

  return (
    <Spin spinning={loading}>
      {list && list.length ? (
        <div style={{ margin: '24px' }}>
          <Row gutter={[24, 24]}>
            {list.map((each) => (
              <Col xs={24} sm={12} md={8} lg={6} xl={6} key={each.roadId}>
                <Card
                  hoverable
                  actions={[
                    <Button type='link' key='check'>
                      {each.suspendFlag ? '继续检查' : '开始检查'}
                    </Button>,
                  ]}
                  onClick={() => goCheck(each)}
                >
                  <Meta title={each.roadName} description={`项目名：${each.projectName}`} />
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <div className='flex-x m-t-b' style={{ color: '#ACACB3', margin: '64px 24px' }}>
          <img src={checkEmpty} style={{ width: '120px', height: '120px' }} alt='' />
          <span>如需检查路线，</span>
          <span>请联系超级管理员开始路线</span>
        </div>
      )}
    </Spin>
  );
};

export default RoadCheckTable;
