import React, { FC, useEffect, useMemo, useState } from 'react';
import { get, sum } from 'lodash/fp';
import { floorSynergyColumn } from '../../../utils/predict';

import { Card, Table, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
  value?: any[];
}

const FloorSynergyTable: FC<Props> = ({ value }) => {
  const [dataSource, setDataSource] = useState<any[]>([]);

  const columns = useMemo(() => {
    return [
      ...[...floorSynergyColumn, '总面积(m²)'].map((item, index) => {
        return {
          title: item,
          dataIndex: 'data.' + index,
          key: 'data.' + index,
          render(text: any, record: any) {
            return get('data.' + index, record);
          },
        };
      }),
      {
        title: (
          <div>
            分配比例
            <Tooltip placement='top' title={'所有楼层人员总数/空间数量'}>
              <InfoCircleOutlined className='title-tips' />
            </Tooltip>
          </div>
        ),
        dataIndex: 'data.5',
        key: 'data.5',
        render(text: any, record: any): any {
          return get('data.5', record);
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (value && value.length) {
      setDataSource(value[0].tableData);
    }
  }, [value]);

  return (
    <Card
      className='space-predict-edit-table'
      type='inner'
      title='楼层协同空间'
    >
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        summary={() => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>小计</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {sum(dataSource.map((row) => row.data[1]))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={2}>
                {sum(dataSource.map((row) => row.data[2]))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} colSpan={2}>
                {sum(dataSource.map((row) => row.data[4])).toFixed(2)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Card>
  );
};

export default FloorSynergyTable;
