import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button, Result } from 'antd';

const RoadAddResult = () => {
  const history = useHistory();
  const params = useParams() as any;

  return (
    <Result
      status='success'
      title='路线创建成功'
      subTitle='完善信息，让观察更有效'
      extra={[
        <Button
          type='primary'
          key='console'
          onClick={() => history.replace(`/road/edit/${params.id}`)}
        >
          完善信息
        </Button>,
        <Button key='back' onClick={history.goBack}>
          返回
        </Button>,
      ]}
    />
  );
};

export default RoadAddResult;
