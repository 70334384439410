import uploadFile from '../../../assets/images/upload-file.png';
import uploadImage from '../../../assets/images/upload-img.png';

import React, { FC, useState, MouseEvent, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash/fp';
import { useStableCallback, useWrapCallback } from '../../../hooks/use-stable-callback';
import { mangLocal } from '../../../utils/common';

import { download } from '../../../service/download';
import { RoadDetail, uploadPointInfo } from '../../../service/road';

import { Button, message, Modal, Upload } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
const { Dragger } = Upload;

interface Props {
  road?: RoadDetail;
}

const RoadPointEdit: FC<Props> = ({ road }) => {
  const params: any = useParams();

  const [pointImage, setPointImage] = useState<string>();
  const [pointList, setPointList] = useState<{ x: number; y: number }[]>([]);
  const [previewImage, setPreviewImage] = useState<string>();
  const [previewVisible, setPreviesVisible] = useState<boolean>(false);
  const [showUploadFileList, setShowUploadFileList] = useState<boolean>(false);

  const imageRef = React.useRef<HTMLImageElement>(null);
  const bodyParams = useMemo(
    () => ({
      roadId: params.id,
      projectId: road?.projectId ?? '',
    }),
    [params.id, road?.projectId],
  );

  const downloadTpl = useWrapCallback(() => {
    download('/road/downloadPointTpl', bodyParams, '点位信息上传模板.xlsx');
  }, debounce(500));

  const addPoint = useWrapCallback((event: MouseEvent<HTMLDivElement>) => {
    let rect = imageRef.current?.getBoundingClientRect();
    if (!rect) return;

    const relativeY = Math.max(0, event.clientY - rect.y);
    const relativeX = Math.max(0, event.clientX - rect.x);

    setPointList([...pointList, { x: relativeX / rect.width, y: relativeY / rect.height }]);
  }, debounce(50));

  const undo = useStableCallback(() => {
    setPointList(pointList.slice(0, -1));
  });

  const preview = useStableCallback(async () => {
    await onSave();
    setPreviesVisible(true);
  });

  const onSave = useStableCallback(async () => {
    const data = await uploadPointInfo(road?.projectId ?? '', params.id, pointList);
    setPreviewImage(data.fileName);
    message.success('保存成功');
  });

  const imageProps = {
    name: 'image',
    maxCount: 1,
    showUploadList: false,
    action: `${process.env.REACT_APP_BASE_URL}/road/uploadImage`,
    headers: {
      Authorization: mangLocal('userInfo')?.token,
    },
    data: bodyParams,
    onChange(info: any) {
      const { status, response } = info.file;
      if (!status) return;
      if (status === 'error') {
        return message.error('上传失败');
      }
      if (status === 'done') {
        if (response && response.status !== '1') {
          return message.error(response.msg);
        }
        message.success('上传成功');
        setPointImage(response.data.fileName);
        setPointList([]);
      }
    },
  };

  const excelProps = {
    name: 'file',
    maxCount: 1,
    showUploadList: showUploadFileList,
    action: `${process.env.REACT_APP_BASE_URL}/road/parsePointTplReq`,
    headers: {
      Authorization: mangLocal('userInfo')?.token,
    },
    data: bodyParams,
    onChange(info: any) {
      setShowUploadFileList(false);
      const { status, response } = info.file;
      if (!status) return;
      if (status === 'error') {
        return message.error('上传失败');
      }
      if (status === 'done') {
        if (response && response.status !== '1') {
          return message.error(response.msg);
        }
        setShowUploadFileList(true);
        message.success('上传成功');
      }
    },
  };

  useEffect(() => {
    setPointImage(road?.originImageUrl);
    setPointList(road?.pointData ?? []);
  }, [road?.originImageUrl, road?.pointData]);

  return (
    <div className='road-point'>
      <div className='commom-setting-title'>编辑点位图</div>
      <div className='commom-setting-content flex-x m-s-between'>
        <span>请先上传图片，点击图片可添加点位</span>
        <div>
          {pointImage && (
            <Upload {...imageProps}>
              <Button style={{ marginRight: 8 }}>更换图片</Button>
            </Upload>
          )}
          <Button disabled={pointList.length === 0} onClick={undo}>
            撤销
          </Button>
          <Button type='primary' ghost onClick={preview}>
            预览
          </Button>
          <Button type='primary' onClick={onSave}>
            保存
          </Button>
        </div>
      </div>
      <div style={{ margin: '0 32px 50px' }}>
        {pointImage ? (
          <div className='road-point-image'>
            <img src={pointImage} ref={imageRef} onClick={addPoint} alt='' />
            {pointList.map((each, index) => (
              <div
                key={index}
                className='road-point-item'
                style={{ left: each.x * 100 + '%', top: each.y * 100 + '%' }}
              >
                {index + 1}
              </div>
            ))}
          </div>
        ) : (
          <div style={{ height: 460 }}>
            <Dragger {...imageProps}>
              <p className='ant-upload-drag-icon'>
                <img src={uploadImage} alt='' />
              </p>
              <p className='ant-upload-drag-text'>点击或将图片拖拽到这里上传</p>
              <p className='ant-upload-drag-hint'>支持扩展名：.jpg .png</p>
            </Dragger>
          </div>
        )}
      </div>

      <div className='commom-setting-title'>点位信息</div>
      <div className='commom-setting-content'>
        <span style={{ marginRight: 16 }}>请上传点位信息</span>
        <Button type='link' style={{ padding: 0 }} onClick={downloadTpl}>
          <DownloadOutlined /> 下载模板
        </Button>
      </div>
      <div style={{ width: 384, height: 200, margin: '0 32px 50px' }}>
        <Dragger {...excelProps}>
          <p className='ant-upload-drag-icon'>
            <img src={uploadFile} alt='' />
          </p>
          <p className='ant-upload-drag-text'>点击或将文件拖拽到这里上传</p>
          <p className='ant-upload-drag-hint'>支持扩展名：.xlsx</p>
        </Dragger>
      </div>

      <Modal
        className='road-point-preview'
        title='预览'
        visible={previewVisible}
        onCancel={() => setPreviesVisible(false)}
        footer={null}
        width={'90%'}
        centered
      >
        <div className='road-point-image'>
          <img src={previewImage} alt='' />
        </div>
      </Modal>
    </div>
  );
};

export default RoadPointEdit;
