import React, { FC, useEffect, useMemo, useState } from 'react';
import { get, sum } from 'lodash/fp';

import { Card, Table, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
  valueA?: any[];
  valueB?: any[];
}

const PersonalSpaceTable: FC<Props> = ({ valueA, valueB }) => {
  const [columnListA, setColumnListA] = useState<any[]>([]);
  const [dataSourceA, setDataSourceA] = useState<any[]>([]);
  const [dataSourceB, setDataSourceB] = useState<any[]>([]);

  const columnsA = useMemo(() => {
    return columnListA.map((item, index) => {
      return {
        title: item,
        dataIndex: 'data.' + index,
        key: 'data.' + index,
        render(text: any, record: any) {
          return get('data.' + index, record);
        },
      };
    });
  }, [columnListA]);

  const columnsB = useMemo(() => {
    return [
      ...[columnListA[0], '人数', '空间数量', '单面积(m²)'].map(
        (item, index) => {
          return {
            title: item,
            dataIndex: 'data.' + index,
            key: 'data.' + index,
            render(text: any, record: any) {
              return get('data.' + index, record);
            },
          };
        }
      ),
      {
        title: (
          <div>
            总面积(m²)
            <Tooltip placement='top' title={'空间数量*单面积'}>
              <InfoCircleOutlined className='title-tips' />
            </Tooltip>
          </div>
        ),
        dataIndex: 'data.4',
        key: 'data.4',
        render(text: any, record: any) {
          return get('data.4', record);
        },
      },
      {
        title: (
          <div>
            共享比例
            <Tooltip placement='top' title={'人数/空间数量'}>
              <InfoCircleOutlined className='title-tips' />
            </Tooltip>
          </div>
        ),
        dataIndex: 'data.5',
        key: 'data.5',
        render(text: any, record: any) {
          return get('data.5', record);
        },
      },
    ];
  }, [columnListA]);

  useEffect(() => {
    if (valueA && valueA.length) {
      setDataSourceA(valueA[0].tableData);
      setColumnListA(valueA[0].columnList);
    }
  }, [valueA]);

  useEffect(() => {
    if (valueB && valueB.length) {
      setDataSourceB(valueB[0].tableData);
    }
  }, [valueB]);

  return (
    <Card
      className='space-predict-edit-table'
      type='inner'
      title='个人空间类型'
    >
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        columns={columnsA}
        dataSource={dataSourceA}
        pagination={false}
        summary={() => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>小计</Table.Summary.Cell>
              {[...columnListA].slice(1).map((each, index) => {
                return (
                  <Table.Summary.Cell index={index + 1}>
                    {sum(dataSourceA.map((row) => row.data[index + 1]))}
                  </Table.Summary.Cell>
                );
              })}
            </Table.Summary.Row>
          );
        }}
      />

      <Table
        className='list-table'
        style={{ marginTop: '32px' }}
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        columns={columnsB}
        dataSource={dataSourceB}
        pagination={false}
        summary={() => {
          const people = sum(dataSourceB.map((row) => row.data[1]));
          const space = sum(dataSourceB.map((row) => row.data[2]));
          const area = sum(dataSourceB.map((row) => row.data[4]));
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>小计</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>{people}</Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={2}>
                {space}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                {area.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                {(!space ? 0 : people / space).toFixed(2)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Card>
  );
};

export default PersonalSpaceTable;
