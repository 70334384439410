import { preFetch } from './fetch';

export interface UserInfo {
  accountName: string;
  password: string;
  confirmPassword?: string;
}

// 登录
export const signIn = async (userInfo: UserInfo) => {
  const res = await preFetch('/signIn', {
    method: 'POST',
    body: userInfo,
  });
  return res;
};

// 注册
export const signUp = async (userInfo: UserInfo) => {
  const res = await preFetch('/signUp', {
    method: 'POST',
    body: userInfo,
  });
  return res;
};

// 修改密码
export const modifyPassword = async (userInfo: object) => {
  const res = await preFetch('/user/modifyPassword', {
    method: 'POST',
    body: userInfo,
  });
  return res;
};
