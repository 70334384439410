import './index.scss';

import React, { FC, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';

import BreadcrumbCustom from '../../breadcrumb-custom';
import RoadBase from './base';
import RoadPointEdit from './point-edit';
import RoadManageEdit from './manage';

import { getRoadDetail, RoadDetail } from '../../../service/road';

import { Menu, message } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

const activeMenuRegex = /^\/road\/edit\/\w+\/(\w+)$/;

const RoadEdit: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const params: any = useParams();
  const [selectMenu, setSelectMenu] = useState<string[]>([]);
  const [hasBaseChanged, setHasBaseChanged] = useState(false);
  const [road, setRoad] = useState<RoadDetail>();

  const onSelect = useStableCallback(async (info: any) => {
    // 如果基础新有变动，切换前提示报错
    if (selectMenu[0] === 'base' && hasBaseChanged) {
      message.error('基础信息未保存，请先保存后再切换');
      return;
    }

    // 只有基础信息完善才可进入编辑点位页
    if (!road?.roadFinished && info.key === 'point') {
      message.error('请先完善路线基本信息');
      return;
    }

    if (!road?.projectFinished && info.key === 'point') {
      message.error('请先完善项目基本信息');
      return;
    }

    if (info.key === 'point') {
      getRoadDetail(params.id).then(setRoad);
    }

    setSelectMenu([info.key]);
    history.replace(`/road/edit/${params.id}/${info.key}`);
  });

  const onChange = useStableCallback(() => {
    setHasBaseChanged(true);
  });

  const onSave = useStableCallback(async () => {
    setHasBaseChanged(false);

    // 只有基础信息完善才可进入编辑点位页
    const road = await getRoadDetail(params.id);
    setRoad(road);
    if (road.roadFinished && road.projectFinished) {
      setSelectMenu(['point']);
      history.replace(`/road/edit/${(params as any).id}/point`);
    }
  });

  useEffect(() => {
    const res = activeMenuRegex.exec(location.pathname);
    setSelectMenu([res?.[1] ?? '']);
  }, [location.pathname]);

  useEffect(() => {
    getRoadDetail(params.id).then((road) => {
      setRoad(road);
      if (road?.status !== 1) {
        setSelectMenu(['manage']);
        history.replace(`/road/edit/${params.id}/manage`);
      }
    });
  }, [history, params.id]);

  return (
    <>
      <BreadcrumbCustom
        paths={['路线管理', '路线设置']}
        title={`路线：${road?.roadSequence}（${road?.roadName}）`}
      />

      <div className='page-custom road-edit flex-x a-s-stretch'>
        <Menu
          className='minauto'
          style={{ width: 200, color: '#7B7B80' }}
          selectedKeys={selectMenu}
          onClick={onSelect}
        >
          <Menu.Item key='base' disabled={road?.status !== 1 && road?.status !== 4}>
            <div className='flex-x m-s-between'>
              <span>路线信息</span>
              {road?.roadFinished && <CheckCircleFilled style={{ color: '#12C99B' }} />}
            </div>
          </Menu.Item>
          <Menu.Item key='point' disabled={road?.status !== 1 && road?.status !== 4}>编辑点位</Menu.Item>
          <Menu.Item key='manage'>路线观察员</Menu.Item>
        </Menu>
        <div className='road-edit-step maxauto'>
          <Switch>
            <Route path='/road/edit/:id/base'>
              <RoadBase road={road} onChange={onChange} onSave={onSave} />
            </Route>
            <Route path='/road/edit/:id/point'>
              <RoadPointEdit road={road} />
            </Route>
            <Route path='/road/edit/:id/manage'>
              <RoadManageEdit />
            </Route>
            <Redirect to='/road/edit/:id/base' />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default RoadEdit;
