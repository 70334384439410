import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash/fp';
import { useStableCallback, useWrapCallback } from '../../hooks/use-stable-callback';
import { getPersonnelColumns } from './table-columns';

import { getManager, bindingManager } from '../../service/personnel';

import { Modal, Input, message, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
const { Search } = Input;

interface Props {
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const PersonnelAdd: FC<Props> = ({ visible, onCancel, onConfirm }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchName, setSearchName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const getList = useStableCallback(async () => {
    setLoading(true);
    try {
      const list = await getManager(searchName);
      setList(list);
    } catch (err) {
      setList([]);
    } finally {
      setLoading(false);
    }
  });

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const onChange = useStableCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  });

  const onSearch = useWrapCallback((value: string) => {
    setSearchName(value);
    getList();
  }, debounce(200));

  const handleOk = useStableCallback(async () => {
    if (!selectedRowKeys.length) return message.warning('请先选择人员');
    setConfirmLoading(true);
    try {
      await bindingManager(selectedRowKeys.toString());
      message.success('添加成功');
      onConfirm();
    } catch (err) {
    } finally {
      setConfirmLoading(false);
    }
  });

  useEffect(() => {
    if (visible) {
      setSearchName('');
      setSelectedRowKeys([]);
      setList([]);
    }
  }, [visible]);

  const columns: ColumnsType = useMemo(() => getPersonnelColumns(), []);

  return (
    <Modal
      title='添加人员'
      width={'60%'}
      centered
      maskClosable={false}
      visible={visible}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={onCancel}
    >
      <div className='common-model'>
        <div className='model-header flex-x m-s-between'>
          <div className='header-title'>人员列表</div>
          <div className='header-operate flex-x m-s-end'>
            <Search
              placeholder='搜索账户名'
              value={searchName}
              onChange={onChange}
              onSearch={onSearch}
              style={{ width: 230 }}
            />
            <ReloadOutlined className='refresh' onClick={() => getList()} />
          </div>
        </div>

        <Table
          className='list-table'
          size={'middle'}
          scroll={{ x: '100%', y: 300 }}
          rowKey='userId'
          loading={loading}
          columns={columns}
          dataSource={list}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          pagination={false}
        />
      </div>
    </Modal>
  );
};

export default PersonnelAdd;
