import React, { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { mangLocal } from '../../../utils/common';
import ProjectManageAdd from './manage-add';
import usePersonnelTable from '../../personnel/use-personnel-table';

import { projectRemoveUser } from '../../../service/project';
import { getProjectManager } from '../../../service/personnel';

import { Button, Modal, message } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const PorjectManageEdit: FC = () => {
  const params = useParams() as any;

  const userInfo = mangLocal('userInfo');

  const [modalVisible, setModalVisible] = useState(false);

  const renderOpeartor = useStableCallback((record: any) => {
    return (
      <Button type='link' disabled={record.role >= userInfo?.role} onClick={() => removeManage(record)}>
        移除
      </Button>
    );
  });

  const search = useMemo(() => ({ projectId: params.id }), [params.id]);

  const { table, getList } = usePersonnelTable({
    search,
    renderOpeartor: userInfo?.role >= 3 ? renderOpeartor : undefined,
    getUserList: getProjectManager,
  });

  const removeManage = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定移除管理员【${record.accountName}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          await projectRemoveUser(params.id, [record.userId]);
          message.success('移除成功');
          getList(1);
        } catch (err) {}
      },
    });
  });

  const addManagers = useStableCallback(() => {
    setModalVisible(true);
  });

  const onConfirm = useStableCallback(() => {
    setModalVisible(false);
    getList(1);
  });

  const onCancel = useStableCallback(() => {
    setModalVisible(false);
  });

  return (
    <div className='project-setting-detail project-road-setting common-list'>
      <div className='commom-setting-title'>管理员列表</div>
      
      <div className='commom-setting-content'>{table}</div>

      {userInfo?.role >= 3 && (
        <div className='commom-setting-content' style={{ marginBottom: 8 }}>
          <Button ghost type='primary' block className='common-button-dashed' onClick={addManagers}>
            <PlusOutlined />
            添加管理员
          </Button>
        </div>
      )}

      <ProjectManageAdd
        projectId={params.id}
        visible={modalVisible}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </div>
  );
};

export default PorjectManageEdit;
