import React, { FC, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { sum } from 'lodash/fp';
import { EditableRow } from '../../common/editable/editable-row';
import {
  DataType,
  EditableCell,
  InputCell,
} from '../../common/editable/editable-cell';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import {
  floorFeaturesColumn,
  getFloorFeaturesList,
} from '../../../utils/predict';

import { Button, Card, Table, Tooltip } from 'antd';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

interface Props {
  value?: any;
  onChange?: (newValue: any) => void;
}

const PredictTemplate4: FC<Props> = ({ value, onChange }) => {
  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    []
  );

  const handleValueChange = useStableCallback((newValue: DataType[]) => {
    setDataSource(newValue);
    onChange?.({ tableData: newValue });
  });

  const handleDelete = useStableCallback((id: React.Key) => {
    handleValueChange(dataSource.filter((item) => item.id !== id));
  });

  const handleAdd = useStableCallback(() => {
    const newData: DataType = {
      id: uuid(),
      data: [
        '空间名称',
        '根据部门需求',
        ...Array(floorFeaturesColumn.length - 2).fill(0),
      ],
    };
    handleValueChange([...dataSource, newData]);
  });

  const handleChange = useStableCallback(
    (row: DataType, changedKey: keyof DataType) => {
      handleValueChange(
        dataSource.map((each) => {
          if (row.id === each.id) {
            return {
              ...each,
              ...row,
            };
          }
          return each;
        })
      );
    }
  );

  const columns = React.useMemo(() => {
    let tables = floorFeaturesColumn.map((item, index) => {
      return {
        title: item,
        dataIndex: 'data|' + index,
        key: 'data|' + index,
        width: 200,
        render: (text: string, record: any) => {
          return record.data[index];
        },
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'input',
          title: item,
          dataIndex: 'data|' + index,
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell
                name={name}
                onChange={onChange}
                isInputNumber={![0, 1].includes(index)}
                numberPrecision={item === '单面积(m²)' ? 2 : 0}
                rules={[{ required: true, message: '不可为空' }]}
              >
                {originCell}
              </InputCell>
            );
          },
        }),
      };
    });

    return [
      ...tables,
      {
        title: (
          <div>
            总面积(m²)
            <Tooltip placement='top' title={'空间数量*单面积'}>
              <InfoCircleOutlined className='title-tips' />
            </Tooltip>
          </div>
        ),
        dataIndex: 'totalArea',
        key: 'totalArea',
        width: 200,
        render: (text: string, record: any) => {
          return (record.data[2] * record.data[4]).toFixed(2);
        },
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        width: 200,
        render: (text: any, record: any) => (
          <>
            <Button type='link' onClick={() => handleDelete(record?.id)}>
              删除
            </Button>
          </>
        ),
      },
    ];
  }, [handleChange, handleDelete]);

  useEffect(() => {
    if (value) {
      setDataSource(value?.tableData);
    } else {
      setDataSource(getFloorFeaturesList);
      onChange?.({ tableData: getFloorFeaturesList() });
    }
  }, [onChange, value]);

  return (
    <Card
      id='step4'
      className='space-predict-edit-table'
      type='inner'
      title='楼层功能空间'
    >
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        components={components}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        summary={() => {
          const space = sum(dataSource.map((row) => row.data[2]));
          const site = sum(dataSource.map((row) => row.data[3]));
          const area = sum(dataSource.map((row) => row.data[2] * row.data[4]));
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                小计
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>{space}</Table.Summary.Cell>
              <Table.Summary.Cell index={3} colSpan={2}>
                {site}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} colSpan={2}>
                {area.toFixed(2)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
      <Button
        block
        type='dashed'
        icon={<PlusOutlined />}
        style={{ margin: '16px 0 0' }}
        onClick={handleAdd}
      >
        新增空间类型
      </Button>
    </Card>
  );
};

export default PredictTemplate4;
