import { mangLocal } from '../utils/common';
import { preFetch } from './fetch';
import { DepartmentInfo, SpaceInfo } from './project';

export interface CheckInfo {
  projectId?: string;
  roadId?: string;
  suspendIndex: number;
  version: string;
  companyList: string[];
  projectOptionList: {
    roadPointId: string;
    pointNum: number;
    pointIndex: number;
    company: string;
    remark: string;
    clientTime?: number;
    projectSpaceInfoList: SpaceInfo[];
    projectDepartmentInfoList: DepartmentInfo[];
  }[];
}

export const getCheckInfo = async (projectId: string, roadId: string, version?: string) => {
  const localData = mangLocal(`checkInfo_${projectId}_${roadId}`);

  if (localData) return localData;

  const res = await preFetch('/observe/getProjectOption', {
    method: 'POST',
    body: { projectId, roadId, version },
  });

  return res.data;
};

export const saveCheckInfo = async (checkInfo: CheckInfo) => {
  const postInfo: any = {
    ...checkInfo,
    suspendIndex: checkInfo.projectOptionList[checkInfo.suspendIndex].pointIndex,
    saveProjectPointList: checkInfo.projectOptionList,
  };
  delete postInfo.projectOptionList;
  delete postInfo.companyList;

  const res = await preFetch('/observe/saveProjectPoint', {
    method: 'POST',
    body: postInfo,
  });

  return res.data;
};
