import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { mangLocal } from '../../utils/common';
import { personnelStatusConvert, roleStatusConvert } from '../../utils/definition';

import { getProjectColumns } from '../project/table-columns';
import { getRoadColumns } from '../road/table-columns';
import AllocationRoad from './allocation-road';
import AllocationProject from './allocation-project';
import PersonnelAdd from './personnel-add';

import {
  getUserList,
  modifyUserRole,
  modifyAccountName,
  editUserStatus,
  resetPassword,
  deleteUser,
  userRemoveRoad,
  userRemoveProject,
} from '../../service/personnel';
import { getUserProjectList } from '../../service/project';
import { getUserRoadList } from '../../service/road';

import { Button, Table, Modal, message, Select, Dropdown, Menu, Typography } from 'antd';
import {
  ReloadOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  EllipsisOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
const { Option } = Select;
const { Paragraph } = Typography;

interface Props {
  searchForm?: { [key: string]: any };
}

const PersonnelListTable: FC<Props> = ({ searchForm }) => {
  const history = useHistory();

  const userInfo = mangLocal('userInfo');

  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const [listTotal, setListTotal] = useState<number>(0);
  const [pageData, setPageData] = useState<{ [key: string]: number }>({
    page: 1,
    size: 10,
  });

  const [expandedKeys, setExpandedKeys] = useState<string[] | undefined>(undefined);
  const [roadModalVisible, setRoadModalVisible] = useState<boolean>(false);
  const [roadModalInfo, setRoadModalInfo] = useState<{ [key: string]: any }>({});
  const [projectModalVisible, setProjectModalVisible] = useState<boolean>(false);
  const [projectModalInfo, setProjectModalInfo] = useState<{ [key: string]: any }>({});
  const [personnelModalVisible, setPersonnelModalVisible] = useState<boolean>(false);

  const getList = useStableCallback(
    async (page: number, size?: number, cleanExpandedKeys: boolean = true) => {
      const listPageData = {
        page: page,
        size: size ?? pageData.size,
      };
      setLoading(true);
      setPageData(listPageData);
      try {
        const params = { ...searchForm, ...listPageData };
        const { list, total } = await getUserList(params);
        setList(list);
        setListTotal(total);
      } catch (err) {
      } finally {
        setLoading(false);
      }
      if (cleanExpandedKeys) {
        setExpandedKeys([]);
      }
    },
  );

  const handlerExpand = useStableCallback(async (expanded, record) => {
    if (!expanded) return;
    setLoading(true);
    try {
      if (record.role === 1) {
        const res = await getUserRoadList({ userId: record.userId });
        record.children = res.list;
      } else {
        const res = await getUserProjectList({ userId: record.userId });
        record.children = res.list;
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const handlerExpandedRows = useStableCallback((expandedRows) => {
    setExpandedKeys(expandedRows);
  });

  const modifyNameRow = useStableCallback(async (record, value) => {
    try {
      await modifyAccountName(record.userId, value);
      message.success('修改成功');
      getList(pageData.page);
    } catch (err) {}
  });

  const modifyRoleRow = useStableCallback(async (record, value) => {
    try {
      await modifyUserRole(record.userId, value);
      message.success('修改成功');
      getList(pageData.page);
    } catch (err) {}
  });

  const changeStatusRow = useStableCallback((record: any) => {
    const titleStatus = record.status === 1 ? '禁用' : '启用';
    const contentStatus =
      record.status === 1
        ? '禁用后该用户将无法登录系统，之后可再次启用。'
        : '启用后该用户可正常登录系统，之后可再次禁用。';
    Modal.confirm({
      title: `确定${titleStatus}账户【${record.accountName}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: contentStatus,
      maskClosable: false,
      onOk: async () => {
        try {
          await editUserStatus(record.userId, record.status === 1 ? 2 : 1);
          message.success(`${titleStatus}成功`);
          getList(pageData.page);
        } catch (err) {}
      },
    });
  });

  const dropdownClick = useStableCallback((record, value) => {
    if (value.key === 'reset') {
      resetPasswordRow(record);
    } else {
      deleteUserRow(record);
    }
  });

  const resetPasswordRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定重置账户【${record.accountName}】的密码吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          await resetPassword(record.userId);
          message.success('重置成功');
          getList(pageData.page);
        } catch (err) {}
      },
    });
  });

  const deleteUserRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定删除账户【${record.accountName}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          await deleteUser(record.userId);
          message.success('删除成功');
          getList(1);
        } catch (err) {}
      },
    });
  });

  const personnelConfirm = useStableCallback(async () => {
    setPersonnelModalVisible(false);
    setLoading(true);
    try {
      await getList(1);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  // 观察员分配路线，其余分配项目
  const allocationRow = useStableCallback((record: any) => {
    if (record.role === 1) {
      setRoadModalVisible(true);
      setRoadModalInfo(record);
    } else {
      setProjectModalVisible(true);
      setProjectModalInfo(record);
    }
  });

  const roadConfirm = useStableCallback(async () => {
    setRoadModalVisible(false);
    setLoading(true);
    try {
      const res = await getUserRoadList({ userId: roadModalInfo.userId });
      roadModalInfo.children = res.list;
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });
  const roadCancel = useStableCallback(() => {
    setRoadModalVisible(false);
  });

  const projectConfirm = useStableCallback(async () => {
    setProjectModalVisible(false);
    setLoading(true);
    try {
      const res = await getUserProjectList({ userId: projectModalInfo.userId });
      projectModalInfo.children = res.list;
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });
  const projectCancel = useStableCallback(() => {
    setProjectModalVisible(false);
  });

  const removeRoadRow = useStableCallback((record: any, innerRecord: any) => {
    Modal.confirm({
      title: `确定移除路线【${innerRecord.roadName}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        setLoading(true);
        try {
          await userRemoveRoad(record.userId, [innerRecord.roadId]);
          const res = await getUserRoadList({ userId: record.userId });
          record.children = res.list;
          message.success('移除成功');
        } catch (err) {
        } finally {
          setLoading(false);
        }
      },
    });
  });

  const removeProjectRow = useStableCallback((record: any, innerRecord: any) => {
    Modal.confirm({
      title: `确定移除项目【${innerRecord.projectName}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        setLoading(true);
        try {
          await userRemoveProject(record.userId, [innerRecord.projectId]);
          const res = await getUserProjectList({ userId: record.userId });
          record.children = res.list;
          message.success('移除成功');
        } catch (err) {
        } finally {
          setLoading(false);
        }
      },
    });
  });

  useEffect(() => {
    getList(1, 10, false);
  }, [getList, searchForm]);

  const expandedRoadRowRender = (record: any) => {
    const roadColumns: any = getRoadColumns(
      (innerRecord) => (
        <Button type='link' onClick={() => removeRoadRow(record, innerRecord)}>
          移除
        </Button>
      ),
      [
        'projectSequence',
        'expectSum',
        'actualSum',
        'accountNames',
        'updater',
        'startTime',
        'endTime',
      ],
      true,
      false,
    );

    return (
      <Table
        rowKey='roadId'
        columns={roadColumns}
        dataSource={record.children}
        pagination={false}
      />
    );
  };

  const expandedProjectRowRender = (record: any) => {
    const projectColumns: any = getProjectColumns(
      (innerRecord) => (
        <Button type='link' onClick={() => removeProjectRow(record, innerRecord)}>
          移除
        </Button>
      ),
      ['roadNum', 'accountNameList'],
    );

    return (
      <Table
        rowKey='projectId'
        columns={projectColumns}
        dataSource={record.children}
        pagination={false}
      />
    );
  };

  const columns: ColumnsType = [
    {
      title: '账户ID',
      dataIndex: 'accountId',
      key: 'accountId',
      width: 120,
    },
    {
      title: '账户名',
      dataIndex: 'accountName',
      key: 'accountName',
      width: 140,
      render: (text: any, record: any) => (
        <Paragraph
          editable={{
            icon: <EditOutlined style={{ color: 'rgba(74, 74, 77, 0.45)' }} />,
            onChange: (value) => modifyNameRow(record, value),
          }}
        >
          {text}
        </Paragraph>
      ),
    },
    {
      title: '角色',
      dataIndex: 'role',
      key: 'role',
      width: 120,
      render: (text: any, record: any) => (
        <div className='table-select'>
          {userInfo?.role === 1 || userInfo?.role === 2 ? (
            roleStatusConvert[text]
          ) : (
            <Select
              defaultValue={text}
              style={{ width: '100%' }}
              bordered={false}
              value={record.role}
              onChange={(value) => modifyRoleRow(record, value)}
            >
              {userInfo?.role === 4 && (
                <Option value={3} label='超级管理员'>
                  <p className='dropdown-title'>超级管理员</p>
                  <p className='dropdown-text'>拥有管理项目、管理员和观察员的权限</p>
                </Option>
              )}
              <Option value={2} label='管理员'>
                <p className='dropdown-title'>管理员</p>
                <p className='dropdown-text'>拥有管理项目和观察员的权限</p>
              </Option>
              <Option value={1} label='观察员'>
                <p className='dropdown-title'>观察员</p>
                <p className='dropdown-text'>仅拥有观察的权限</p>
              </Option>
            </Select>
          )}
        </div>
      ),
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status: any) => (
        <div className='flex-x m-s-start table-status'>
          <div className={`circle ${personnelStatusConvert[status]?.[1]}`}></div>
          <div>{personnelStatusConvert[status]?.[0]}</div>
        </div>
      ),
    },
    {
      title: '更新时间',
      key: 'updateTime',
      dataIndex: 'updateTime',
      width: 180,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: 160,
      fixed: 'right',
      render: (text: any, record: any) => {
        const menu = (
          <Menu onClick={(value) => dropdownClick(record, value)}>
            <Menu.Item key='reset'>重置密码</Menu.Item>
            <Menu.Item key='delete' danger>
              删除
            </Menu.Item>
          </Menu>
        );

        return (
          <>
            <Button type='link' onClick={() => changeStatusRow(record)}>
              {record.status === 1 ? '禁用' : '启用'}
            </Button>
            <Button type='link' onClick={() => allocationRow(record)}>
              {record.role === 1 ? '分配路线' : '分配项目'}
            </Button>
            {userInfo?.role >= 3 && (
              <Dropdown overlay={menu}>
                <Button type='link' icon={<EllipsisOutlined />}></Button>
              </Dropdown>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>人员列表</div>
        <div className='header-operate'>
          {userInfo?.role === 3 && (
            <Button onClick={() => setPersonnelModalVisible(true)}>添加人员</Button>
          )}
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => history.push('/personnel/add')}
          >
            新增
          </Button>
          <ReloadOutlined className='refresh' onClick={() => getList(1)} />
        </div>
      </div>

      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='userId'
        loading={loading}
        columns={columns}
        dataSource={list}
        expandable={{
          childrenColumnName: 'childrenColumnName',
          expandedRowKeys: expandedKeys,
          expandedRowRender: (record: any) => {
            return record.role === 1
              ? expandedRoadRowRender(record)
              : expandedProjectRowRender(record);
          },
          onExpand: (expanded, record) => handlerExpand(expanded, record),
          onExpandedRowsChange: (expandedRows) => handlerExpandedRows(expandedRows),
        }}
        pagination={{
          size: 'small',
          current: pageData.page,
          pageSize: pageData.size,
          total: listTotal,
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: getList,
        }}
      />

      <AllocationRoad
        userId={roadModalInfo.userId}
        visible={roadModalVisible}
        onConfirm={roadConfirm}
        onCancel={roadCancel}
      />
      <AllocationProject
        userId={projectModalInfo.userId}
        visible={projectModalVisible}
        onConfirm={projectConfirm}
        onCancel={projectCancel}
      />
      <PersonnelAdd
        visible={personnelModalVisible}
        onConfirm={personnelConfirm}
        onCancel={() => setPersonnelModalVisible(false)}
      />
    </div>
  );
};

export default PersonnelListTable;
