import React, { FC, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { get } from 'lodash/fp';

import { Card, Table, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
  value?: any[];
}

const RentalAreaTable: FC<Props> = ({ value }) => {
  const [dataSource, setDataSource] = useState<any[]>([]);

  const columns = useMemo(() => {
    return [
      {
        title: '租用面积分配',
        dataIndex: 'data.0',
        key: 'data.0',
        render(text: any, record: any) {
          return get('data.0', record);
        },
      },
      {
        title: (
          <div>
            面积(m²)
            <Tooltip
              placement='top'
              overlayInnerStyle={{ width: '310px' }}
              title={
                <>
                  <p>空间使用净面积：所有空间类型的面积总和</p>
                  <p>走道面积：请测量走道包括次走道的总面积填写</p>
                  <p>净面积：空间使用净面积与走道的面积总和</p>
                  <p>租赁面积：请根据租赁合同填写</p>
                </>
              }
            >
              <InfoCircleOutlined className='title-tips' />
            </Tooltip>
          </div>
        ),
        dataIndex: 'data.1',
        key: 'data.1',
        render(text: any, record: any) {
          return get('data.1', record);
        },
      },
      {
        title: '分配说明',
        dataIndex: 'data.2',
        key: 'data.2',
        render: (text: string, record: any, index: number) => {
          if (index === 1) {
            return (
              <div>
                {get('data.2', record) || '-'}%
                <Tooltip
                  placement='top'
                  title={'走道面积/(空间使用净面积+走道面积)'}
                >
                  <InfoCircleOutlined className='title-tips' />
                </Tooltip>
              </div>
            );
          }
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (value && value.length) {
      let newValue = [
        ['空间使用净面积', value[0].usedArea, ''],
        ['走道面积', value[0].aisleArea, value[0].aislePercent],
        ['净面积', value[0].area, ''],
        ['租赁面积', value[0].leaseArea, ''],
      ];
      const data = newValue.map((each: any[]) => {
        return {
          id: uuid(),
          data: each,
        };
      });
      setDataSource(data);
    }
  }, [value]);

  return (
    <Card
      className='space-predict-edit-table'
      type='inner'
      title='租用面积分配'
    >
      <div className='rental-area flex-x m-s-start a-s-center'>
        <div>
          工位数量：{value?.[0]?.workNum || '-'}
          <Tooltip placement='top' title={'所有楼层的工位总数'}>
            <InfoCircleOutlined className='title-tips' />
          </Tooltip>
        </div>
        <div>
          座位数量：{value?.[0]?.seatNum || '-'}
          <Tooltip placement='top' title={'所有楼层的座位总数'}>
            <InfoCircleOutlined className='title-tips' />
          </Tooltip>
        </div>
      </div>
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </Card>
  );
};

export default RentalAreaTable;
