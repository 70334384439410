import React, { FC, useEffect, useMemo, useState } from 'react';
import { get, sum } from 'lodash/fp';

import { Card, Table } from 'antd';

interface Props {
  value?: any[];
}

const StaffTable: FC<Props> = ({ value }) => {
  const [columnList, setColumnList] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);

  const columns = useMemo(() => {
    return [
      ...columnList.map((item, index) => {
        return {
          title: item,
          dataIndex: 'data.' + index,
          key: 'data.' + index,
          render(text: any, record: any) {
            return get('data.' + index, record);
          },
        };
      }),
      {
        title: '现有人员总数',
        dataIndex: 'total',
        key: 'total',
        width: 240,
        render: (text: string, record: any) => {
          return sum([...record.data].splice(1));
        },
      },
    ];
  }, [columnList]);

  useEffect(() => {
    if (value && value.length) {
      setDataSource(value[0].tableData);
      setColumnList(value[0].columnList);
    }
  }, [value]);

  return (
    <Card className='space-predict-edit-table' type='inner' title='人员情况'>
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        summary={() => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>小计</Table.Summary.Cell>
              {[...columnList].slice(1).map((each, index) => {
                return (
                  <Table.Summary.Cell index={index + 1}>
                    {sum(dataSource.map((row) => row.data[index + 1]))}
                  </Table.Summary.Cell>
                );
              })}
              <Table.Summary.Cell index={columnList.length} colSpan={2}>
                {sum(dataSource.map((row) => sum([...row.data].slice(1))))}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Card>
  );
};

export default StaffTable;
