/**
 * 存储sessionStorage
 * @param {String} name 需要存储的内容命名
 * @param content 需要存储的内容，直接取的时候不需要传此参
 */
export const mangSession = (name: string, content?: any) => {
  return manageSave(name, content, false);
};

/**
 * 存储localStorage
 * @param {String} name 需要存储的内容命名
 * @param content 需要存储的内容，直接取的时候不需要传此参
 */
export const mangLocal = (name: string, content?: any) => {
  return manageSave(name, content);
};

export const manageSave = (name: string, content?: any, type = true) => {
  let storage = type ? localStorage : sessionStorage;
  let isLocal = window.localStorage && window.localStorage instanceof Storage;
  if (typeof name === 'string') {
    if (!content && typeof content != 'boolean') {
      let data = isLocal ? storage.getItem(name) : getCookie(name);
      try {
        data = JSON.parse(data);
      } catch (e) {
        // continue regardless of error
      }
      return data;
    } else if (
      content &&
      (Object.prototype.toString.call(content) === '[object Object]' ||
        Object.prototype.toString.call(content) === '[object Array]')
    ) {
      content = JSON.stringify(content);
      if (isLocal) {
        storage.setItem(name, content);
      } else {
        setCookie(name, content);
      }
    } else if (
      content &&
      Object.prototype.toString.call(content) === '[object Boolean]'
    ) {
      content = JSON.stringify(content);
      if (isLocal) {
        storage.setItem(name, content);
      } else {
        setCookie(name, content);
      }
    } else if (typeof content === 'string' && content === 'del') {
      storage.removeItem(name);
    } else {
      if (isLocal) {
        storage.setItem(name, content);
      } else {
        setCookie(name, content);
      }
    }
  } else if (Object.prototype.toString.call(name) === '[object Object]') {
    if (isLocal) {
      storage.setItem(name, content);
    } else {
      setCookie(name, content);
    }
  }
  return true;
};

export const getCookie = (name: string) => {
  var arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)'),
    res,
    r;
  if ((arr = document.cookie.match(reg))) {
    r = decodeURIComponent(arr[2]);
    try {
      res = JSON.parse(decodeURIComponent(arr[2]));
    } catch (e) {
      // continue regardless of error
    }
    return res ? res : r;
  }
  return r;
};

export const setCookie = (name: string, content: any) => {
  try {
    var days = 1;
    var exp = new Date();
    exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
    if (Object.prototype.toString.call(content) === '[object Object]') {
      content = JSON.stringify(content);
    }
    // 写入Cookie，toUTCString将时间转换成字符串。
    document.cookie = name + '=' + encodeURIComponent(content) + ';expires=' + exp.toUTCString();
  } catch (e) {
    // continue regardless of error
  }
};

/**
 * 列表页筛选查询数据过了，去除值为undefined的
 * @param {Object} obj 需要筛选的对象
 */
export const filterParams = (obj: { [key: string]: any }) => {
  let filterObj: { [key: string]: any } = {};
  for (let item in obj) {
    if (obj[item] !== undefined) {
      filterObj[item] = obj[item];
    }
  }
  return filterObj;
};

/**
 * 判断设备是否是移动端
 * @return {boolean}
 */
export const deviceIsMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
};
