import React, { FC } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { createOpenModal, createCloseModal } from '../../../utils/modal';
import { numberOfFloors } from '../../../utils/definition';

import { ConfigProvider, Modal, Form, Select, DatePicker } from 'antd';
const { Option } = Select;

interface Props {
  visible: boolean;
  onCancel: () => void;
  onConfirm: (value: any) => void;
}

const SetFloorsNumberModal: FC<Props> = ({ visible, onCancel, onConfirm }) => {
  const [form] = Form.useForm();

  const onInnerConfirm = useStableCallback(async () => {
    await form.validateFields();
    const values = await form.getFieldsValue();
    onConfirm(values);
    onInnerCancel();
  });

  const onInnerCancel = useStableCallback(() => {
    form.resetFields();
    onCancel();
  });

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title='设置楼层数量'
        centered
        width={480}
        maskClosable={false}
        visible={visible}
        onOk={onInnerConfirm}
        onCancel={onInnerCancel}
      >
        <Form form={form} labelCol={{ span: 4 }}>
          <Form.Item
            label='楼层数'
            name='floorNum'
            rules={[{ required: true, message: '请选择楼层数' }]}
          >
            <Select style={{ width: '100%' }} placeholder={'请选择'} allowClear>
              {numberOfFloors.map((value, index) => (
                <Option value={value} key={index}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='日期'
            name='predictDate'
            rules={[{ required: true, message: '请选择日期' }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={'YYYY-MM'}
              placeholder={'年/月'}
              picker='month'
              allowClear
            />
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export const openSetFloorsNumberModal = createOpenModal(
  SetFloorsNumberModal,
  'SetFloorsNumberModal'
);

export const closeSetFloorsNumberModal = createCloseModal(
  SetFloorsNumberModal,
  'SetFloorsNumberModal'
);
