import { TreeNode } from '../components/common/contorl-tree';

export const personnelStatusConvert: { [key: string]: string[] } = {
  1: ['正常', 'green'],
  2: ['禁用', 'default'],
};

export const roleStatusConvert: { [key: string]: string } = {
  1: '观察员',
  2: '管理员',
  3: '超级管理员',
  4: '系统管理员',
};

export const roadStatusConvert: { [key: string]: string[] } = {
  1: ['待开始', 'blue'],
  2: ['观察中', 'green'],
  3: ['已完成', 'default'],
  4: ['已暂停', 'warning'],
};

export const projectStatusConvert: { [key: string]: string[] } = {
  1: ['在建', 'blue'],
  3: ['进行中', 'green'],
  4: ['已结束', 'default'],
};

// 使用人数
export const numberOfUsers: { [key: string]: string } = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11-20',
  12: '21-30',
  13: '>30',
  14: '其他',
};

export const weekInfoMap: { [key: string]: string } = {
  1: '星期一',
  2: '星期二',
  3: '星期三',
  4: '星期四',
  5: '星期五',
  6: '星期六',
  7: '星期日',
};

export const intervalMap: { [key: string]: string } = {
  MONTH: '月',
  WEEK: '周',
  DAY: '天',
  HOUR: '小时',
};

export interface ObserveList {
  [key: string]: { name: string; list: string[] };
}

export const getDefaultObserveList = (): ObserveList => ({
  spaceStatus: {
    name: '空间状态',
    list: ['无人使用', '独立工作', '多人协作', '暂无使用', '电话中'],
  },
  tool: {
    name: '使用工具',
    list: ['手提电脑', '台式电脑', '显示器', '电话', '文件', '电话会议设备'],
  },
});

export interface SpaceInfo {
  [key: string]: { name: string; list: string[] };
}

export const getDefaultSpaceInfo = (): SpaceInfo => ({
  station: {
    name: '工位空间',
    list: ['固定工位', '灵活工位', '个人办公室', '电话间', '部门办公室'],
  },
  meetingRoom: {
    name: '封闭会议空间',
    list: [
      '小型会议室(2-4人)',
      '中型会议室(6-10人)',
      '大型会议室(15-30人)',
      '培训教室(12-20人)',
      '培训教室(25-35人)',
      '培训教室(大于35人)',
      '无需预定小型会议室(2-4人)',
      '项目室',
    ],
  },
});

export const getDefaultDepartmentTree = (): TreeNode => ({
  name: 'root',
  children: [
    {
      name: '人事部',
    },
    {
      name: '财务部',
      children: [
        {
          name: '会计部',
          children: [
            {
              name: '资产管理部',
            },
          ],
        },
      ],
    },
  ],
});

// 所属行业
export const industryList: string[] = [
  '消费产品',
  '消费电子产品',
  '互联网与科技',
  '零售业',
  '交通运输与出行',
  '旅游与酒店行业',
  '房地产',
  '金融与保险',
  '医疗健康',
  '教育',
  '媒体与娱乐行业',
  '电子通信行业',
  '工业制造',
  '制药与生物科技',
  '航空与国防',
  '能源与基础建设',
  '专业服务',
  '建筑与设计',
  '公共部门',
  '非营利组织',
  '其他',
];

// 空间预测楼层数
export const numberOfFloors: number[] = [1, 2, 3];
