import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash/fp';
import { useStableCallback, useWrapCallback } from '../../hooks/use-stable-callback';
import { getRoadColumns } from '../road/table-columns';

import { getAllRoadList, userAddRoad } from '../../service/personnel';

import { Modal, Input, Table, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
const { Search } = Input;

interface Props {
  userId: string;
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const AllocationRoad: FC<Props> = ({ userId, visible, onCancel, onConfirm }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [searchName, setSearchName] = useState<string>('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [list, setList] = useState<any[]>([]);
  const [listTotal, setListTotal] = useState<number>(0);
  const [pageData, setPageData] = useState<{ [key: string]: number }>({
    page: 1,
    size: 10,
  });

  const getList = useStableCallback(async (page: number, size?: number) => {
    const listPageData = {
      page: page,
      size: size ?? pageData.size,
    };
    setTableLoading(true);
    setPageData(listPageData);
    try {
      const searchForm = { name: searchName, userId };
      const params = { ...searchForm, ...listPageData };
      const { list, total } = await getAllRoadList(params);
      setList(list);
      setListTotal(total);
    } catch (err) {
    } finally {
      setTableLoading(false);
    }
  });

  const onChange = useStableCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  });

  const onSearch = useWrapCallback((value: string) => {
    setSearchName(value);
    getList(1);
  }, debounce(200));

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const handleOk = useStableCallback(async () => {
    if (!selectedRowKeys.length) return message.warning('请先选择分配路线');
    setConfirmLoading(true);
    try {
      await userAddRoad(userId, selectedRowKeys);
      message.success('分配成功');
      onConfirm();
    } catch (err) {
    } finally {
      setConfirmLoading(false);
    }
  });

  useEffect(() => {
    if (visible) {
      setSearchName('');
      setSelectedRowKeys([]);
      setTimeout(() => {
        getList(1);
      }, 200);
    }
  }, [getList, visible]);

  const columns = useMemo(
    () =>
      getRoadColumns(
        undefined,
        [
          'projectSequence',
          'expectSum',
          'actualSum',
          'accountNames',
          'updater',
          'startTime',
          'endTime',
        ],
        false,
        false,
      ),
    [],
  );

  return (
    <Modal
      title='分配路线'
      width={'60%'}
      centered
      maskClosable={false}
      visible={visible}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={onCancel}
    >
      <div className='common-model'>
        <div className='model-header flex-x m-s-between'>
          <div className='header-title'>路线列表</div>
          <div className='header-operate flex-x m-s-end'>
            <Search
              placeholder='搜索路线/项目名称'
              value={searchName}
              onChange={onChange}
              onSearch={onSearch}
              style={{ width: 230 }}
            />
            <ReloadOutlined className='refresh' onClick={() => getList(1)} />
          </div>
        </div>

        <Table
          className='list-table'
          size={'middle'}
          scroll={{ x: '100%', y: 300 }}
          rowKey='roadId'
          loading={tableLoading}
          columns={columns}
          dataSource={list}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          pagination={{
            size: 'small',
            current: pageData.page,
            pageSize: pageData.size,
            total: listTotal,
            showQuickJumper: true,
            showSizeChanger: true,
            onChange: getList,
          }}
        />
      </div>
    </Modal>
  );
};

export default AllocationRoad;
