import loginLogo from '../assets/images/logo-title.png';

import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { mangLocal } from '../utils/common';
import { useStableCallback } from '../hooks/use-stable-callback';
import HelpFeedback from './user/help-feedback';

import { Layout, Menu, Dropdown, Button } from 'antd';
const { Header } = Layout;

const HeaderCustom: FC = () => {
  const history = useHistory();

  const userInfo = mangLocal('userInfo');

  const [isHelpVisible, setIsHelpVisible] = useState(false);

  const onChange = useStableCallback(() => {
    setIsHelpVisible(false);
  });

  const gotoHome = useStableCallback(() => {
    window.open(`${process.env.REACT_APP_HOME_URL}`, '_blank');
  });

  const dropdownClick = useStableCallback(({ key }) => {
    if (key === 'logout') {
      sessionStorage.clear();
      mangLocal('userInfo', 'del');
      history.push('/login');
    } else {
      history.push('/modifyPassword');
    }
  });

  const menu = (
    <Menu onClick={dropdownClick}>
      <Menu.Item key='logout'>退出登录</Menu.Item>
      <Menu.Item key='changePassword'>修改密码</Menu.Item>
    </Menu>
  );

  return (
    <Header className='header-custom flex-x m-l-r m-s-between'>
      <img className='header-logo' src={loginLogo} alt='' />
      <div>
        <Button
          className='header-btn media-hidden'
          type='text'
          onClick={() => gotoHome()}
        >
          操作指南
        </Button>
        <Button
          className='header-btn media-hidden'
          type='text'
          onClick={() => setIsHelpVisible(true)}
        >
          联系客服
        </Button>
        <Dropdown overlay={menu}>
          <Button className='header-btn' type='text'>
            Hi, {userInfo?.accountName}
          </Button>
        </Dropdown>
      </div>
      {isHelpVisible && (
        <HelpFeedback isHelpVisible={isHelpVisible} onChange={onChange} />
      )}
    </Header>
  );
};

export default HeaderCustom;
