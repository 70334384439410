import './assets/styles/index.scss';
import 'antd/dist/antd.css';

import ReactDOM from 'react-dom';
import { BrowserRouter, Router } from 'react-router-dom';
import { ViewRouter } from './router';
import { history } from './utils/history';
import { mangLocal } from './utils/common';

import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

const userInfo = mangLocal('userInfo');
if (!userInfo) {
  history.replace('/login');
}

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <BrowserRouter>
      <Router history={history}>
        <ViewRouter />
      </Router>
    </BrowserRouter>
  </ConfigProvider>,
  document.getElementById('root')
);
