import React, { FC, useEffect } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { createOpenModal, createCloseModal } from '../../../utils/modal';

import { ConfigProvider, Modal, Form, Input, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

interface Props {
  visible: boolean;
  columnList: string[];
  onCancel: () => void;
  onConfirm: (values: string[]) => void;
}

const ColumnConfigureModal1: FC<Props> = ({
  visible,
  columnList,
  onCancel,
  onConfirm,
}) => {
  const [form] = Form.useForm();

  const onInnerConfirm = useStableCallback(async () => {
    await form.validateFields();
    const values = await form.getFieldsValue();
    onConfirm(values.names);
    onInnerCancel();
  });

  const onInnerCancel = useStableCallback(() => {
    form.resetFields();
    onCancel();
  });

  useEffect(() => {
    form.setFieldsValue({ names: columnList });
  });

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title='列名配置'
        centered
        width={520}
        maskClosable={false}
        visible={visible}
        onOk={onInnerConfirm}
        onCancel={onInnerCancel}
      >
        <Form className='column-configure' form={form} labelCol={{ span: 4 }}>
          <Form.List
            name='names'
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 2) {
                    return Promise.reject(new Error('至少添加一列人员'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? '工作方式' : `人员${index}`}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      noStyle
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: '不可为空',
                        },
                      ]}
                    >
                      <Input
                        placeholder='请输入'
                        style={{ width: '80%' }}
                        allowClear
                      />
                    </Form.Item>
                    {fields.length > 2 && index !== 0 ? (
                      <MinusCircleOutlined
                        className='delete-button'
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item wrapperCol={{ offset: 4 }}>
                  <Button
                    type='dashed'
                    className='add-button'
                    onClick={() => add()}
                    style={{ width: '80%' }}
                    icon={<PlusOutlined />}
                  >
                    添加列
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export const openColumnConfigureModal1 = createOpenModal(
  ColumnConfigureModal1,
  'ColumnConfigureModal1'
);

export const closeColumnConfigureModal1 = createCloseModal(
  ColumnConfigureModal1,
  'ColumnConfigureModal1'
);
