import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { render } from 'react-dom';
import { debounce } from 'lodash/fp';
import { useStableCallback, useWrapCallback } from '../../../hooks/use-stable-callback';
import usePersonnelTable from '../../personnel/use-personnel-table';
import { createOpenModal } from '../../../utils/modal';

import { getUserListForRoad } from '../../../service/personnel';
import { roadAddUser } from '../../../service/road';

import { Modal, Input, message, ConfigProvider } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
const { Search } = Input;

interface Props {
  roadId: string;
  visible: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const RoadObserversSet: FC<Props> = ({ roadId, visible, onCancel, onConfirm }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchName, setSearchName] = useState<string>('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const { table, getList } = usePersonnelTable({
    needInit: false,
    tableScroll: { x: '100%', y: 300 },
    search: { roadId, accountName: searchName },
    rowSelection: {
      selectedRowKeys,
      onChange: onSelectChange,
    },
    getUserList: getUserListForRoad,
  });

  const onChange = useStableCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  });

  const onSearch = useWrapCallback((value: string) => {
    setSearchName(value);
    getList(1);
  }, debounce(200));

  const handleOk = useStableCallback(async () => {
    if (!selectedRowKeys.length) return message.warning('请先选择观察人员');
    setConfirmLoading(true);
    try {
      await roadAddUser(roadId, selectedRowKeys);
      message.success('设置成功');
      onConfirm?.();
    } catch (err) {
    } finally {
      setConfirmLoading(false);
    }
  });

  useEffect(() => {
    if (visible) {
      setSearchName('');
      setSelectedRowKeys([]);
      setTimeout(() => {
        getList(1);
      }, 200);
    }
  }, [getList, visible]);

  return (
    <Modal
      title='设置观察员'
      width={'60%'}
      centered
      maskClosable={false}
      visible={visible}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={onCancel}
    >
      <div className='common-model'>
        <div className='model-header flex-x m-s-between'>
          <div className='header-title'>人员列表</div>
          <div className='header-operate flex-x m-s-end'>
            <Search
              placeholder='搜索账户名'
              value={searchName}
              onChange={onChange}
              onSearch={onSearch}
              style={{ width: 230 }}
            />
            <ReloadOutlined className='refresh' onClick={() => getList(1)} />
          </div>
        </div>

        <ConfigProvider locale={zhCN}>{table}</ConfigProvider>
      </div>
    </Modal>
  );
};

export const openRoadObserversSetModal = createOpenModal(RoadObserversSet, 'RoadObserversSetModal');

export const closeRoadObserversSetModal = () => {
  let div = document.getElementById('RoadObserversSetModal');
  if (!div) {
    div = document.createElement('div');
    div.id = 'RoadObserversSetModal';
    document.body.appendChild(div);
  }

  render(<RoadObserversSet visible={false} roadId='' />, div);
};

export default RoadObserversSet;
