import React, { useState } from 'react';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { filterParams } from '../../utils/common';

import RoadCheckQuery from './query';
import RoadCheckTable from './table';

const RoadCheck = () => {
  const [searchForm, setSearchForm] = useState<{ [key: string]: any }>({});

  const onSearchFinish = useStableCallback((values) => {
    setSearchForm(filterParams(values));
  });
  const onSearchReset = useStableCallback((values) => {
    setSearchForm(filterParams(values));
  });

  return (
    <>
      <div className='common-title'>路线检查</div>
      <RoadCheckQuery onFinish={onSearchFinish} onReset={onSearchReset} />
      <RoadCheckTable searchForm={searchForm} />
    </>
  );
};

export default RoadCheck;
