import React, { FC, useState, KeyboardEvent, useRef, FocusEvent } from 'react';
import { useStableCallback } from '../../hooks/use-stable-callback';

import { Input } from 'antd';

interface AddNewProps {
  name: string;
  onChange?: (value: string) => void;
}

export const AddNew: FC<AddNewProps> = ({ name, onChange }) => {
  const [show, setShow] = useState(false);
  const ref = useRef<any>();

  const handleInput = useStableCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (!(event.target as any).value) return;
    onChange?.((event.target as any).value);
    setShow(false);
  });

  const onClick = useStableCallback(() => {
    setShow(true);
    setTimeout(() => {
      ref.current.focus({
        cursor: 'start',
      });
    });
  });

  const onBlur = useStableCallback((event: FocusEvent<HTMLInputElement, Element>) => {
    if (!(event.target as any).value) return;
    onChange?.((event.target as any).value);
    setShow(false);
  });

  return (
    <div style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
      <span onClick={onClick} style={{ color: '#0676ED' }}>
        {name}
      </span>
      {show && (
        <Input
          ref={ref}
          size='small'
          onPressEnter={handleInput}
          onBlur={onBlur}
          style={{ width: 120, marginLeft: 8, height: 22 }}
        />
      )}
    </div>
  );
};
