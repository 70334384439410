import React from 'react';
import { roleStatusConvert, personnelStatusConvert } from '../../utils/definition';

import { ColumnsType } from 'antd/es/table';

export const getPersonnelColumns = (
  renderOpeartor?: (record: any) => React.ReactElement,
  excluedIds: string[] = [],
): ColumnsType => {
  let list: ColumnsType = [
    {
      title: '账户ID',
      dataIndex: 'accountId',
      key: 'accountId',
      width: 120,
    },
    {
      title: '账户名',
      dataIndex: 'accountName',
      key: 'accountName',
      width: 140,
    },
    {
      title: '角色',
      dataIndex: 'role',
      key: 'role',
      width: 120,
      render: (text: any) => roleStatusConvert[text],
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status: any) => (
        <div className='flex-x m-s-start table-status'>
          <div className={`circle ${personnelStatusConvert[status]?.[1]}`}></div>
          <div>{personnelStatusConvert[status]?.[0]}</div>
        </div>
      ),
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 180,
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  if (renderOpeartor) {
    list.push({
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: 80,
      render: (text: string, record: any) => renderOpeartor(record),
    });
  }

  return list;
};
