import './index.scss';

import React, { FC, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import BreadcrumbCustom from '../../breadcrumb-custom';
import PorjectRoadEdit from './road';
import PorjectManageEdit from './manage';
import PorjectBaseSetting from './base';
import { useStableCallback } from '../../../hooks/use-stable-callback';

import { getProjectDetail, Project } from '../../../service/project';

import { Menu, message } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

const activeMenuRegex = /^\/project\/edit\/\w+\/(\w+)$/;

const PorjectSetting: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams() as any;
  const [selectMenu, setSelectMenu] = useState<string[]>([]);
  const [hasBaseChanged, setHasBaseChanged] = useState(false);
  const [project, setProject] = useState<Project>();

  const onSelect = useStableCallback(async (info: any) => {
    // 如果基础新有变动，切换前提示报错
    if (selectMenu[0] === 'base' && hasBaseChanged) {
      message.error('基础信息未保存，请先保存后再切换');
      return;
    }

    // 只有基础信息完善才可进入路线设置页
    if (!project?.finished && info.key === 'road') {
      message.error('请先完善基础信息');
      return;
    }

    setSelectMenu([info.key]);
    history.replace(`/project/edit/${(params as any).id}/${info.key}`);
  });

  const onChange = useStableCallback(() => {
    setHasBaseChanged(true);
  });

  const onSave = useStableCallback(async () => {
    setHasBaseChanged(false);

    // 只有基础信息完善才可进入路线设置页
    const project = await getProjectDetail(params.id);
    setProject(project);
    if (project.finished) {
      setSelectMenu(['road']);
      history.replace(`/project/edit/${(params as any).id}/road`);
    }
  });

  useEffect(() => {
    const res = activeMenuRegex.exec(location.pathname);
    setSelectMenu([res?.[1] ?? '']);
  }, [location.pathname]);

  useEffect(() => {
    getProjectDetail(params.id).then(setProject).catch(() => {});
  }, [params.id]);

  return (
    <>
      <BreadcrumbCustom
        paths={['项目管理', '项目设置']}
        title={`项目：${project?.projectSequence}（${project?.projectName}）`}
      />

      <div className='page-custom project-setting minauto flex-x a-s-stretch'>
        <Menu
          selectedKeys={selectMenu}
          className='minauto'
          style={{ width: 200, color: '#7B7B80' }}
          onClick={onSelect}
        >
          <Menu.Item key='base'>
            <div className='flex-x m-s-between'>
              <span>基本设置</span>
              {project?.finished && <CheckCircleFilled style={{ color: '#12C99B' }} />}
            </div>
          </Menu.Item>
          <Menu.Item key='road'>路线设置</Menu.Item>
          <Menu.Item key='manage'>项目管理员</Menu.Item>
        </Menu>

        <div className='maxauto' style={{ minWidth: 0 }}>
          <Switch>
            <Route path='/project/edit/:id/base'>
              <PorjectBaseSetting project={project} onSave={onSave} onChange={onChange} />
            </Route>
            <Route path='/project/edit/:id/road'>
              <PorjectRoadEdit />
            </Route>
            <Route path='/project/edit/:id/manage'>
              <PorjectManageEdit />
            </Route>
            <Redirect to='/project/edit/:id/base' />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default PorjectSetting;
