import column from '../../../assets/images/column.png';

import React, { FC, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { sum } from 'lodash/fp';
import { EditableRow } from '../../common/editable/editable-row';
import {
  DataType,
  EditableCell,
  InputCell,
} from '../../common/editable/editable-cell';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import {
  closeColumnConfigureModal1,
  openColumnConfigureModal1,
} from '../modal/column-configure1';
import { staffColumn, getStaffList } from '../../../utils/predict';

import { Button, Card, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  value?: any;
  onChange?: (newValue: any) => void;
}

const PredictTemplate1: FC<Props> = ({ value, onChange }) => {
  const [columnList, setColumnList] = useState<string[]>([]);
  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    []
  );

  const columnConfigure = useStableCallback(() => {
    openColumnConfigureModal1({
      visible: true,
      columnList,
      onCancel: closeColumnConfigureModal1,
      onConfirm: (values) => {
        const data = getStaffList(values, dataSource);
        setColumnList(values);
        setDataSource(data);
        onChange?.({ tableData: data, columnList: values });
      },
    });
  });

  const handleValueChange = useStableCallback((newValue: DataType[]) => {
    setDataSource(newValue);
    onChange?.({ tableData: newValue, columnList: columnList });
  });

  const handleDelete = useStableCallback((id: React.Key) => {
    handleValueChange(dataSource.filter((item) => item.id !== id));
  });

  const handleAdd = useStableCallback(() => {
    const newData: DataType = {
      id: uuid(),
      data: ['部门名称', ...Array(columnList.length - 1).fill(0)],
    };
    handleValueChange([...dataSource, newData]);
  });

  const handleChange = useStableCallback(
    (row: DataType, changedKey: keyof DataType) => {
      handleValueChange(
        dataSource.map((each) => {
          if (row.id === each.id) {
            return {
              ...each,
              ...row,
            };
          }
          return each;
        })
      );
    }
  );

  const columns = React.useMemo(() => {
    let tables = columnList.map((item, index) => {
      return {
        title: item,
        dataIndex: 'data|' + index,
        key: 'data|' + index,
        width: 200,
        render: (text: string, record: any) => {
          return record.data[index];
        },
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'input',
          title: item,
          dataIndex: 'data|' + index,
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell
                name={name}
                onChange={onChange}
                isInputNumber={index !== 0}
                rules={[{ required: true, message: '不可为空' }]}
              >
                {originCell}
              </InputCell>
            );
          },
        }),
      };
    });

    return [
      ...tables,
      {
        title: '现有人员总数',
        dataIndex: 'total',
        key: 'total',
        width: 240,
        render: (text: string, record: any) => {
          return sum([...record.data].splice(1));
        },
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        width: 100,
        render: (text: any, record: any) => (
          <>
            <Button type='link' onClick={() => handleDelete(record?.id)}>
              删除
            </Button>
          </>
        ),
      },
    ];
  }, [columnList, handleChange, handleDelete]);

  useEffect(() => {
    if (value) {
      setDataSource(value?.tableData);
      setColumnList(value?.columnList);
    } else {
      const data = getStaffList(staffColumn, []);
      setDataSource(data);
      setColumnList(staffColumn);
      onChange?.({
        tableData: data,
        columnList: staffColumn,
      });
    }
  }, [onChange, value]);

  const extraButton = useMemo(() => {
    return (
      <>
        <img className='column-icon' src={column} alt='column' />
        <Button type='link' onClick={columnConfigure}>
          列名配置
        </Button>
      </>
    );
  }, [columnConfigure]);

  return (
    <Card
      id='step1'
      className='space-predict-edit-table'
      type='inner'
      title='人员情况'
      extra={extraButton}
    >
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        components={components}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        summary={() => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>小计</Table.Summary.Cell>

              {[...columnList].slice(1).map((each, index) => {
                return (
                  <Table.Summary.Cell index={index + 1}>
                    {sum(dataSource.map((row) => row.data[index + 1]))}
                  </Table.Summary.Cell>
                );
              })}

              <Table.Summary.Cell index={columnList.length} colSpan={2}>
                {sum(dataSource.map((row) => sum([...row.data].slice(1))))}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
      <Button
        block
        type='dashed'
        icon={<PlusOutlined />}
        style={{ margin: '16px 0 0' }}
        onClick={handleAdd}
      >
        新增
      </Button>
    </Card>
  );
};

export default PredictTemplate1;
