import { preFetch } from './fetch';
import moment from 'moment';

export interface RoadDetail {
  roadFinished?: boolean;
  roadName: string;
  roadSequence?: string;
  roadId?: string;
  projectFinished?: boolean;
  projectName?: string;
  projectSequence?: string;
  projectId: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  interval: 'MONTH' | 'WEEK' | 'DAY' | 'HOUR';
  intervalNum: string;
  repetition: string[];
  userNum: string[];
  pointImageUrl?: string;
  originImageUrl?: string;
  pointData?: { x: number; y: number }[];
  roadObserveInfoList: {
    roadObserveName: string;
    observeItemList: {
      itemName: string;
      checked: boolean;
    }[];
  }[];
  status?: number;
}

export interface RoadList {
  roadId: number;
  roadSequence: string;
  roadName: string;
  projectSequence: string;
  expectSum: number;
  actualSum: number;
  accountNames: string;
  updater: string;
  status: number;
  startTime: string;
  endTime: string;
  updateTime: string;
}

// 根据项目id和条件筛选项目下的路线列表
export const getRoadList = async (body: object): Promise<{ list: RoadList[]; total: number }> => {
  const res = await preFetch('/road/getRoadList', {
    method: 'POST',
    body,
  });
  return res.data;
};

// 根据用户id获取路线列表
export const getUserRoadList = async (
  body: object,
): Promise<{ list: RoadList[]; total: number }> => {
  const res = await preFetch('/road/getUserRoadList', {
    method: 'POST',
    body,
  });
  return res.data;
};

// 路线详情
export const getRoadDetail = async (roadId: string): Promise<RoadDetail> => {
  const res = await preFetch('/road/getRoadDetail', {
    method: 'POST',
    body: { roadId },
  });
  res.data.startDate = moment(res.data.startDate, 'YYYYMMDD').format('YYYY.MM.DD');
  res.data.endDate = moment(res.data.endDate, 'YYYYMMDD').format('YYYY.MM.DD');
  res.data.startTime = moment(res.data.startTime, 'HHmmss').format('HH:mm');
  res.data.endTime = moment(res.data.endTime, 'HHmmss').format('HH:mm');
  res.data.pointData = (res.data?.pointData ?? []).sort(
    (left: any, right: any) => left.pointNum - right.pointNum,
  );
  return res.data;
};

// 创建路线
export const createRoad = async (road: RoadDetail) => {
  const res = await preFetch('/road/createRoad', {
    method: 'POST',
    body: road,
  });
  return res;
};

// 编辑路线
export const editRoad = async (road: Partial<RoadDetail>) => {
  const res = await preFetch('/road/editRoad', {
    method: 'POST',
    body: road,
  });
  return res;
};

// 删除路线
export const deleteRoad = async (roadId: string) => {
  const res = await preFetch('/road/deleteRoad', {
    method: 'POST',
    body: { roadId },
  });
  return res;
};

// 路线添加观察员
export const roadAddUser = async (roadId: string, userIdList: number[]) => {
  const res = await preFetch('/road/roadAddUser', {
    method: 'POST',
    body: { roadId, userIdList },
  });
  return res;
};

// 路线移除观察员
export const roadRemoveUser = async (roadId: string, userIdList: number[]) => {
  const res = await preFetch('/road/roadRemoveUser', {
    method: 'POST',
    body: { roadId, userIdList },
  });
  return res;
};

// 开始观察路线
export const publishRoad = async (roadId: string[]) => {
  const res = await preFetch('/road/publishRoad', {
    method: 'POST',
    body: { roadId },
  });
  return res;
};

// 暂停观察路线
export const pauseRoad = async (roadId: string[]) => {
  const res = await preFetch('/road/pauseRoad', {
    method: 'POST',
    body: { roadId },
  });
  return res;
};

// 上传点位
export const uploadPointInfo = async (
  projectId: string,
  roadId: string,
  pointData: { x: number; y: number }[],
) => {
  const res = await preFetch('/road/uploadPointInfo', {
    method: 'POST',
    body: {
      projectId,
      roadId,
      pointData: pointData.map((each, index) => ({
        x: Number(each.x.toFixed(4)),
        y: Number(each.y.toFixed(4)),
        pointNum: index + 1,
        color: '#0676ED',
      })),
    },
  });
  return res.data;
};
