import React, { FC, useMemo } from 'react';

import { Breadcrumb } from 'antd';

interface Props {
  paths: string[];
  title?: string | false;
  extra?: React.ReactElement;
}

const BreadcrumbCustom: FC<Props> = ({ paths, title, extra }) => {
  const sureTitle = useMemo(() => {
    if (title === false) {
      return null;
    }
    return title ?? paths[paths.length - 1];
  }, [paths, title]);

  return (
    <div className='breadcrumb-custom'>
      <Breadcrumb>
        {paths.map((each) => (
          <Breadcrumb.Item key={each}>{each}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
      {(sureTitle || extra) && (
        <div className='breadcrumb-item flex-x m-s-between'>
          {sureTitle}
          {extra}
        </div>
      )}
    </div>
  );
};

export default BreadcrumbCustom;
