import { preFetch } from './fetch';

export interface UserList {
  userId: number;
  accountId: string;
  accountName: string;
  role: number;
  status: number;
  updateTime: string;
}

// 获取用户列表
export const getUserList = async (body: object): Promise<{ list: UserList[]; total: number }> => {
  const res = await preFetch('/user/getUserList', {
    method: 'POST',
    body,
  });
  return res.data;
};

// 创建用户
export const createUser = async (body: object) => {
  const res = await preFetch('/user/createUser', {
    method: 'POST',
    body,
  });
  return res;
};

// 修改账号名称
export const modifyAccountName = async (userId: string, accountName: string) => {
  const res = await preFetch('/user/modifyAccountName', {
    method: 'POST',
    body: { userId, accountName },
  });
  return res;
};

// 修改用户角色
export const modifyUserRole = async (userId: string, role: number) => {
  const res = await preFetch('/user/modifyUserRole', {
    method: 'POST',
    body: { userId, role },
  });
  return res;
};

// 更新用户状态
export const editUserStatus = async (userId: string, status: number) => {
  const res = await preFetch('/user/updateUserStatus', {
    method: 'POST',
    body: { userId, status },
  });
  return res;
};

// 重置密码
export const resetPassword = async (userId: string) => {
  const res = await preFetch('/user/resetPassword', {
    method: 'POST',
    body: { userId },
  });
  return res;
};

// 删除用户
export const deleteUser = async (userId: string) => {
  const res = await preFetch('/user/deleteUser', {
    method: 'POST',
    body: { userId },
  });
  return res;
};

// 分配路线-根据搜索路线、项目名称查询所有路线列表
export const getAllRoadList = async (body: object): Promise<{ list: any[]; total: number }> => {
  const res = await preFetch('/road/getAllRoadList', {
    method: 'POST',
    body,
  });
  return res.data;
};

// 分配项目-根据项目名称查询所有项目列表
export const getAllProjectList = async (body: object): Promise<{ list: any[]; total: number }> => {
  const res = await preFetch('/project/getAllProjectList', {
    method: 'POST',
    body,
  });
  return res.data;
};

// 指定用户分配路线
export const userAddRoad = async (userId: string, roadIdList: number[]) => {
  const res = await preFetch('/user/userAddRoad', {
    method: 'POST',
    body: { userId, roadIdList },
  });
  return res;
};

// 给指定用户分配项目
export const userAddProject = async (userId: string, projectIdList: number[]) => {
  const res = await preFetch('/user/userAddProject', {
    method: 'POST',
    body: { userId, projectIdList },
  });
  return res;
};

// 指定用户移除路线
export const userRemoveRoad = async (userId: string, roadIdList: number[]) => {
  const res = await preFetch('/user/userRemoveRoad', {
    method: 'POST',
    body: { userId, roadIdList },
  });
  return res;
};

// 指定用户移除项目
export const userRemoveProject = async (userId: string, projectIdList: number[]) => {
  const res = await preFetch('/user/userRemoveProject', {
    method: 'POST',
    body: { userId, projectIdList },
  });
  return res;
};

// 设置管理员弹窗获取人员列表
export const getUserListForProject = async (
  body: object,
): Promise<{ list: UserList[]; total: number }> => {
  const res = await preFetch('/user/getUserListForProject', {
    method: 'POST',
    body,
  });
  return res.data;
};

// 设置观察员弹窗获取人员列表
export const getUserListForRoad = async (
  body: object,
): Promise<{ list: UserList[]; total: number }> => {
  const res = await preFetch('/user/getUserListForRoad', {
    method: 'POST',
    body,
  });
  return res.data;
};

// 获取设置项目-项目管理员列表
export const getProjectManager = async (
  body: object,
): Promise<{ list: UserList[]; total: number }> => {
  const res = await preFetch('/user/getProjectManager', {
    method: 'POST',
    body,
  });
  return res.data;
};

// 获取路线观察员列表
export const getRoadObserver = async (
  body: object,
): Promise<{ list: UserList[]; total: number }> => {
  const res = await preFetch('/user/getRoadObserver', {
    method: 'POST',
    body,
  });
  return res.data;
};

// 超管获取添加人员列表
export const getManager = async (accountName: string): Promise<UserList[]> => {
  const res = await preFetch('/user/getManager', {
    method: 'POST',
    body: { accountName },
  });
  return res.data;
};

// 超管添加人员
export const bindingManager = async (userId: string) => {
  const res = await preFetch('/user/bindingManager', {
    method: 'POST',
    body: { userId },
  });
  return res;
};
