import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { cityList } from '../../utils/city';
import { industryList } from '../../utils/definition';

import { createProject } from '../../service/project';

import { Form, Input, Button, Select, message, Cascader, InputNumber, DatePicker } from 'antd';
const { Option } = Select;

const PorjectAdd: FC = () => {
  const history = useHistory();

  const [form] = Form.useForm();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const onFinish = useStableCallback(async (values: any) => {
    setSubmitLoading(true);
    try {
      const { data } = await createProject(values);
      message.success('创建成功');
      history.replace(`/project/add/success/${data.projectId}`);
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  return (
    <div className='page-custom common-details'>
      <Form
        name='personnel-add'
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label='项目名称'
          name='projectName'
          rules={[{ required: true, message: '请输入项目名称' }]}
        >
          <Input placeholder='请输入项目名称' allowClear />
        </Form.Item>

        <Form.Item
          label='所属行业'
          name='industry'
          rules={[{ required: true, message: '请选择项目所属行业' }]}
        >
          <Select placeholder='请选择项目所属行业' allowClear>
            {industryList.map((each, index) => (
              <Option value={each} key={index}>
                {each}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='项目城市'
          name='city'
          rules={[{ required: true, message: '请选择项目所在城市' }]}
        >
          <Cascader
            fieldNames={{ label: 'name', value: 'name', children: 'mallCityList' }}
            options={cityList}
            placeholder='请选择项目所在城市'
            allowClear
          />
        </Form.Item>

        <Form.Item
          label='项目地点'
          name='address'
          rules={[{ required: true, message: '请输入项目详细地址' }]}
        >
          <Input placeholder='请输入项目详细地址' allowClear />
        </Form.Item>

        <Form.Item
          label='楼层数量'
          name='floor'
          rules={[{ required: true, message: '请输入楼层数' }]}
        >
          <InputNumber style={{ width: '100%' }} min='0' placeholder='请输入楼层数' />
        </Form.Item>

        <Form.Item
          label='面积(m²)'
          name='area'
          rules={[{ required: true, message: '请输入建筑面积' }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min='0'
            precision={2}
            placeholder='请输入建筑面积'
          />
        </Form.Item>

        <Form.Item
          label='租约到期日'
          name='expireDate'
          // rules={[{ required: true, message: '请选择租约到期日' }]}
        >
          <DatePicker format='YYYY-MM-DD' style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label='租约提醒时间'
          name='notifyDate'
          // rules={[{ required: true, message: '请选择租约提醒时间' }]}
        >
          <DatePicker showTime format='YYYY-MM-DD HH:mm' style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type='primary' htmlType='submit' loading={submitLoading}>
            确定
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PorjectAdd;
