import React, { FC } from 'react';
import { useStableCallback } from '../../hooks/use-stable-callback';

import { AddNew } from './add-new';

import { Tree, TreeProps } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';

export interface TreeNode {
  name: string;
  children?: TreeNode[];
}

export const SPLIT_VALUE = '{[()]}';

const renderTreeNode = (
  treeNode: TreeNode,
  handleAdd?: (addName: string, level: number, parentKeys: string[]) => void,
  parentKeys: string[] = [],
  level: number = 1,
) => {
  const keys = [...parentKeys, treeNode.name];
  if (level !== 3) {
    return (
      <Tree.TreeNode key={keys.join(SPLIT_VALUE)} title={treeNode.name}>
        {(treeNode.children ?? []).map((each) => renderTreeNode(each, handleAdd, keys, level + 1))}
        {level === 1 && handleAdd && (
          <Tree.TreeNode
            icon={<PlusSquareOutlined />}
            key={keys.join(SPLIT_VALUE) + 'add'}
            title={<AddNew name='添加二级部门' onChange={(value) => handleAdd(value, 2, keys)} />}
            checkable={false}
          />
        )}
        {level === 2 && handleAdd && (
          <Tree.TreeNode
            icon={<PlusSquareOutlined />}
            key={keys.join(SPLIT_VALUE) + 'add'}
            title={<AddNew name='添加三级部门' onChange={(value) => handleAdd(value, 3, keys)} />}
            checkable={false}
          />
        )}
      </Tree.TreeNode>
    );
  }

  return <Tree.TreeNode key={keys.join(SPLIT_VALUE)} title={treeNode.name} />;
};

interface ControlTreeProps extends TreeProps {
  mutiple?: boolean;
  treeDate: TreeNode;
  value?: TreeProps['checkedKeys'];
  onChange?: (value: TreeProps['checkedKeys']) => void;
  onAddNew?: (name: string, level?: number, parentKeys?: string[]) => void;
}

export const ControlTree: FC<ControlTreeProps> = ({
  mutiple = true,
  value,
  treeDate,
  onChange,
  onAddNew,
  ...otherProps
}) => {
  const innerChange = useStableCallback((checkedKeys: TreeProps['checkedKeys'], checkInfo: any) => {
    if (mutiple) {
      onChange?.(checkedKeys);
    } else if (checkInfo.checked) {
      onChange?.([checkInfo.node.key]);
    } else {
      onChange?.([]);
    }
  });

  return (
    <Tree checkedKeys={value} onCheck={innerChange} {...otherProps}>
      {treeDate.children?.map((each) => renderTreeNode(each, onAddNew))}

      {onAddNew && (
        <Tree.TreeNode
          icon={<PlusSquareOutlined />}
          key={'root-add'}
          title={<AddNew name='其他部门' onChange={(value) => onAddNew(value)} />}
          checkable={false}
        />
      )}
    </Tree>
  );
};
