import React from 'react';
import { Link } from 'react-router-dom';
import { roadStatusConvert } from '../../utils/definition';

import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';

export const getRoadColumns = (
  renderOpeartor?: (record: any) => React.ReactElement,
  excluedIds: string[] = [],
  link: boolean = true,
  statusUseTag: boolean = true,
): ColumnsType => {
  const list: ColumnsType = [
    {
      title: '路线ID',
      dataIndex: 'roadSequence',
      key: 'roadSequence',
      render: (text: string, record: any) => {
        if (link) {
          return <Link to={`/road/detail/${record.roadId}`}>{text}</Link>;
        }
        return text;
      },
      fixed: 'left' as any,
      width: 120,
    },
    {
      title: '路线名称',
      dataIndex: 'roadName',
      key: 'roadName',
      width: 150,
    },
    {
      title: '项目ID',
      dataIndex: 'projectSequence',
      key: 'projectSequence',
      render: (text: string, record: any) => {
        if (link) {
          return <Link to={`/project/detail/${record.projectId}`}>{text}</Link>;
        }
        return text;
      },
      width: 80,
    },
    {
      title: '项目名称',
      dataIndex: 'projectName',
      key: 'projectName',
      width: 150,
    },
    {
      title: '预定收集总量',
      dataIndex: 'expectSum',
      key: 'expectSum',
      align: 'right' as any,
      render: (text: string) => <span>{text ?? '--'}</span>,
      width: 140,
    },
    {
      title: '目前收集总量',
      dataIndex: 'actualSum',
      key: 'actualSum',
      align: 'right' as any,
      render: (text: string) => <span>{text ?? '--'}</span>,
      width: 140,
    },
    {
      title: '观察员',
      dataIndex: 'accountNames',
      key: 'accountNames',
      render: (text: string) => <span>{text ?? '--'}</span>,
      width: 140,
      ellipsis: true,
    },
    {
      title: '最近操作人',
      dataIndex: 'updater',
      key: 'updater',
      render: (text: string) => <span>{text ?? '--'}</span>,
      width: 140,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        if (statusUseTag) {
          return <Tag color={roadStatusConvert[status]?.[1]}>{roadStatusConvert[status]?.[0]}</Tag>;
        }
        return (
          <div className='flex-x m-s-start table-status'>
            <div className={`circle ${roadStatusConvert[status]?.[1]}`}></div>
            <div>{roadStatusConvert[status]?.[0]}</div>
          </div>
        );
      },
      width: 100,
    },
    {
      title: '任务开始时间',
      key: 'startTime',
      dataIndex: 'startTime',
      width: 180,
    },
    {
      title: '任务结束时间',
      key: 'endTime',
      dataIndex: 'endTime',
      width: 180,
    },
    {
      title: '更新时间',
      key: 'updateTime',
      dataIndex: 'updateTime',
      width: 180,
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  if (renderOpeartor && !excluedIds.includes('operation')) {
    list.push({
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: 140,
      fixed: 'right' as any,
      render: (text: any, record: any) => renderOpeartor(record),
    });
  }

  return list;
};
