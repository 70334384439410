import './config.scss';

import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { intervalMap, numberOfUsers, ObserveList, weekInfoMap } from '../../../utils/definition';
import { mangLocal } from '../../../utils/common';
import { AddNew } from '../../common/add-new';

import { getUserProjectList } from '../../../service/project';

import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Row,
  Col,
  Checkbox,
  Modal,
  FormInstance,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const { Option } = Select;
const { RangePicker } = DatePicker;

interface Props {
  form: FormInstance;
  className?: string;
  observeList: ObserveList;
  onObserveListChange: (observeList: ObserveList) => void;
  onChange?: () => void;
}

const RoadConfig: FC<Props> = ({ form, className, observeList, onObserveListChange, onChange }) => {
  const userInfo = mangLocal('userInfo');

  const location: any = useLocation();
  const { projectId } = location.state ?? {};

  const [usedForm] = Form.useForm(form);

  const [innerObserveList, setObserveList] = useState<ObserveList>({});
  const [projectInfo, setProjectInfo] = useState<any[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalName, setModalName] = useState<string>('');

  const onObserveChange = useStableCallback((type: string, checkList: string[] = []) => {
    const sureCheckedList = checkList.filter((each) => !['other'].includes(each));

    usedForm.setFieldsValue({
      [type + '-all']: sureCheckedList.length === innerObserveList[type].list.length,
    });
  });

  const addObserveList = useStableCallback((type: string, value: string) => {
    const newObserveList = { ...innerObserveList };
    const newList = [...newObserveList[type].list, value];
    newObserveList[type] = {
      ...newObserveList[type],
      list: newList,
    };
    setObserveList(newObserveList);
    onObserveListChange?.(newObserveList);
  });

  const onCheckAllChange = useStableCallback((type: string, checked: boolean) => {
    if (checked) {
      usedForm.setFieldsValue({
        [type]: innerObserveList[type].list,
      });
      onObserveChange(type, innerObserveList[type].list);
    } else {
      usedForm.setFieldsValue({
        [type]: [],
      });
      onObserveChange(type, []);
    }
  });

  const openAddObserve = useStableCallback(() => {
    setModalVisible(true);
  });

  const onAddObserve = useStableCallback(() => {
    setModalName('');
    setModalVisible(false);

    setObserveList({
      ...observeList,
      ['extra' + innerObserveList.length]: {
        name: modalName,
        list: [],
      },
    });
  });

  const onAddCancel = useStableCallback(() => {
    setModalName('');
    setModalVisible(false);
  });

  useEffect(() => {
    getUserProjectList({ userId: userInfo?.userId })
      .then((res) => {
        setProjectInfo(res.list);
      })
      .catch(() => {});
  }, [userInfo?.userId]);

  useEffect(() => {
    setObserveList(observeList);

    Object.keys(observeList).forEach((key) => {
      const sureCheckedList = usedForm.getFieldValue(key);

      usedForm.setFieldsValue({
        [key + '-all']: sureCheckedList?.length === observeList[key].list.length,
      });
    });
  }, [observeList, onObserveChange, usedForm]);

  useEffect(() => {
    if (!projectId) return;
    usedForm.setFieldsValue({ projectId: Number(projectId) });
  })

  return (
    <div className={'road-config ' + className}>
      <Form
        className='road-config-form'
        layout='vertical'
        form={usedForm}
        labelAlign='left'
        onChange={onChange}
      >
        <div className='commom-setting-title'>基本信息</div>
        <div className='commom-setting-content' style={{ marginBottom: 32 }}>
          <Row gutter={64}>
            <Col span={8}>
              <Form.Item
                label='项目ID'
                name='projectId'
                rules={[{ required: true, message: '请选择项目' }]}
              >
                <Select placeholder='请选择项目'>
                  {projectInfo.map((each, index) => (
                    <Option value={each.projectId} key={index}>
                      {`${each.projectSequence}（${each.projectName}）`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='路线名称'
                name='roadName'
                rules={[{ required: true, message: '请输入路线名称' }]}
              >
                <Input placeholder='请输入路线名称' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='有效日期'
                name='dateRange'
                rules={[{ required: true, message: '请选择项目有效日期' }]}
              >
                <RangePicker
                  style={{ width: '100%' }}
                  disabledDate={(currentDate) => {
                    return currentDate && currentDate < moment().subtract(1, 'days').endOf('day');
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={64}>
            <Col span={8}>
              <Form.Item
                label='有效时间段'
                name='timeRange'
                rules={[{ required: true, message: '请选择项目有效时间段' }]}
              >
                <RangePicker picker='time' format='HH:mm' style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='星期'
                name='repetition'
                rules={[{ required: true, message: '请选择星期' }]}
              >
                <Select mode='multiple' maxTagCount='responsive' placeholder='请选择星期'>
                  {Object.entries(weekInfoMap).map(([key, value]) => (
                    <Option value={key} key={key}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='间隔时间'
                name='intervalNum'
                rules={[
                  { required: true, message: '请输入间隔时间' },
                  { pattern: /^[1-9][0-9]*$/g, message: '请输入正整数' },
                ]}
              >
                <Input
                  type='number'
                  placeholder='请输入间隔时间'
                  min={0}
                  addonAfter={
                    <Form.Item name='interval' initialValue={'MONTH'} style={{ margin: 0 }}>
                      <Select>
                        {Object.entries(intervalMap).map(([key, value]) => (
                          <Option key={key} value={key}>
                            {value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className='commom-setting-title'>观察信息</div>
        <div
          className='commom-setting-content road-config-form-horizontal'
          style={{ marginBottom: 32 }}
        >
          <Form.Item
            label='使用人数'
            name='userNum'
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 8 }}
            rules={[{ required: true, message: '请选择人数' }]}
          >
            <Select mode='multiple' maxTagCount='responsive' placeholder='请选择人数'>
              {Object.entries(numberOfUsers).map(([key, value]) => (
                <Option value={key} key={key}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {Object.entries(innerObserveList)?.map(([key, value]) => (
            <Form.Item
              label={value.name}
              key={key}
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 12 }}
            >
              <Form.Item name={key + '-all'} style={{ margin: 0 }} valuePropName='checked'>
                <Checkbox onChange={(event) => onCheckAllChange(key, event.target.checked)}>
                  全选
                </Checkbox>
              </Form.Item>

              <Form.Item name={key} className='road-config-form-checkbox'>
                <Checkbox.Group
                  style={{ width: '100%' }}
                  onChange={(keys) => onObserveChange(key, keys as any)}
                >
                  <Row>
                    {value.list.map((each) => (
                      <Col span={8} key={each}>
                        <Checkbox value={each}>{each}</Checkbox>
                      </Col>
                    ))}
                    <Col span={8} style={{ lineHeight: '32px' }}>
                      <AddNew name='添加其他' onChange={(value) => addObserveList(key, value)} />
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Form.Item>
          ))}
          <Row>
            <Col span={15}>
              <Button
                className='common-button-dashed'
                ghost
                type='primary'
                block
                onClick={openAddObserve}
              >
                <PlusOutlined />
                添加其他观察信息
              </Button>
            </Col>
          </Row>
        </div>
      </Form>

      <Modal
        title='请输入观察信息'
        width={360}
        centered
        maskClosable={false}
        visible={modalVisible}
        onOk={onAddObserve}
        onCancel={onAddCancel}
      >
        <Input value={modalName} onChange={(event) => setModalName(event.target.value)} />
      </Modal>
    </div>
  );
};

export default RoadConfig;
