import { useHistory, useParams } from 'react-router-dom';
import { Button, Result } from 'antd';

const ProjectAddResult = () => {
  const history = useHistory();
  const params = useParams() as any;

  return (
    <Result
      status='success'
      title='项目创建成功'
      subTitle='完善信息，让观察更有效'
      extra={[
        <Button
          type='primary'
          key='set'
          onClick={() => history.replace(`/project/edit/${params.id}`)}
        >
          完善信息
        </Button>,
        <Button
          key='space'
          onClick={() => history.replace(`/project/space/${params.id}`)}
        >
          空间预测
        </Button>,
        <Button key='back' onClick={history.goBack}>
          返回
        </Button>,
      ]}
    />
  );
};

export default ProjectAddResult;
