import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStableCallback } from '../hooks/use-stable-callback';
import { mangLocal } from '../utils/common';

import { Layout, Menu } from 'antd';
const { Sider } = Layout;

const MenuCustom: FC = () => {
  const location = useLocation();

  const userInfo = mangLocal('userInfo');

  const [selectKeys, setSelectKeys] = useState<string[]>([]);

  const onSelect = useStableCallback(({ key }) => {
    setSelectKeys([key]);
  });

  useEffect(() => {
    const split = location.pathname.split('/').filter((each) => !!each);
    const selectKey = split[0];
    setSelectKeys([selectKey]);
  }, [location]);

  return (
    // 系统管理员：项目管理 路线管理 人员管理
    // 超级管理员和管理员：项目管理 路线管理 人员管理 路线检查
    // 观察员：观察任务
    <Menu
      className='flex-x m-t-b'
      mode='inline'
      selectedKeys={selectKeys}
      onSelect={onSelect}
    >
      {userInfo?.role === 1 ? (
        <Menu.Item key='observe'>
          <Link to='/observe'>观察任务</Link>
        </Menu.Item>
      ) : (
        <>
          <Menu.Item key='project'>
            <Link to='/project'>项目管理</Link>
          </Menu.Item>
          <Menu.Item key='road'>
            <Link to='/road'>路线管理</Link>
          </Menu.Item>
          <Menu.Item key='personnel'>
            <Link to='/personnel'>人员管理</Link>
          </Menu.Item>
          {userInfo?.role !== 4 && (
            <Menu.Item key='check'>
              <Link to='/check'>路线检查</Link>
            </Menu.Item>
          )}
        </>
      )}
    </Menu>
  );
};

const SiderCustom: FC = () => {
  return (
    <Sider width={64} className='sider-custom media-hidden'>
      <MenuCustom />
    </Sider>
  );
};

export default SiderCustom;
