import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { set, range } from 'lodash/fp';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { numberOfFloors } from '../../utils/definition';
import {
  getFloorFeaturesList,
  getFloorSynergyList,
  getOtherFeaturesList,
  getPersonalSpaceListA,
  getPersonalSpaceListB,
  getSpecialFeaturesList,
  getStaffList,
  personalSpaceColumn,
  staffColumn,
} from '../../utils/predict';
import { closeConfirmModal, openConfirmModal } from './modal/confirm-modal';

import PredictTemplate1 from './template/index1';
import PredictTemplate2 from './template/index2';
import PredictTemplate3 from './template/index3';
import PredictTemplate4 from './template/index4';
import PredictTemplate5 from './template/index5';
import PredictTemplate6 from './template/index6';
import PredictTemplate7 from './template/index7';

import { getPredictData, savePredictData } from '../../service/space';

import { DatePicker, Form, Select, Modal, Button, Radio, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Option } = Select;

interface Props {
  projectId: string;
  step: number;
  floorNum: number;
  predictDate: any;
  dataType: string;
  autoSaveId: string;
}

const PredictTabpane: FC<Props> = ({
  projectId,
  step,
  floorNum,
  predictDate,
  dataType,
  autoSaveId,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const inInit = useRef<boolean>(false);
  const [oldFloorNum, setOldFloorNum] = useState<number>(1);
  const [radioValue, setRadioValue] = useState<number>(1);
  const [predictDatas, setPredictDatas] = useState<any>({});

  const numberChange = useStableCallback((value) => {
    Modal.confirm({
      centered: true,
      icon: <ExclamationCircleOutlined />,
      title: '注意',
      content: '减少楼层或列数将会丢失数据，请谨慎操作！',
      maskClosable: false,
      okText: '确定修改',
      cancelText: '取消',
      onOk() {
        setOldFloorNum(value);
        setRadioValue(1);
      },
      onCancel() {
        form.setFieldsValue({ floorNum: oldFloorNum });
      },
    });
  });

  const radioChange = useStableCallback((e) => {
    setRadioValue(e.target.value);
  });

  const beforeLeave = useStableCallback((url: string) => {
    openConfirmModal({
      visible: true,
      onLeave: () => {
        closeConfirmModal();
        history.replace(`${url}/${projectId}`);
      },
      onSave: async () => {
        await onSave();
        closeConfirmModal();
        history.replace(`${url}/${projectId}`);
      },
      onCancel: closeConfirmModal,
    });
  });

  const onSave = useStableCallback(async () => {
    try {
      const formData = form.getFieldsValue();
      let params: any = { ...predictDatas };
      params.projectId = projectId;
      params.dataType = dataType;
      params.floorNum = formData.floorNum;
      params.predictDate = moment(formData.predictDate).format('YYYY-MM');
      if (!params.floorNum) return;
      if (params.step1DataList.length !== params.floorNum) {
        hanldeFloorData(params);
      }
      await savePredictData(params);
      message.success('保存成功');
    } catch (err) {}
  });

  const promptSave = useStableCallback(() => {
    Modal.confirm({
      centered: true,
      icon: <ExclamationCircleOutlined />,
      title: '注意核对人数',
      content:
        '请将【人员数量】【个人工作方式分配人数】【个人空间分配人数】保持一致。',
      maskClosable: false,
      okText: '仍要保存',
      cancelText: '取消保存',
      onOk: async () => {
        await onSave();
      },
    });
  });

  const changeTemplate1 = useStableCallback((value1) => {
    setPredictDatas((prev: any) =>
      set(['step1DataList', radioValue - 1], value1, prev)
    );
  });

  const changeTemplate2A = useStableCallback((value2A) => {
    setPredictDatas((prev: any) =>
      set(['step2ADataList', radioValue - 1], value2A, prev)
    );
  });

  const changeTemplate2B = useStableCallback((value2B) => {
    setPredictDatas((prev: any) =>
      set(['step2BDataList', radioValue - 1], value2B, prev)
    );
  });

  const changeTemplate3 = useStableCallback((value3) => {
    setPredictDatas((prev: any) =>
      set(['step3DataList', radioValue - 1], value3, prev)
    );
  });

  const changeTemplate4 = useStableCallback((value4) => {
    setPredictDatas((prev: any) =>
      set(['step4DataList', radioValue - 1], value4, prev)
    );
  });

  const changeTemplate5 = useStableCallback((value5) => {
    setPredictDatas((prev: any) =>
      set(['step5DataList', radioValue - 1], value5, prev)
    );
  });

  const changeTemplate6 = useStableCallback((value6) => {
    setPredictDatas((prev: any) =>
      set(['step6DataList', radioValue - 1], value6, prev)
    );
  });

  const changeTemplate7 = useStableCallback((value7) => {
    setPredictDatas((prev: any) =>
      set(['step7DataList', radioValue - 1], value7, prev)
    );
  });

  const hanldeFloorData = (params: any) => {
    params.step1DataList = range(0, params.floorNum).map((index) => {
      if (params.step1DataList[index]) {
        return params.step1DataList[index];
      }
      return {
        columnList: staffColumn,
        tableData: getStaffList(staffColumn, []),
      };
    });
    params.step2ADataList = range(0, params.floorNum).map((index) => {
      if (params.step2ADataList[index]) {
        return params.step2ADataList[index];
      }
      return {
        columnList: personalSpaceColumn,
        tableData: getPersonalSpaceListA(personalSpaceColumn, []),
      };
    });
    params.step2BDataList = range(0, params.floorNum).map((index) => {
      if (params.step2BDataList[index]) {
        return params.step2BDataList[index];
      }
      return {
        tableData: getPersonalSpaceListB(personalSpaceColumn, [], []),
      };
    });
    params.step3DataList = range(0, params.floorNum).map((index) => {
      if (params.step3DataList[index]) {
        return params.step3DataList[index];
      }
      return {
        tableData: getFloorSynergyList(),
      };
    });
    params.step4DataList = range(0, params.floorNum).map((index) => {
      if (params.step4DataList[index]) {
        return params.step4DataList[index];
      }
      return {
        tableData: getFloorFeaturesList(),
      };
    });
    params.step5DataList = range(0, params.floorNum).map((index) => {
      if (params.step5DataList[index]) {
        return params.step5DataList[index];
      }
      return {
        tableData: getOtherFeaturesList(),
      };
    });
    params.step6DataList = range(0, params.floorNum).map((index) => {
      if (params.step6DataList[index]) {
        return params.step6DataList[index];
      }
      return {
        tableData: getSpecialFeaturesList(),
      };
    });
    params.step7DataList = range(0, params.floorNum).map((index) => {
      if (params.step7DataList[index]) {
        return params.step7DataList[index];
      }
      return {
        usedArea: 0,
        aisleArea: 0,
        area: 0,
        leaseArea: 0,
        aislePercent: 0,
        workNum: 0,
        seatNum: 0,
      };
    });
  };

  useEffect(() => {
    if (!inInit.current) {
      inInit.current = true;
      return;
    }
    onSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSaveId]);

  useEffect(() => {
    setRadioValue(1);
    getPredictData(projectId, dataType)
      .then((res) => {
        res.floorNum = Number(res.floorNum || floorNum || 1);
        res.predictDate = res.predictDate || predictDate || moment();
        hanldeFloorData(res);
        form.setFieldsValue({
          floorNum: res.floorNum,
          predictDate: moment(res.predictDate),
        });
        setOldFloorNum(res.floorNum);
        setPredictDatas(res);

        if (step) {
          document
            .getElementById(`step${step}`)
            ?.scrollIntoView({ behavior: 'smooth' });
        }
      })
      .catch(() => {});
  }, [dataType, floorNum, form, predictDate, projectId, step]);

  return (
    <div className='space-predict-edit-template'>
      <div className='flex-x m-s-between a-s-center'>
        <Form
          className='space-predict-edit-extra'
          layout={'inline'}
          form={form}
        >
          <Form.Item label='日期' name='predictDate'>
            <DatePicker
              className='picker'
              format={'YYYY-MM'}
              placeholder={'年/月'}
              picker='month'
              allowClear={false}
            />
          </Form.Item>
          <Form.Item label='楼层数量' name='floorNum'>
            <Select
              className='select'
              placeholder={'请选择'}
              onChange={numberChange}
            >
              {numberOfFloors.map((value, index) => (
                <Option value={value} key={index}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <div>
          <Radio.Group
            value={radioValue}
            onChange={radioChange}
            buttonStyle='solid'
          >
            {1 <= oldFloorNum && <Radio.Button value={1}>楼层1</Radio.Button>}
            {2 <= oldFloorNum && <Radio.Button value={2}>楼层2</Radio.Button>}
            {3 <= oldFloorNum && <Radio.Button value={3}>楼层3</Radio.Button>}
          </Radio.Group>
        </div>
        <div>
          <Button onClick={() => beforeLeave('/project/space')}>
            返回列表
          </Button>
          <Button onClick={() => beforeLeave('/project/space/total')}>
            查看总计
          </Button>
          <Button type='primary' onClick={() => promptSave()}>
            保存
          </Button>
        </div>
      </div>

      <div className='space-predict-main'>
        <PredictTemplate1
          value={predictDatas.step1DataList?.[radioValue - 1]}
          onChange={changeTemplate1}
        />
        <PredictTemplate2
          valueA={predictDatas.step2ADataList?.[radioValue - 1]}
          valueB={predictDatas.step2BDataList?.[radioValue - 1]}
          onChangeA={changeTemplate2A}
          onChangeB={changeTemplate2B}
        />
        <PredictTemplate3
          value={predictDatas.step3DataList?.[radioValue - 1]}
          personalValue={predictDatas.step1DataList?.[radioValue - 1]}
          onChange={changeTemplate3}
        />
        <PredictTemplate4
          value={predictDatas.step4DataList?.[radioValue - 1]}
          onChange={changeTemplate4}
        />
        <PredictTemplate5
          value={predictDatas.step5DataList?.[radioValue - 1]}
          onChange={changeTemplate5}
        />
        <PredictTemplate6
          value={predictDatas.step6DataList?.[radioValue - 1]}
          onChange={changeTemplate6}
        />
        <PredictTemplate7
          value={predictDatas.step7DataList?.[radioValue - 1]}
          template2Value={predictDatas.step2BDataList?.[radioValue - 1]}
          template3Value={predictDatas.step3DataList?.[radioValue - 1]}
          template4Value={predictDatas.step4DataList?.[radioValue - 1]}
          template5Value={predictDatas.step5DataList?.[radioValue - 1]}
          template6Value={predictDatas.step6DataList?.[radioValue - 1]}
          onChange={changeTemplate7}
        />
      </div>
    </div>
  );
};

export default PredictTabpane;
