import React, { FC } from 'react';
import { useStableCallback } from '../../hooks/use-stable-callback';

import { Form, Input, Button, Row, Col } from 'antd';

interface Props {
  onReset: (value: { [key: string]: any }) => void;
  onFinish: (value: { [key: string]: any }) => void;
}

const RoadObserveQuery: FC<Props> = ({ onReset, onFinish }) => {
  const [form] = Form.useForm();

  const onInnerReset = useStableCallback(() => {
    form.resetFields();
    onReset(form.getFieldsValue(true));
  });

  return (
    <div className='page-custom common-query'>
      <Form
        form={form}
        name='observe-query'
        layout={'inline'}
        onFinish={onFinish}
      >
        <Row>
          <Col
            xs={{ span: 22, offset: 2 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
          >
            <Form.Item label='路线ID' name='roadSequence'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 22, offset: 2 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
          >
            <Form.Item label='路线名称' name='roadName'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 22, offset: 2 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
          >
            <Form.Item label='项目名称' name='projectName'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 22, offset: 2 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
          >
            <Form.Item>
              <Button htmlType='button' onClick={onInnerReset}>
                重置
              </Button>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default RoadObserveQuery;
