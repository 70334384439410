import column from '../../../assets/images/column.png';

import React, { FC, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { sum } from 'lodash/fp';
import { EditableRow } from '../../common/editable/editable-row';
import {
  DataType,
  EditableCell,
  InputCell,
} from '../../common/editable/editable-cell';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import {
  closeColumnConfigureModal2,
  openColumnConfigureModal2,
} from '../modal/column-configure2';
import {
  personalSpaceColumn,
  getPersonalSpaceListA,
  getPersonalSpaceListB,
} from '../../../utils/predict';

import { Button, Card, Table, Tooltip } from 'antd';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

interface Props {
  valueA?: any;
  valueB?: any;
  onChangeA?: (newValue: any) => void;
  onChangeB?: (newValue: any) => void;
}

const PredictTemplate2: FC<Props> = ({
  valueA,
  valueB,
  onChangeA,
  onChangeB,
}) => {
  const [columnListA, setColumnListA] = useState<string[]>([]);
  const [dataSourceA, setDataSourceA] = useState<DataType[]>([]);
  const [dataSourceB, setDataSourceB] = useState<DataType[]>([]);

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    []
  );

  const columnConfigure = useStableCallback(() => {
    openColumnConfigureModal2({
      visible: true,
      columnList: columnListA,
      onCancel: closeColumnConfigureModal2,
      onConfirm: (values) => {
        const dataA = getPersonalSpaceListA(values, dataSourceA);
        const dataB = getPersonalSpaceListB(values, dataSourceA, dataSourceB);
        setColumnListA(values);
        setDataSourceA(dataA);
        setDataSourceB(dataB);
        onChangeA?.({ tableData: dataA, columnList: values });
        onChangeB?.({ tableData: dataB });
      },
    });
  });

  const handleValueChangeA = useStableCallback((newValue: DataType[]) => {
    let dataB = getPersonalSpaceListB(columnListA, newValue, dataSourceB);
    dataB = dataB.map((each, index) => {
      return {
        ...each,
        data: each.data.map((each1, index1) => {
          if (index1 !== 1) return each1;
          return sum(newValue[index].data.slice(1));
        }),
      };
    });
    setDataSourceA(newValue);
    setDataSourceB(dataB);
    onChangeA?.({ tableData: newValue, columnList: columnListA });
    onChangeB?.({ tableData: dataB });
  });

  const handleDeleteA = useStableCallback((id: React.Key) => {
    handleValueChangeA(dataSourceA.filter((item) => item.id !== id));
  });

  const handleAddA = useStableCallback(() => {
    const newData: DataType = {
      id: uuid(),
      data: ['类型名称', ...Array(columnListA.length - 1).fill(0)],
    };
    handleValueChangeA([...dataSourceA, newData]);
  });

  const handleChangeA = useStableCallback(
    (row: DataType, changedKey: keyof DataType) => {
      handleValueChangeA(
        dataSourceA.map((each) => {
          if (row.id === each.id) {
            return {
              ...each,
              ...row,
            };
          }
          return each;
        })
      );
    }
  );

  const handleValueChangeB = useStableCallback((newValue: DataType[]) => {
    setDataSourceB(newValue);
    onChangeB?.({ tableData: newValue });
  });

  const handleChangeB = useStableCallback(
    (row: DataType, changedKey: keyof DataType) => {
      handleValueChangeB(
        dataSourceB.map((each) => {
          if (row.id === each.id) {
            return {
              ...each,
              ...row,
            };
          }
          return each;
        })
      );
    }
  );

  const columnsA = React.useMemo(() => {
    let tablesA = columnListA.map((item, index) => {
      return {
        title: item,
        dataIndex: 'data|' + index,
        key: 'data|' + index,
        width: 200,
        render: (text: string, record: any) => {
          return record.data[index];
        },
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'input',
          title: item,
          dataIndex: 'data|' + index,
          handleChange: handleChangeA,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell
                name={name}
                onChange={onChange}
                isInputNumber={index !== 0}
                rules={[{ required: true, message: '不可为空' }]}
              >
                {originCell}
              </InputCell>
            );
          },
        }),
      };
    });

    return [
      ...tablesA,
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        width: 100,
        render: (text: any, record: any) => (
          <>
            <Button type='link' onClick={() => handleDeleteA(record?.id)}>
              删除
            </Button>
          </>
        ),
      },
    ];
  }, [columnListA, handleChangeA, handleDeleteA]);

  const columnsB = React.useMemo(() => {
    let tablesB = [columnListA[0], '人数', '空间数量', '单面积(m²)'].map(
      (item, index) => {
        return index === 0
          ? {
              title: item,
              dataIndex: 'data|' + index,
              key: 'data|' + index,
              width: 200,
              render: (text: string, record: any) => {
                return record.data[index];
              },
            }
          : {
              title: item,
              dataIndex: 'data|' + index,
              key: 'data|' + index,
              width: 200,
              render: (text: string, record: any) => {
                return record.data[index];
              },
              onCell: (record: DataType): any => ({
                record,
                editable: true,
                editType: 'input',
                title: item,
                dataIndex: 'data|' + index,
                handleChange: handleChangeB,
                renderFormCell: (
                  name: keyof DataType,
                  onChange: () => void,
                  { originCell }: any
                ) => {
                  return (
                    <InputCell
                      name={name}
                      onChange={onChange}
                      isInputNumber={index !== 0}
                      numberPrecision={item === '单面积(m²)' ? 2 : 0}
                      rules={[{ required: true, message: '不可为空' }]}
                    >
                      {originCell}
                    </InputCell>
                  );
                },
              }),
            };
      }
    );

    return [
      ...tablesB,
      {
        title: (
          <div>
            总面积(m²)
            <Tooltip placement='top' title={'空间数量*单面积'}>
              <InfoCircleOutlined className='title-tips' />
            </Tooltip>
          </div>
        ),
        dataIndex: 'totalArea',
        key: 'totalArea',
        width: 200,
        render: (text: string, record: any) => {
          return (record.data[2] * record.data[3]).toFixed(2);
        },
      },
      {
        title: (
          <div>
            共享比例
            <Tooltip placement='top' title={'人数/空间数量'}>
              <InfoCircleOutlined className='title-tips' />
            </Tooltip>
          </div>
        ),
        dataIndex: 'shareRatio',
        key: 'shareRatio',
        width: 200,
        render: (text: string, record: any) => {
          return (
            !record.data[2] ? 0 : record.data[1] / record.data[2]
          ).toFixed(2);
        },
      },
    ];
  }, [columnListA, handleChangeB]);

  useEffect(() => {
    if (valueA) {
      setDataSourceA(valueA?.tableData);
      setColumnListA(valueA?.columnList);
    } else {
      const dataA = getPersonalSpaceListA(personalSpaceColumn, []);
      setColumnListA(personalSpaceColumn);
      setDataSourceA(dataA);
      onChangeA?.({
        tableData: dataA,
        columnList: personalSpaceColumn,
      });
    }
  }, [onChangeA, valueA]);

  useEffect(() => {
    if (valueB) {
      setDataSourceB(valueB?.tableData);
    } else {
      const dataB = getPersonalSpaceListB(personalSpaceColumn, [], []);
      setDataSourceB(dataB);
      onChangeB?.({ tableData: dataB });
    }
  }, [onChangeB, valueB]);

  const extraButton = useMemo(() => {
    return (
      <>
        <img className='column-icon' src={column} alt='column' />
        <Button type='link' onClick={columnConfigure}>
          列名配置
        </Button>
      </>
    );
  }, [columnConfigure]);

  return (
    <Card
      id='step2'
      className='space-predict-edit-table'
      type='inner'
      title='个人空间类型'
      extra={extraButton}
    >
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        components={components}
        columns={columnsA}
        dataSource={dataSourceA}
        pagination={false}
        summary={() => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>小计</Table.Summary.Cell>

              {[...columnListA].slice(1).map((each, index) => {
                return (
                  <Table.Summary.Cell index={index + 1}>
                    {sum(dataSourceA.map((row) => row.data[index + 1]))}
                  </Table.Summary.Cell>
                );
              })}
            </Table.Summary.Row>
          );
        }}
      />
      <Button
        block
        type='dashed'
        icon={<PlusOutlined />}
        style={{ margin: '16px 0 32px' }}
        onClick={handleAddA}
      >
        新增
      </Button>

      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        components={components}
        columns={columnsB}
        dataSource={dataSourceB}
        pagination={false}
        summary={() => {
          const people = sum(dataSourceB.map((row) => row.data[1]));
          const space = sum(dataSourceB.map((row) => row.data[2]));
          const area = sum(dataSourceB.map((row) => row.data[2] * row.data[3]));
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>小计</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>{people}</Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={2}>
                {space}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                {area.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                {(!space ? 0 : people / space).toFixed(2)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Card>
  );
};

export default PredictTemplate2;
