import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { mangLocal } from '../../../utils/common';
import { closeRoadObserversSetModal, openRoadObserversSetModal } from './observers-set';
import usePersonnelTable from '../../personnel/use-personnel-table';

import { roadRemoveUser } from '../../../service/road';
import { getRoadObserver } from '../../../service/personnel';

import { Button, Modal, message } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

const RoadManageEdit: FC = () => {
  const params = useParams() as any;

  const userInfo = mangLocal('userInfo');

  const renderOpeartor = useStableCallback((record: any) => {
    return (
      <Button type='link' disabled={record.role >= userInfo?.role} onClick={() => removeManage(record)}>
        移除
      </Button>
    );
  });

  const search = useMemo(() => ({ roadId: params.id }), [params.id]);

  const { table, getList } = usePersonnelTable({
    search,
    renderOpeartor,
    getUserList: getRoadObserver,
  });

  const removeManage = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定移除观察员【${record.accountName}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          await roadRemoveUser(params.id, [record.userId]);
          message.success('移除成功');
          getList(1);
        } catch (err) {}
      },
    });
  });

  const addManagers = useStableCallback(() => {
    openRoadObserversSetModal({
      visible: true,
      roadId: params.id,
      onCancel,
      onConfirm,
    });
  });

  const onConfirm = useStableCallback(() => {
    closeRoadObserversSetModal();
    getList(1);
  });

  const onCancel = useStableCallback(() => {
    closeRoadObserversSetModal();
  });

  return (
    <div className='road-manage'>
      <div className='commom-setting-title'>观察员列表</div>
      
      <div className='commom-setting-content'>{table}</div>

      {userInfo?.role >= 2 && (
        <div style={{ margin: '0 32px 16px' }}>
          <Button
            ghost
            type='primary'
            block
            className='common-button-dashed'
            onClick={addManagers}
          >
            <PlusOutlined />
            添加观察员
          </Button>
        </div>
      )}
    </div>
  );
};

export default RoadManageEdit;
