import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { mangLocal } from '../../utils/common';
import HelpFeedback from './help-feedback';

import { signIn, UserInfo } from '../../service/user';

import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const LoginTab: FC = () => {
  const history = useHistory();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [isHelpVisible, setIsHelpVisible] = useState(false);

  const onChange = useStableCallback(() => {
    setIsHelpVisible(false);
  });

  const onFinish = useStableCallback(async (values: UserInfo) => {
    setLoading(true);
    try {
      const res = await signIn(values);
      mangLocal('userInfo', res.data);
      message.success('登录成功');
      history.push('/');
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const getUserInfo = useStableCallback(() => {
    const userInfo = mangLocal('userInfo');
    if (userInfo !== undefined && userInfo != null) {
      mangLocal('userInfo', userInfo);
      history.push('/');
    }
  });

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  return (
    <div>
      <Form name='normal_login' className='login-form' form={form} onFinish={onFinish}>
        <Form.Item
          name='accountName'
          rules={[{ required: true, message: '请输入手机号/邮箱/账户名' }]}
        >
          <Input
            prefix={<UserOutlined className='login-form-icon' />}
            placeholder='手机号/邮箱/账户名'
            allowClear
          />
        </Form.Item>
        <Form.Item name='password' rules={[{ required: true, message: '请输入密码' }]}>
          <Input
            prefix={<LockOutlined className='login-form-icon' />}
            type='password'
            placeholder='密码'
            allowClear
          />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' className='login-form-button' loading={loading}>
            登录
          </Button>
        </Form.Item>
        <Form.Item>
          <p className='login-form-forget' onClick={() => setIsHelpVisible(true)}>忘记密码?</p>
        </Form.Item>
      </Form>
      {isHelpVisible && <HelpFeedback isHelpVisible={isHelpVisible} onChange={onChange} />}
    </div>
  );
};

export default LoginTab;
