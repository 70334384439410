import { v4 as uuid } from 'uuid';

// 空间预测初始化数据

// 人员情况表头
export const staffColumn: string[] = [
  '部门',
  'FTE(全职人员)',
  '实习生',
  '非全职人员',
  '其他人员',
];

// 人员情况
export const getStaffList = (header: string[], oldValue: any[]) => {
  if (!oldValue || !oldValue.length) {
    return [
      '管理层',
      '财务部',
      '人事部',
      '采购部',
      '销售部',
      '市场部',
      '设施管理',
    ].map((each) => {
      return { id: uuid(), data: [each, ...Array(header.length - 1).fill(0)] };
    });
  }

  return oldValue.map((each) => {
    const newRowValue = { ...each, data: each.data.slice(0, header.length) };
    if (header.length > each.data.length) {
      newRowValue.data.push(...Array(header.length - each.data.length).fill(0));
    }
    return newRowValue;
  });
};

// 个人空间类型表头
export const personalSpaceColumn: string[] = [
  '个人空间类型',
  '保密级别高+周平均每天在工位时长>6小时',
  '工位时长>6小时',
  '工位时长6-4小时',
  '工位时长<4小时',
  '外勤人员',
];

// 个人空间类型
export const getPersonalSpaceListA = (header: string[], oldValue: any[]) => {
  if (!oldValue || !oldValue.length) {
    return ['个人办公室', '固定工位', '移动工位', '专注工位'].map((each) => {
      return { id: uuid(), data: [each, ...Array(header.length - 1).fill(0)] };
    });
  }

  return oldValue.map((each) => {
    const newRowValue = { ...each, data: each.data.slice(0, header.length) };
    if (header.length > each.data.length) {
      newRowValue.data.push(...Array(header.length - each.data.length).fill(0));
    }
    return newRowValue;
  });
};

export const getPersonalSpaceListB = (
  headerA: string[],
  oldValueA: any[],
  oldValueB: any[]
) => {
  const headerB = [headerA[0], '人数', '空间数量', '单面积(m²)'];
  const listA = getPersonalSpaceListA(headerA, oldValueA);

  if (!oldValueB || !oldValueB.length) {
    return listA.map((each) => {
      return {
        id: uuid(),
        data: [each.data[0], ...Array(headerB.length - 1).fill(0)],
      };
    });
  }

  const newValueB = oldValueB
    .filter((each, index) => !!listA[index])
    .map((each, index) => {
      return {
        id: each.id,
        data: [listA[index].data[0], ...each.data.slice(1)],
      };
    });

  if (newValueB.length < listA.length) {
    const temp = [];
    for (let i = newValueB.length; i < listA.length; i++) {
      temp.push({
        id: uuid(),
        data: [listA[i].data[0], ...Array(headerB.length - 1).fill(0)],
      });
    }
    newValueB.push(...temp);
    return newValueB;
  }

  return newValueB;
};

// 楼层协同空间表头
export const floorSynergyColumn: string[] = [
  '楼层协同空间',
  '空间数量',
  '座位数量',
  '单面积(m²)',
];

// 楼层协同空间
export const getFloorSynergyList = () => {
  return [
    '电话间',
    '小会议室（4-6人）',
    '中型会议室（8-12人）',
    '大型会议室（15+）',
    '超大型会议室（30+）',
    '培训教室（30+）',
    '对外大型会议室',
    '对外中型会议室',
    '对外小型会议室',
  ].map((each) => {
    return {
      id: uuid(),
      data: [each, ...Array(floorSynergyColumn.length - 1).fill(0)],
    };
  });
};

// 楼层功能空间表头
export const floorFeaturesColumn: string[] = [
  '楼层功能空间',
  '分配说明',
  '空间数量',
  '座位数量',
  '单面积(m²)',
];

// 楼层功能空间
export const getFloorFeaturesList = () => {
  return [
    ['开放会议空间', '结合封闭会议统筹，需配置电视等工具设备'],
    ['开放讨论区', '接所有会议需求，无需设备，随时讨论'],
    ['茶水间', '每个楼层1个'],
    ['打印间', '每个楼层2个'],
    ['IT机房', '总机房1个'],
    ['储藏室', '按需配置'],
    ['个人储藏壁柜', '每个人1个'],
    ['部门文件柜', '按需配置'],
    ['衣柜', '按需配置'],
    ['母婴室', '每层楼4处'],
    ['更衣间', '每层楼1个'],
    ['休息室', '每层楼2个（男女）'],
  ].map((each) => {
    return {
      id: uuid(),
      data: [...each, ...Array(floorFeaturesColumn.length - 2).fill(0)],
    };
  });
};

// 其他功能空间表头
export const otherFeaturesColumn: string[] = [
  '其他功能空间',
  '分配说明',
  '空间数量',
  '座位数量',
  '单面积(m²)',
];

// 其他功能空间
export const getOtherFeaturesList = () => {
  return [
    ['总前台', '楼层1个'],
    ['收发室', '整合1个'],
    ['安保房间', '根据部门需求'],
  ].map((each) => {
    return {
      id: uuid(),
      data: [...each, ...Array(otherFeaturesColumn.length - 2).fill(0)],
    };
  });
};

// 特别功能空间表头
export const specialFeaturesColumn: string[] = [
  '特别功能空间',
  '分配说明',
  '空间数量',
  '座位数量',
  '单面积(m²)',
];

// 特别功能空间
export const getSpecialFeaturesList = () => {
  return [['其他', '根据部门需求']].map((each) => {
    return {
      id: uuid(),
      data: [...each, ...Array(otherFeaturesColumn.length - 2).fill(0)],
    };
  });
};
