import './error.scss';
import img from '../../assets/images/500.png';
import icon from '../../assets/images/500-icon.png';

import { Button } from 'antd';

import { FC } from 'react';
import { useHistory } from 'react-router-dom';

const NetworkError: FC = () => {
  const history = useHistory();

  return (
    <div className='error flex-x'>
      <div className='error-img media-hidden'>
        <img src={img} alt='img' />
      </div>

      <div className='error-info'>
        <div className='info-title flex-x m-s-start'>
          <div>抱歉，服务器连接中断了</div>
          <img className='title-icon' src={icon} alt='icon' />
        </div>
        <div className='info-desc'>请检查您的网络配置</div>
        <div className='info-desc'>服务器可能在维护中，请您稍后再尝试访问</div>
        <Button
          className='info-btn'
          type='primary'
          onClick={() => history.goBack()}
        >
          返 回
        </Button>
      </div>
    </div>
  );
};

export default NetworkError;
