import React, { useState } from 'react';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { filterParams } from '../../utils/common';

import RoadListQuery from './query';
import RoadListTable from './table';

const RoadManagement = () => {
  const [searchForm, setSearchForm] = useState<{ [key: string]: any }>({});

  const onSearchFinish = useStableCallback((values) => {
    setSearchForm(filterParams(values));
  });
  const onSearchReset = useStableCallback((values) => {
    setSearchForm(filterParams(values));
  });

  return (
    <>
      <div className='common-title'>路线管理</div>
      <RoadListQuery onFinish={onSearchFinish} onReset={onSearchReset} />
      <RoadListTable searchForm={searchForm} />
    </>
  );
};

export default RoadManagement;
