import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { mangLocal } from '../../utils/common';

import { modifyPassword } from '../../service/user';

import { Form, Input, Button, message } from 'antd';

const ModifyPassword: FC = () => {
  const history = useHistory();

  const userInfo = mangLocal('userInfo');

  const [form] = Form.useForm();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const onFinish = useStableCallback(async (values: any) => {
    setSubmitLoading(true);
    try {
      await modifyPassword(values);
      message.success('密码修改成功，请重新登录');
      setTimeout(() => {
        sessionStorage.clear();
        history.push('/login');
      }, 500);
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  return (
    <div className='page-custom common-details'>
      <div className='details-title'>修改密码</div>
      <Form
        name='modify-password'
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        form={form}
        onFinish={onFinish}
        autoComplete='off'
      >
        <Form.Item label='账户名'>
          <span>{userInfo?.accountName}</span>
        </Form.Item>
        <Form.Item
          label='原密码'
          name='oldPassword'
          rules={[{ required: true, message: '请输入原密码' }]}
        >
          <Input type='password' placeholder='请输入原密码' allowClear />
        </Form.Item>
        <Form.Item
          label='新密码'
          name='newPassword'
          rules={[{ required: true, message: '请输入新密码' }]}
        >
          <Input type='password' placeholder='请输入新密码' allowClear />
        </Form.Item>
        <Form.Item
          label='确认新密码'
          name='confirmNewPassword'
          dependencies={['newPassword']}
          rules={[
            { required: true, message: '请再次输入新密码' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('您输入的两个密码不匹配'));
              },
            }),
          ]}
        >
          <Input type='password' placeholder='请再次输入新密码' allowClear />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type='primary' htmlType='submit' loading={submitLoading}>
            确定
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ModifyPassword;
