/* eslint-disable eqeqeq */
import checkEmpty from '../../assets/images/check-empty.png';

import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { mangLocal } from '../../utils/common';
import { getObserveList, ObserveList } from '../../service/observe';

import { Col, Row, Card, Button, Spin, Modal, Form, message, Select } from 'antd';
const { Meta } = Card;
const { Option } = Select;

interface Props {
  searchForm?: { [key: string]: any };
}

const RoadObserveTable: FC<Props> = ({ searchForm }) => {
  const history = useHistory();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<ObserveList[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [observeItem, setObserveItem] = useState<ObserveList | null>(null);

  const getList = useStableCallback(async () => {
    setLoading(true);
    try {
      let params = { ...searchForm };
      const { data } = await getObserveList(params);
      setList(data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const startObservation = (item: ObserveList) => {
    const localObserve = mangLocal('observeName');
    if (
      localObserve &&
      list.some((each) => each.roadId == localObserve.roadId) &&
      (localObserve.projectId != item.projectId || localObserve.roadId != item.roadId)
    ) {
      Modal.warning({
        title: `${localObserve.roadName}的观察数据未保存，请前往保存以免数据丢失`,
        centered: true,
      });
      return;
    }

    setObserveItem(item);

    if (item.suspendFlag) {
      history.push(`/observe/start/${item.projectId}/${item.roadId}`, {
        version: item.suspendVersion,
        roadName: item.roadName,
      });
      return;
    }

    if (!item.observeTime?.length) {
      message.warning('当前不在该路线观察时间内');
      return;
    }

    setModalVisible(true);
  };

  const onObserveOk = useStableCallback(async () => {
    try {
      const values = await form.validateFields();
      history.push(`/observe/start/${observeItem?.projectId}/${observeItem?.roadId}`, {
        version: values.suspendVersion,
        roadName: observeItem?.roadName,
      });
    } catch (err) {
    } finally {
      form.resetFields();
    }
  });

  useEffect(() => {
    getList();
  }, [getList, searchForm]);

  return (
    <Spin spinning={loading}>
      {list && list.length ? (
        <div style={{ margin: '24px' }}>
          <Row gutter={[24, 24]}>
            {list.map((each) => (
              <Col xs={24} sm={12} md={8} lg={6} xl={6} key={each.roadId}>
                <Card
                  hoverable
                  actions={[
                    <Button type='link' key='check'>
                      {each.suspendFlag ? '继续观察' : '开始观察'}
                    </Button>,
                  ]}
                  onClick={() => startObservation(each)}
                >
                  <Meta title={each.roadName} description={`项目名：${each.projectName}`} />
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <div className='flex-x m-t-b' style={{ color: '#ACACB3', margin: '64px 24px' }}>
          <img src={checkEmpty} style={{ width: '120px', height: '120px' }} alt='' />
          <span>如需观察任务，</span>
          <span>请联系超级管理员开始路线</span>
        </div>
      )}

      <Modal
        title='设置观察时间'
        centered
        width={360}
        maskClosable={false}
        visible={modalVisible}
        onOk={onObserveOk}
        onCancel={() => setModalVisible(false)}
      >
        <Form form={form}>
          <Form.Item
            label='观察时间'
            name='suspendVersion'
            rules={[{ required: true, message: '请选择本次观察时间' }]}
          >
            <Select style={{ width: '100%' }}>
              {observeItem?.observeTime?.map((each, index) => (
                <Option value={each.version} key={index}>
                  {each.observeTime}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  );
};

export default RoadObserveTable;
