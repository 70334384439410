import { Switch, Route, Redirect } from 'react-router-dom';

import App from './App';
import Login from './components/user/login';
import Home from './components/home/home';

export const ViewRouter = () => {
  return (
    <Switch>
      <Route path='/login'>
        <Login />
      </Route>
      <Route path='/home'>
        <Home />
      </Route>
      <Route path='/'>
        <App />
      </Route>
      <Redirect to='/' />
    </Switch>
  );
};
