import { preFetch } from './fetch';
import { v4 as uuid } from 'uuid';

// 查询项目是否填写前两步以及是否开通未来权限
export const getProjectPredictStatus = async (
  projectId: string
): Promise<{ predictAuth: number; hasPredictData: number }> => {
  const res = await preFetch('/predict/getProjectPredictStatus', {
    method: 'POST',
    body: { projectId },
  });

  return res.data;
};

// 空间预测添加、移除未来权限
export const updatePredictAuth = async (projectId: string, status: number) => {
  const res = await preFetch('/predict/updatePredictAuth', {
    method: 'POST',
    body: { projectId, status },
  });

  return res;
};

// 保存空间预测数据
export const savePredictData = async (body: any) => {
  let data = { ...body };
  data.step1DataList = handleSavePredictData(data.step1DataList);
  data.step2ADataList = handleSavePredictData(data.step2ADataList);
  data.step2BDataList = handleSavePredictData(data.step2BDataList);
  data.step3DataList = handleSavePredictData(data.step3DataList);
  data.step4DataList = handleSavePredictData(data.step4DataList);
  data.step5DataList = handleSavePredictData(data.step5DataList);
  data.step6DataList = handleSavePredictData(data.step6DataList);

  const res = await preFetch('/predict/savePredictData', {
    method: 'POST',
    body: data,
  });

  return res;
};

// 获取空间预测数据
export const getPredictData = async (projectId: string, dataType: string) => {
  const res = await preFetch('/predict/getPredictData', {
    method: 'POST',
    body: { projectId, dataType },
  });

  if (!res.data) return {};

  const data = res.data;
  data.step1DataList = handleGetPredictData(data.step1DataList, 1);
  data.step2ADataList = handleGetPredictData(data.step2ADataList, 1);
  data.step2BDataList = handleGetPredictData(data.step2BDataList, 1);
  data.step3DataList = handleGetPredictData(data.step3DataList, 1);
  data.step4DataList = handleGetPredictData(data.step4DataList, 2);
  data.step5DataList = handleGetPredictData(data.step5DataList, 2);
  data.step6DataList = handleGetPredictData(data.step6DataList, 2);
  return data;
};

// 获取总计页面数据
export const getTotalData = async (projectId: string, dataType: string) => {
  const res = await preFetch('/predict/getTotalData', {
    method: 'POST',
    body: { projectId, dataType },
  });

  if (!res.data) return {};

  const data = res.data;
  data.step1DataList = handleGetPredictData(data.step1DataList, 1);
  data.step2ADataList = handleGetPredictData(data.step2ADataList, 1);
  data.step2BDataList = handleGetPredictData(data.step2BDataList, 1);
  data.step3DataList = handleGetPredictData(data.step3DataList, 1);
  data.step4DataList = handleGetPredictData(data.step4DataList, 2);
  data.step5DataList = handleGetPredictData(data.step5DataList, 2);
  data.step6DataList = handleGetPredictData(data.step6DataList, 2);
  return data;
};

export const handleSavePredictData = (data: any[]) => {
  return data.map((each: any) => {
    return {
      ...each,
      tableData: each.tableData.map((item: any) => item.data),
    };
  });
};

export const handleGetPredictData = (data: any[], length: number) => {
  return (data || []).map((each: any) => {
    return {
      ...each,
      tableData: each.tableData.map((item: any) => {
        return {
          id: uuid(),
          data: item.map((col: any, row: number) => {
            return row < length ? col : Number(col);
          }),
        };
      }),
    };
  });
};
