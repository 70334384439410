import projectEmpty from '../../assets/images/project-empty.png';

import { FC, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { mangLocal } from '../../utils/common';
import { getProjectColumns } from './table-columns';

import {
  getUserProjectList,
  deleteProject,
  Project,
} from '../../service/project';
import { download } from '../../service/download';
import { updatePredictAuth } from '../../service/space';

import { Button, Table, Modal, message, Dropdown, Menu } from 'antd';
import {
  ReloadOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';

const exportInfoMap: { [key: string]: [string, string] } = {
  project: ['/project/downloadProjectInfo', '项目信息.xlsx'],
  observe: ['/observe/downloadProjectObserveInfo', '项目观察数据.xlsx'],
  check: ['/observe/downloadProjectCheckInfo', '项目检查数据.xlsx'],
};

interface Props {
  searchForm?: { [key: string]: any };
}

const ProjectListTable: FC<Props> = ({ searchForm }) => {
  const history = useHistory();

  const userInfo = mangLocal('userInfo');

  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<Project[]>([]);
  const [listTotal, setListTotal] = useState<number>(0);
  const [pageData, setPageData] = useState<{ [key: string]: number }>({
    page: 1,
    size: 10,
  });

  const getList = useStableCallback(async (page: number, size?: number) => {
    setLoading(true);
    setPageData({
      page: page,
      size: size ?? pageData.size,
    });

    try {
      const { list, total } = await getUserProjectList({
        ...searchForm,
        page: page,
        size: size ?? pageData.size,
        userId: userInfo?.userId,
      });
      setList(list);
      setListTotal(total);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const batchExport = useStableCallback((type: string) => {
    if (!selectedRowKeys.length)
      return message.warning('请先选择需要导出的项目');
    download(
      exportInfoMap[type][0],
      { projectIdList: selectedRowKeys },
      exportInfoMap[type][1]
    );
  });

  const setRow = useStableCallback((record: any) => {
    history.push(`/project/edit/${record.projectId}`);
  });

  const spaceRow = useStableCallback((record: any) => {
    history.push(`/project/space/${record.projectId}`);
  });

  const predictRow = useStableCallback((record: any) => {
    const typeName = record.predictStatus === 1 ? '停止预测' : '开始预测';
    const typeStatus = record.predictStatus === 1 ? 2 : 1;
    Modal.confirm({
      title: `确定${typeName}项目【${record.projectName}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          await updatePredictAuth(record.projectId, typeStatus);
          message.success(`${typeName}成功`);
          getList(pageData.page);
        } catch (err) {}
      },
    });
  });

  const dropdownClick = useStableCallback((record, value) => {
    if (value.key === 'delete') {
      deleteRow(record);
    } else if (value.key === 'space') {
      spaceRow(record);
    } else {
      exportRow(record, value.key);
    }
  });

  const deleteRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定删除项目【${record.projectName}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          await deleteProject(record.projectId);
          message.success('删除成功');
          getList(1);
        } catch (err) {}
      },
    });
  });

  const exportRow = useStableCallback((record: any, type: string) => {
    download(
      exportInfoMap[type][0],
      { projectIdList: [record.projectId] },
      exportInfoMap[type][1]
    );
  });

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  useEffect(() => {
    getList(1);
  }, [getList, searchForm]);

  const columns = useMemo(
    () =>
      getProjectColumns((record) => {
        const menu = (
          <Menu onClick={(value) => dropdownClick(record, value)}>
            <Menu.Item key='project'>导出项目信息</Menu.Item>
            <Menu.Item key='observe' disabled={record.status === 1}>
              导出观察数据
            </Menu.Item>
            <Menu.Item key='check'>导出检查数据</Menu.Item>
            {userInfo?.role >= 3 && (
              <>
                <Menu.Item key='space'>空间预测信息</Menu.Item>
                <Menu.Item key='delete' danger disabled={record.status !== 1}>
                  删除
                </Menu.Item>
              </>
            )}
          </Menu>
        );

        return (
          <>
            <Button type='link' onClick={() => setRow(record)}>
              设置
            </Button>
            {userInfo?.role >= 3 ? (
              <Button type='link' onClick={() => predictRow(record)}>
                {record.predictStatus === 1 ? '停止预测' : '开始预测'}
              </Button>
            ) : (
              <Button type='link' onClick={() => spaceRow(record)}>
                空间预测
              </Button>
            )}
            <Dropdown overlay={menu}>
              <Button type='link'>
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </>
        );
      }),
    [userInfo?.role, dropdownClick, setRow, predictRow, spaceRow]
  );

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>项目列表</div>
        {listTotal > 0 && (
          <div className='header-operate'>
            <Button onClick={() => batchExport('project')}>导出项目信息</Button>
            <Button onClick={() => batchExport('observe')}>导出观察数据</Button>
            <Button onClick={() => batchExport('check')}>导出检查数据</Button>
            <Button type='primary' onClick={() => history.push('/project/add')}>
              创建项目
            </Button>
            <ReloadOutlined className='refresh' onClick={() => getList(1)} />
          </div>
        )}
      </div>

      {listTotal ? (
        <Table
          className='list-table'
          size={'middle'}
          scroll={{ x: '100%' }}
          rowKey='projectId'
          loading={loading}
          columns={columns}
          dataSource={list}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          pagination={{
            size: 'small',
            current: pageData.page,
            pageSize: pageData.size,
            total: listTotal,
            showQuickJumper: true,
            showSizeChanger: true,
            onChange: getList,
          }}
        />
      ) : (
        <div className='flex-x m-t-b'>
          <img
            src={projectEmpty}
            style={{ width: '240px', height: '200px' }}
            alt=''
          />
          <Button
            ghost
            type='primary'
            shape='round'
            icon={<PlusOutlined />}
            onClick={() => history.push('/project/add')}
            style={{ margin: '16px auto 24px' }}
          >
            立即创建项目
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProjectListTable;
