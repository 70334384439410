import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import FloorFeaturesTable from './table/floor-features';
import FloorSynergyTable from './table/floor-synergy';
import OtherFeaturesTable from './table/other-features';
import PersonalSpaceTable from './table/personal-space';
import RentalAreaTable from './table/rental-area';
import SpecialFeaturesTable from './table/special-features';
import StaffTable from './table/staff';

import { Button } from 'antd';

interface Props {
  projectId: string;
  totalData: any;
}

const TotalTabpane: FC<Props> = ({ projectId, totalData }) => {
  const history = useHistory();

  return (
    <div className='space-predict-total'>
      <div className='flex-x m-s-between a-s-center'>
        <div className='flex-x m-s-start a-s-center'>
          <div className='date flex-x m-s-start a-s-center'>
            <p>日期：</p>
            <p>{totalData.predictDate || '-'}</p>
          </div>
          <div className='num flex-x m-s-start a-s-center'>
            <p>楼层数量：</p>
            <p>{totalData.floorNum || '-'}</p>
          </div>
        </div>
        <Button onClick={() => history.replace(`/project/space/${projectId}`)}>
          返回列表
        </Button>
      </div>

      <div className='space-predict-main'>
        <StaffTable value={totalData.step1DataList} />
        <PersonalSpaceTable
          valueA={totalData.step2ADataList}
          valueB={totalData.step2BDataList}
        />
        <FloorSynergyTable value={totalData.step3DataList} />
        <FloorFeaturesTable value={totalData.step4DataList} />
        <OtherFeaturesTable value={totalData.step5DataList} />
        <SpecialFeaturesTable value={totalData.step6DataList} />
        <RentalAreaTable value={totalData.step7DataList} />
      </div>
    </div>
  );
};

export default TotalTabpane;
