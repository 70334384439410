import React, { FC } from 'react';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { roadStatusConvert } from '../../utils/definition';

import { Form, Input, Button, Row, Col, Select } from 'antd';
const { Option } = Select;

interface Props {
  onReset: (value: { [key: string]: any }) => void;
  onFinish: (value: { [key: string]: any }) => void;
}

const RoadListQuery: FC<Props> = ({ onReset, onFinish }) => {
  const [form] = Form.useForm();

  const onInnerReset = useStableCallback(() => {
    form.resetFields();
    onReset(form.getFieldsValue(true));
  });

  return (
    <div className='page-custom common-query'>
      <Form form={form} name='road-query' layout={'inline'} onFinish={onFinish}>
        <Row>
          <Col span={8}>
            <Form.Item label='路线ID' name='roadSequence'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='路线名称' name='roadName'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='项目名称' name='projectName'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='观察员' name='accountName'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='状态' name='status'>
              <Select placeholder='请选择' allowClear>
                {Object.entries(roadStatusConvert).map(([key, value]) => (
                  <Option value={key} key={key}>
                    {value[0]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button htmlType='button' onClick={onInnerReset}>
                重置
              </Button>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default RoadListQuery;
