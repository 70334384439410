import { mangLocal } from '../utils/common';
import { preFetch } from './fetch';
import { RoadDetail } from './road';

export interface ObserveInfo {
  roadSequence?: string;
  roadName?: string;
  projectName?: string;
}

export interface observeTime {
  observeTime: string;
  version: string;
}

export interface ObserveList {
  roadId: number;
  roadName: string;
  projectId: number;
  projectName: string;
  suspendFlag: boolean;
  suspendVersion?: string;
  observeTime?: observeTime[];
}

export interface ObserveCheckInfo {
  projectId?: number;
  roadId?: number;
  suspendIndex: number;
  version: string;
  userNumList: string[];
  roadOptionList: {
    pointNum: number;
    pointIndex: number;
    userNum: number | string;
    remark: string;
    spaceType: string;
    clientTime: number;
    roadObserveInfoList: RoadDetail['roadObserveInfoList'];
  }[];
}

// 获取观察任务列表（在路线检查列表页和路线观察列表页都用这个接口）
export const getObserveList = async (body: ObserveInfo): Promise<{ data: ObserveList[] }> => {
  const res = await preFetch('/observe/getObserveList', {
    method: 'POST',
    body,
  });

  return res;
};

export const getCheckObserveInfo = async (
  projectId: string,
  roadId: string,
  version: string,
): Promise<ObserveCheckInfo> => {
  const localData = mangLocal(`observeInfo_${projectId}_${roadId}`);

  if (localData) return localData;

  const res = await preFetch('/observe/getRoadOption', {
    method: 'POST',
    body: { projectId, roadId, version },
  });

  return res.data;
};

export const saveCheckObserveInfo = async (checkInfo: ObserveCheckInfo) => {
  const postInfo: any = {
    ...checkInfo,
    suspendIndex: checkInfo.roadOptionList[checkInfo.suspendIndex].pointIndex,
  };
  delete postInfo.userNumList;

  const res = await preFetch('/observe/saveRoadPoint', {
    method: 'POST',
    body: postInfo,
  });

  return res;
};
