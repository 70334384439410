import React from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { mangLocal } from '../../utils/common';
import { closeRoadObserversSetModal, openRoadObserversSetModal } from './edit/observers-set';

import { deleteRoad, publishRoad, pauseRoad } from '../../service/road';
import { download } from '../../service/download';

import { Menu, Modal, Button, Dropdown, message } from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const exportInfoMap: { [key: string]: [string, string] } = {
  observe: ['/observe/downloadRoadObserveInfo', '路线观察数据.xlsx'],
  check: ['/observe/downloadRoadCheckInfo', '路线检查数据.xlsx'],
};

interface Params {
  pageData: { [key: string]: number };
  getList: (params: any) => Promise<any>;
}

const useRoadOperate = ({ pageData, getList }: Params) => {
  const history = useHistory();

  const userInfo = mangLocal('userInfo');

  const onConfirm = useStableCallback(() => {
    closeRoadObserversSetModal();
    getList(pageData.page);
  });

  const onCancel = useStableCallback(() => {
    closeRoadObserversSetModal();
  });

  const dropdownClick = useStableCallback((record, value) => {
    if (value.key === 'downMap') {
      downloadRow(record);
    } else if (value.key === 'setObserver') {
      openRoadObserversSetModal({
        visible: true,
        roadId: record.roadId,
        onCancel,
        onConfirm,
      });
    } else if (value.key === 'deleteRoad') {
      deleteRow(record);
    } else {
      exportRow(record, value.key);
    }
  });

  const downloadRow = useStableCallback(async (record: any) => {
    await download('/road/downloadRoadImage', { roadId: record.roadId }, `${record.roadName}.JPEG`);
    message.success('下载成功');
  });

  const deleteRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定删除路线【${record.roadName}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          await deleteRoad(record.roadId);
          message.success('删除成功');
          getList(1);
        } catch (err) {}
      },
    });
  });

  const publishRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定开始观察路线【${record.roadName}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          await publishRoad([record.roadId]);
          message.success('开始观察成功');
          getList(pageData.page);
        } catch (err) {}
      },
    });
  });

  const pauseRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定暂停观察路线【${record.roadName}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '如需修改路线信息，请先保存原先的观察数据。',
      maskClosable: false,
      onOk: async () => {
        try {
          await pauseRoad([record.roadId]);
          message.success('暂停观察成功');
          getList(pageData.page);
        } catch (err) {}
      },
    });
  });

  const exportRow = useStableCallback((record: any, type: string) => {
    download(exportInfoMap[type][0], { roadIdList: [record.roadId] }, exportInfoMap[type][1]);
  });

  const renderOpeartor = useStableCallback((record: any) => {
    const menu = (
      <Menu onClick={(value) => dropdownClick(record, value)}>
        <Menu.Item key='downMap' disabled={!record.hasPhoto}>
          下载点位图
        </Menu.Item>
        <Menu.Item key='setObserver'>添加观察员</Menu.Item>
        <Menu.Item key='observe' disabled={record.status === 1}>
          导出观察数据
        </Menu.Item>
        <Menu.Item key='check'>导出检查数据</Menu.Item>
        {userInfo?.role >= 3 && (
          <Menu.Item key='deleteRoad' danger disabled={record.status !== 1}>
            删除
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <>
        <Button type='link' onClick={() => history.push(`/road/edit/${record.roadId}`)}>
          设置
        </Button>
        {userInfo?.role >= 3 && (record.status === 1 || record.status === 4) && (
          <Button type='link' onClick={() => publishRow(record)}>
            开始观察
          </Button>
        )}
        {record.status === 2 && (
          <Button type='link' onClick={() => pauseRow(record)}>
            暂停观察
          </Button>
        )}
        <Dropdown overlay={menu}>
          <Button type='link'>
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      </>
    );
  });

  return renderOpeartor;
};

export default useRoadOperate;
