import './login.scss';
import loginLogo from '../../assets/images/logo-title.png';
import loginBeian from '../../assets/images/beian.png';

import { FC } from 'react';

import LoginTab from './login-tab';
import RegisterTab from './register-tab';

import { Tabs } from 'antd';
const { TabPane } = Tabs;

const Login: FC = () => {
  return (
    <div className='login flex-x m-t-b'>
      <img className='login-logo' src={loginLogo} alt='' />

      <Tabs defaultActiveKey='1'>
        <TabPane tab='登录' key='1'>
          <LoginTab></LoginTab>
        </TabPane>
        <TabPane tab='注册' key='2'>
          <RegisterTab></RegisterTab>
        </TabPane>
      </Tabs>

      <div className='login-beian flex-x'>
        <a href='https://beian.miit.gov.cn' target='_blank' rel='noreferrer'>
          沪ICP备2022000158号-1
        </a>
        <img src={loginBeian} alt='' />
        <a href='http://www.beian.gov.cn' target='_blank' rel='noreferrer'>
          沪公网安备31010602006625号
        </a>
        <a href='https://mail.163.com/' target='_blank' rel='noreferrer'>
          ·邮箱:siyouspace@163.com
        </a>
      </div>
    </div>
  );
};

export default Login;
